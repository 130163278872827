import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuModule } from '../../shared/menu/menu.module';
import { PaginaInicialComponent } from './pagina-inicial.component';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
	declarations: [
		PaginaInicialComponent
	],
	imports: [
		CommonModule,
    MenuModule,
		LayoutModule
	],
		exports:[ PaginaInicialComponent ]

	})
	export class PaginaInicialModule { }
