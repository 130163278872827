<div class="container-inner container-scouts scouts" *ngIf="show">
    <h2>Scouts</h2>
    <!-- <ul> -->
    <ngx-slick-carousel class="carousel carousel-scouts"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        *ngIf="!useful.isMobile">

        <!-- no jogo - mandante -->
        <div ngxSlickItem class="slide">
            <div>
                <h5>
                    <img [src]="logoMandante">
                    No jogo
                </h5>
                <div>
                    <div>
                        <p class="scouts-passes">
                            {{ fundamentoObj.mandante.total.total }}
                        </p>
                        <small>{{ fundamentoScout }}</small>
                    </div>
                    <div *ngIf="fundamentoObj.detalhado">
                        <span class="text-green">
                            {{ useful.makeMeInin1decimal(fundamentoObj.mandante.total.corretos) }}

                            <i *ngIf="fundamentoObj.mandante.total.corretos > fundamentoObj.campeonato.corretos; else elseTotalCorretoMandante" class="icon-indicator-above icon-black-200"></i>
                            <ng-template #elseTotalCorretoMandante>
                                <i class="icon-indicator-below icon-black-200"></i>
                            </ng-template>
                        </span>
                        <span class="text-red">
                            {{ useful.makeMeInin1decimal(fundamentoObj.mandante.total.naoCorretos) }}

                            <i *ngIf="fundamentoObj.mandante.total.naoCorretos > fundamentoObj.campeonato.naoCorretos; else elseTotalNaoCorretoMandante" class="icon-indicator-above icon-black-200"></i>
                            <ng-template #elseTotalNaoCorretoMandante>
                                <i class="icon-indicator-below icon-black-200"></i>
                            </ng-template>
                        </span>
                    </div>
                </div>
                <div class="graphic graphic-pizza" *ngIf="fundamentoObj.detalhado; else elseBlockGraphManTotal">
                    <span>{{ useful.makeMeInin1decimal(fundamentoObj.mandante.total.percentualCorretos) }}%</span>
                    <div class="bar-green" [style.background-image]="'conic-gradient(from -90deg, #00C056 0 ' + (fundamentoObj.mandante.total.percentualCorretos/2) +'%, #B8312F 0)'"></div>
                </div>
                <ng-template #elseBlockGraphManTotal>
                    <!-- <i [class]="'icon' + fundamentoObj.nome"></i> -->
                    <i [class]="fundamentoIcon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                </ng-template>
            </div>
        </div>

        <!-- media do time - mandante -->
        <div ngxSlickItem class="slide">
            <div>
                <h5>
                    <img [src]="logoMandante" >
                    Média do time
                </h5>
                <div>
                    <div>
                        <p class="scouts-passes">
                            {{ useful.makeMeInin1decimal(fundamentoObj.mandante.mediaDaEquipe.total) }}
                        </p>
                        <small>{{ fundamentoScout }}</small>
                    </div>
                    <div *ngIf="fundamentoObj.detalhado">
                        <span class="text-green">
                            {{ useful.makeMeInin1decimal(fundamentoObj.mandante.mediaDaEquipe.corretos) }}

                            <i *ngIf="fundamentoObj.mandante.mediaDaEquipe.corretos > fundamentoObj.campeonato.corretos; else elseMediaDaEquipeMandante" class="icon-indicator-above icon-black-200"></i>
                            <ng-template #elseMediaDaEquipeMandante>
                                <i class="icon-indicator-below icon-black-200"></i>
                            </ng-template>
                        </span>
                        <span class="text-red">
                            {{ useful.makeMeInin1decimal(fundamentoObj.mandante.mediaDaEquipe.naoCorretos) }}

                            <i *ngIf="fundamentoObj.mandante.mediaDaEquipe.naoCorretos > fundamentoObj.campeonato.naoCorretos; else elseMediaDaEquipeNaoCorretosMandante" class="icon-indicator-above icon-black-200"></i>
                            <ng-template #elseMediaDaEquipeNaoCorretosMandante>
                                <i class="icon-indicator-below icon-black-200"></i>
                            </ng-template>
                        </span>
                    </div>
                </div>
                <div class="graphic graphic-pizza" *ngIf="fundamentoObj.detalhado; else elseBlockGraphManMed">
                    <span>{{ useful.makeMeInin1decimal(fundamentoObj.mandante.mediaDaEquipe.percentualCorretos) }}%</span>
                    <div class="bar-green" [style.background-image]="'conic-gradient(from -90deg, #00C056 0 ' + (fundamentoObj.mandante.mediaDaEquipe.percentualCorretos/2) +'%, #B8312F 0)'"></div>
                </div>
                <ng-template #elseBlockGraphManMed>
                    <!-- <i [class]="'icon' + fundamentoObj.nome"></i> -->
                    <i [class]="fundamentoIcon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                </ng-template>
            </div>
        </div>

        <!-- media do campeonato -->
        <div ngxSlickItem class="slide slick-current">
            <div>
                <h5>
                    Média do campeonato
                </h5>
                <div>
                    <div>
                        <p class="scouts-passes">
                            {{ useful.makeMeInin1decimal(fundamentoObj.campeonato.total) }}
                        </p>
                        <small>{{ fundamentoScout }}</small>
                    </div>
                    <div *ngIf="fundamentoObj.detalhado">
                        <span class="text-green">
                            {{ useful.makeMeInin1decimal(fundamentoObj.campeonato.corretos) }}
                        </span>
                        <span class="text-red">
                            {{ useful.makeMeInin1decimal(fundamentoObj.campeonato.naoCorretos) }}
                        </span>
                    </div>
                </div>
                <div class="graphic graphic-pizza" *ngIf="fundamentoObj.detalhado; else elseBlockGraphCamp">
                    <span>{{ useful.makeMeInin1decimal(fundamentoObj.campeonato.percentualCorretos) }}%</span>
                    <div class="bar-green" [style.background-image]="'conic-gradient(from -90deg, #00C056 0 ' + (fundamentoObj.campeonato.percentualCorretos/2) +'%, #B8312F 0)'"></div>
                </div>
                <ng-template #elseBlockGraphCamp>
                    <!-- <i [class]="'icon' + fundamentoObj.nome"></i> -->
                    <i [class]="fundamentoIcon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                </ng-template>
            </div>
        </div>

        <!-- media do time - visitante -->
        <div ngxSlickItem class="slide">
            <div>
                <h5>
                    <img [src]="logoVisitante" >
                    Média do time
                </h5>
                <div>
                    <div>
                        <p class="scouts-passes">
                            {{ useful.makeMeInin1decimal(fundamentoObj.visitante.mediaDaEquipe.total) }}
                        </p>
                        <small>{{ fundamentoScout }}</small>
                    </div>
                    <div *ngIf="fundamentoObj.detalhado">
                        <span class="text-green">
                            {{ useful.makeMeInin1decimal(fundamentoObj.visitante.mediaDaEquipe.corretos) }}

                            <i *ngIf="fundamentoObj.visitante.mediaDaEquipe.corretos > fundamentoObj.campeonato.corretos; else elseMediaDaEquipeVisitante" class="icon-indicator-above icon-black-200"></i>
                            <ng-template #elseMediaDaEquipeVisitante>
                                <i class="icon-indicator-below icon-black-200"></i>
                            </ng-template>
                                                    </span>
                        <span class="text-red">
                            {{ useful.makeMeInin1decimal(fundamentoObj.visitante.mediaDaEquipe.naoCorretos) }}

                            <i *ngIf="fundamentoObj.visitante.mediaDaEquipe.naoCorretos > fundamentoObj.campeonato.naoCorretos; else elseMediaDaEquipeNaoCorretosVisitante" class="icon-indicator-above icon-black-200"></i>
                            <ng-template #elseMediaDaEquipeNaoCorretosVisitante>
                                <i class="icon-indicator-below icon-black-200"></i>
                            </ng-template>
                        </span>
                    </div>
                </div>
                <div class="graphic graphic-pizza" *ngIf="fundamentoObj.detalhado; else elseBlockGraphVisMedia">
                    <span>{{ useful.makeMeInin1decimal(fundamentoObj.visitante.mediaDaEquipe.percentualCorretos) }}%</span>
                    <div class="bar-green" [style.background-image]="'conic-gradient(from -90deg, #00C056 0 ' + (fundamentoObj.visitante.mediaDaEquipe.percentualCorretos/2) +'%, #B8312F 0)'"></div>
                </div>
                <ng-template #elseBlockGraphVisMedia>
                    <!-- <i [class]="'icon' + fundamentoObj.nome"></i> -->
                    <i [class]="fundamentoIcon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                </ng-template>
            </div>
        </div>

        <!-- no jogo - visitante -->
        <div ngxSlickItem class="slide">
            <div>
                <h5>
                    <img [src]="logoVisitante" >
                    No jogo
                </h5>
                <div>
                    <div>
                        <p class="scouts-passes">
                            {{ useful.makeMeInin1decimal(fundamentoObj.visitante.total.total) }}
                        </p>
                        <small>{{ fundamentoScout }}</small>
                    </div>
                    <div *ngIf="fundamentoObj.detalhado">
                        <span class="text-green">
                            {{ useful.makeMeInin1decimal(fundamentoObj.visitante.total.corretos) }}

                            <i *ngIf="fundamentoObj.visitante.total.corretos > fundamentoObj.campeonato.corretos; else elseTotalCorretoVisitante" class="icon-indicator-above icon-black-200"></i>
                            <ng-template #elseTotalCorretoVisitante>
                                <i class="icon-indicator-below icon-black-200"></i>
                            </ng-template>
                        </span>
                        <span class="text-red">
                            {{ useful.makeMeInin1decimal(fundamentoObj.visitante.total.naoCorretos) }}

                            <i *ngIf="fundamentoObj.visitante.total.naoCorretos > fundamentoObj.campeonato.naoCorretos; else elseTotalNaoCorretVisitante" class="icon-indicator-above icon-black-200"></i>
                            <ng-template #elseTotalNaoCorretVisitante>
                                <i class="icon-indicator-below icon-black-200"></i>
                            </ng-template>
                        </span>
                    </div>
                </div>
                <div class="graphic graphic-pizza" *ngIf="fundamentoObj.detalhado; else elseBlockGraphVisTotal">
                    <span>{{ useful.makeMeInin1decimal(fundamentoObj.visitante.total.percentualCorretos) }}%</span>
                    <div class="bar-green" [style.background-image]="'conic-gradient(from -90deg, #00C056 0 ' + (fundamentoObj.visitante.total.percentualCorretos/2) +'%, #B8312F 0)'"></div>
                </div>
                <ng-template #elseBlockGraphVisTotal>
                    <!-- <i [class]="'icon' + fundamentoObj.nome"></i> -->
                    <i [class]="fundamentoIcon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                </ng-template>
            </div>
        </div>
    </ngx-slick-carousel>
    <!-- </ul> -->

    <div class="navList nav-scouts" *ngIf="useful.isMobile">
        <ul>
            <li><a href="javascript:void(0)" title="Jogo Completo" class="actived" (click)="mudarFundamentoMobile($event, 'total')">Jogo Completo</a></li>
            <li><a href="javascript:void(0)" title="1º tempo" (click)="mudarFundamentoMobile($event, '1tempo')">1º tempo</a></li>
            <li><a href="javascript:void(0)" title="2º tempo" (click)="mudarFundamentoMobile($event, '2tempo')">2º tempo</a></li>
        </ul>
    </div>

    <div class="content-table content">
        <table>
            <thead *ngIf="!useful.isMobile">
                <tr>
                    <th>1' Tempo</th>
                    <th>2' Tempo</th>
                    <th>Total</th>
                    <th>Fundamento</th>
                    <th>Total</th>
                    <th>2' Tempo</th>
                    <th>1' Tempo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of scoutDetails" (click)="estatisticaScouts(item.nome)" [class]="fundamentoScout == item.nome ? 'actived' : ''">
                    <ng-container *ngIf="!useful.isMobile">
                        <td>
                            <div *ngIf="item.detalhado; else elseBlockMandantePrimeiroTempo">
                                <small class="label label-green">{{ item.mandante.primeiroTempo.corretos }}</small>
                                <small class="label label-red">{{ item.mandante.primeiroTempo.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.mandante.primeiroTempo.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockMandantePrimeiroTempo>
                                {{ item.mandante.primeiroTempo.total }}
                            </ng-template>
                        </td>
                        <td>
                            <div *ngIf="item.detalhado; else elseBlockMandanteSegundoTempo">
                                <small class="label label-green">{{ item.mandante.segundoTempo.corretos }}</small>
                                <small class="label label-red">{{ item.mandante.segundoTempo.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.mandante.segundoTempo.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockMandanteSegundoTempo>
                                {{ item.mandante.segundoTempo.total }}
                            </ng-template>
                        </td>
                        <td>
                            <div *ngIf="item.detalhado; else elseBlockMandanteTotal">
                                <small class="label label-green">{{ item.mandante.total.corretos }}</small>
                                <small class="label label-red">{{ item.mandante.total.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.mandante.total.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockMandanteTotal>
                                {{ item.mandante.total.total }}
                            </ng-template>
                        </td>
                        <td>{{ item.nome }}</td>
                        <td>
                            <div *ngIf="item.detalhado; else elseBlockVisitanteTotal">
                                <small class="label label-green">{{ item.visitante.total.corretos }}</small>
                                <small class="label label-red">{{ item.visitante.total.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.visitante.total.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockVisitanteTotal>
                                {{ item.visitante.total.total }}
                            </ng-template>
                        </td>
                        <td>
                            <div *ngIf="item.detalhado; else elseBlockVisitanteSegundoTempo">
                                <small class="label label-green">{{ item.visitante.segundoTempo.corretos }}</small>
                                <small class="label label-red">{{ item.visitante.segundoTempo.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.visitante.segundoTempo.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockVisitanteSegundoTempo>
                                {{ item.visitante.segundoTempo.total }}
                            </ng-template>
                        </td>
                        <td>
                            <div *ngIf="item.detalhado; else elseBlockVisitantePrimeiroTempo">
                                <small class="label label-green">{{ item.visitante.primeiroTempo.corretos }}</small>
                                <small class="label label-red">{{ item.visitante.primeiroTempo.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.visitante.primeiroTempo.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockVisitantePrimeiroTempo>
                                {{ item.visitante.primeiroTempo.total }}
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="useful.isMobile">
                        <td *ngIf="fundamentoMobile == '1tempo'">
                            <div *ngIf="item.detalhado; else elseBlockMandantePrimeiroTempo">
                                <small class="label label-green">{{ item.mandante.primeiroTempo.corretos }}</small>
                                <small class="label label-red">{{ item.mandante.primeiroTempo.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.mandante.primeiroTempo.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockMandantePrimeiroTempo>
                                {{ item.mandante.primeiroTempo.total }}
                            </ng-template>
                        </td>
                        <td *ngIf="fundamentoMobile == '2tempo'">
                            <div *ngIf="item.detalhado; else elseBlockMandanteSegundoTempo">
                                <small class="label label-green">{{ item.mandante.segundoTempo.corretos }}</small>
                                <small class="label label-red">{{ item.mandante.segundoTempo.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.mandante.segundoTempo.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockMandanteSegundoTempo>
                                {{ item.mandante.segundoTempo.total }}
                            </ng-template>
                        </td>
                        <td *ngIf="fundamentoMobile == 'total'">
                            <div *ngIf="item.detalhado; else elseBlockMandanteTotal">
                                <small class="label label-green">{{ item.mandante.total.corretos }}</small>
                                <small class="label label-red">{{ item.mandante.total.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.mandante.total.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockMandanteTotal>
                                {{ item.mandante.total.total }}
                            </ng-template>
                        </td>
                        <td>{{ item.nome }}</td>
                        <td *ngIf="fundamentoMobile == 'total'">
                            <div *ngIf="item.detalhado; else elseBlockVisitanteTotal">
                                <small class="label label-green">{{ item.visitante.total.corretos }}</small>
                                <small class="label label-red">{{ item.visitante.total.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.visitante.total.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockVisitanteTotal>
                                {{ item.visitante.total.total }}
                            </ng-template>
                        </td>
                        <td *ngIf="fundamentoMobile == '2tempo'">
                            <div *ngIf="item.detalhado; else elseBlockVisitanteSegundoTempo">
                                <small class="label label-green">{{ item.visitante.segundoTempo.corretos }}</small>
                                <small class="label label-red">{{ item.visitante.segundoTempo.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.visitante.segundoTempo.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockVisitanteSegundoTempo>
                                {{ item.visitante.segundoTempo.total }}
                            </ng-template>
                        </td>
                        <td *ngIf="fundamentoMobile == '1tempo'">
                            <div *ngIf="item.detalhado; else elseBlockVisitantePrimeiroTempo">
                                <small class="label label-green">{{ item.visitante.primeiroTempo.corretos }}</small>
                                <small class="label label-red">{{ item.visitante.primeiroTempo.naoCorretos }}</small>
                                <span>{{ useful.makeMeInin1decimal(item.visitante.primeiroTempo.percentualCorretos) }}%</span>
                            </div>
                            <ng-template #elseBlockVisitantePrimeiroTempo>
                                {{ item.visitante.primeiroTempo.total }}
                            </ng-template>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
    <a class="btn-ver-mais" *ngIf="useful.isMobile" href="javascript:void(0)" (click)="verMaisScouts($event)">Ver mais</a>
</div>

