import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuModule } from '../../shared/menu/menu.module';
import { PartidasComponent } from './partidas.component';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
	declarations: [
		PartidasComponent
	],
	imports: [
		CommonModule,
        MenuModule,
		LayoutModule
	],
		exports:[ PartidasComponent ]

	})
	export class PartidasModule { }
