import { Component, OnInit } from '@angular/core';
import { UsefulService } from '../../shared/useful/useful.service';
import { Router, ActivatedRoute  } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-partidas',
  templateUrl: './partidas.component.html',
  styleUrls: ['./partidas.component.scss']
})
export class PartidasComponent implements OnInit {
  indexRodadaAtual:any;
  rodadaEscolhida:any;
  btPartidaAntHab:any = true;
  btPartidaProHab:any = true;
  partidas: any;
  idCampeonato: Number;
  interval: any;
  subscription: Subscription;

    constructor(
      public useful : UsefulService,
      public router: Router,
      public activatedRoute: ActivatedRoute
    ) {
      this.activatedRoute.params.subscribe( parametros => {
        // console.log('idPartida', parametros['campeonato']);

        if (this.idCampeonato != undefined && this.idCampeonato != parametros['campeonato']) {
          this.rodadaEscolhida = 0
        }
        this.idCampeonato = parametros['campeonato'];

        this.partidas = this.useful.getRodadas(this.idCampeonato);
      });

      this.subscription = this.useful.dataObservableRodadas.subscribe((res:any) => {
        this.partidas = res;

        this.getRodadaAtual();
        this.verifyPartidaAnterior(this.rodadaEscolhida);
        this.verifyPartidaProxima(this.rodadaEscolhida);
        this.orderPartidas();
      });

    }

    ngOnInit() {
      const that = this;
      this.interval = setInterval(function() {
        that.useful.getRodadas(that.idCampeonato);
      }, 10000);
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscription.unsubscribe();
    }

    getRodadaAtual() {
      // console.log('this.rodadaescolhida', this.rodadaEscolhida)
      if (this.rodadaEscolhida == undefined || this.rodadaEscolhida == 0) {
        if(this.partidas != undefined) {
          Array.from(this.partidas['rodadas']).forEach((item:any, i) => item.rodadaAtual && (this.indexRodadaAtual = i));
        }
        if (this.indexRodadaAtual != undefined) {
          this.rodadaEscolhida = this.indexRodadaAtual;
        } else {
          this.rodadaEscolhida = 0;
        }
      }
      return this.indexRodadaAtual;
    }

    verifyPartidaAnterior(partidaAtual) {
      if (partidaAtual > 0) {
        this.btPartidaAntHab = true;
        return `Rodada ${this.partidas.rodadas[this.rodadaEscolhida-1].rodada}`;
      } else {
        this.btPartidaAntHab = false;
        return `Não há rodada anteriores`;
      }
    }

    verifyPartidaProxima(partidaAtual) {
      if (partidaAtual < (this.partidas['rodadas'].length-1)) {
        this.btPartidaProHab = true;
        return `Rodada ${this.partidas.rodadas[this.rodadaEscolhida+1].rodada}`;
      } else {
        this.btPartidaProHab = false;
        return `Não há próximas rodadas`;
      }
    }

    getPartidaAnterior() {
      if (this.btPartidaAntHab) {
        this.rodadaEscolhida = (this.rodadaEscolhida-1);
      }
    }

    getPartidaProxima() {
      if (this.btPartidaProHab) {
        this.rodadaEscolhida = (this.rodadaEscolhida+1)
      }
    }

    orderPartidas() {
        for (const i of this.partidas['rodadas']) {
          i.partidas = i.partidas.sort(function(a,b){
            let temp1:any = new Date(a.dataHora);
            let temp2:any = new Date(b.dataHora);
            return  temp1 - temp2 ;
          });
        }
        return this.partidas['rodadas'];
    }

    scoutEscolhido(i) {
      // console.log('partida escolhida ', i)
      this.router.navigateByUrl(`partidas/${this.idCampeonato}/scouts/${i.id}`);
      this.useful.saveInLocalStore('partida', i);
    }
}
