import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
@Component({
  selector: 'app-notification',
  inputs: ['color', 'message', 'urlTeam'],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]
})
export class NotificationComponent implements OnInit, OnChanges {
  color: any;
  message: any;
  messages: any = [];
  urlTeam: any;
  show: boolean = false;

  ngOnInit() {
    if (this.color == undefined) {
      this.color = '#e5e5e5';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.message !== undefined) {
      this.mountMessages();
      this.showNotification();
    }
  }

  mountMessages() {
    this.messages.push(
      {
        color: this.color,
        message: this.message,
        urlTeam: this.urlTeam,
        id: `dinamID${this.message.replace(/ /g,'').normalize("NFD").replace(/[^a-zA-Zs]/g, "")}${this.color.replace(/#/g,'')}`
      }
    );
    this.closeNotificationAuto(this.messages[this.messages.length-1]['id']);
  }

  showNotification() {
    this.show = true;
  }

  closeNotification(myId) {
      this.messages = this.messages.filter(i => i.id != myId);
      this.messages.length < 1 && (this.show = false);
  }

  closeNotificationAuto(myId) {
    setTimeout(() => {
      this.messages = this.messages.filter(i => i.id != myId);
      this.messages.length < 1 && (this.show = false);
    }, 9000);
  }

}
