<div class="container-outside">
    <div class="container-inner over" *ngIf="show">
        <h2>Over</h2>
        <ul>
            <li [class]="qtdGols > 0.5 ? 'itemdisabled' : ''">
              <i *ngIf="dataOver.over05IsGrowthing" class="icon-indicator-above icon-green"></i>
              <i *ngIf="!dataOver.over05IsGrowthing" class="icon-indicator-below icon-red"></i>
              <div [class]="qtdGols > 0.5 ? '' : partidaEstaAoVivo ? !dataOver.over05IsGrowthing ? 'borda-animacao-vermelha' : 'borda-animacao-verde' : ''" >
                <small>0.5</small>
                <strong>{{ multiplyMe100(dataOver.over05) }}%</strong>
              </div>
            </li>
            <li [class]="qtdGols > 1.5 ? 'itemdisabled' : ''">
              <i *ngIf="dataOver.over15IsGrowthing" class="icon-indicator-above icon-green"></i>
              <i *ngIf="!dataOver.over15IsGrowthing" class="icon-indicator-below icon-red"></i>
              <div [class]="qtdGols > 1.5 ? '' : partidaEstaAoVivo ? !dataOver.over15IsGrowthing ? 'borda-animacao-vermelha' : 'borda-animacao-verde' : ''">
                <small>1.5</small>
                <strong>{{ multiplyMe100(dataOver.over15) }}%</strong>
              </div>
            </li>
            <li [class]="qtdGols > 2.5 ? 'itemdisabled' : ''">
              <i *ngIf="dataOver.over25IsGrowthing" class="icon-indicator-above icon-green"></i>
              <i *ngIf="!dataOver.over25IsGrowthing" class="icon-indicator-below icon-red"></i>
              <div [class]="qtdGols > 2.5 ? '' : partidaEstaAoVivo ? !dataOver.over25IsGrowthing ? 'borda-animacao-vermelha' : 'borda-animacao-verde' : ''">
                <small>2.5</small>
                <strong>{{ multiplyMe100(dataOver.over25) }}%</strong>
              </div>
            </li>
            <li [class]="qtdGols > 3.5 ? 'itemdisabled' : ''">
              <i *ngIf="dataOver.over35IsGrowthing" class="icon-indicator-above icon-green"></i>
              <i *ngIf="!dataOver.over35IsGrowthing" class="icon-indicator-below icon-red"></i>
              <div [class]="qtdGols > 3.5 ? '' : partidaEstaAoVivo ? !dataOver.over35IsGrowthing ? 'borda-animacao-vermelha' : 'borda-animacao-verde' : ''">
                <small>3.5</small>
                <strong>{{ multiplyMe100(dataOver.over35) }}%</strong>
              </div>
            </li>
            <li [class]="qtdGols > 4.5 ? 'itemdisabled' : ''">
              <i *ngIf="dataOver.over45IsGrowthing" class="icon-indicator-above icon-green"></i>
              <i *ngIf="!dataOver.over45IsGrowthing" class="icon-indicator-below icon-red"></i>
              <div [class]="qtdGols > 4.5 ? '' : partidaEstaAoVivo ? !dataOver.over45IsGrowthing ? 'borda-animacao-vermelha' : 'borda-animacao-verde' : ''">
                <small>4.5</small>
                <strong>{{ multiplyMe100(dataOver.over45) }}%</strong>
              </div>
            </li>
        </ul>
    </div>
    <div class="container-inner ambos-marcam" *ngIf="show">
        <h2>Ambos Marcam</h2>
        <ul>
            <li>
                <small>Sim</small>
                <strong *ngIf="dataAmbos != undefined">{{ multiplyMe100(dataAmbos.yesProbability) }}%</strong>
            </li>
            <li>
                <small>Não</small>
                <strong *ngIf="dataAmbos != undefined">{{ multiplyMe100(dataAmbos.noProbability) }}%</strong>
            </li>
        </ul>
    </div>
</div>

