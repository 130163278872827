<div class="container-inner escalacao" *ngIf="escalacao != undefined && escalacao != null">
    <h2>Escalação</h2>

    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Em campo">
            <div class="container-escalacao">
                <div>
                    <strong>
                        <img [src]="partida.mandante.urlLogo">
                        {{ partida.mandante.nome }}
                    </strong>

                    <table>
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Score</th>
                                <th>Nome</th>
                                <th></th>
                                <th>Posição</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of escalacao.titular.mandante">
                                <ng-container *ngIf="item.foiSubstituido != null; else foiSubstituidoMandante">
                                    <td>{{ item.foiSubstituido.ranking }}</td>
                                    <td>
                                        <div class="graphic graphic-circle" *ngIf="item.foiSubstituido.indice != null && item.foiSubstituido.indice >= 7">
                                            <span class="text-green">{{ useful.makeMeInin1decimal(item.foiSubstituido.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #00C056 0 ' + (item.foiSubstituido.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                        <div class="graphic graphic-circle" *ngIf="item.foiSubstituido.indice != null && item.foiSubstituido.indice < 7 && item.foiSubstituido.indice >= 5">
                                            <span class="text-blue">{{ useful.makeMeInin1decimal(item.foiSubstituido.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #009BBF 0 ' + (item.foiSubstituido.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                        <div class="graphic graphic-circle" *ngIf="item.foiSubstituido.indice != null && item.foiSubstituido.indice < 5">
                                            <span class="text-red">{{ useful.makeMeInin1decimal(item.foiSubstituido.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #B8312F 0 ' + (item.foiSubstituido.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                    </td>
                                    <td>
                                        {{ item.foiSubstituido.nomeJogador }}
                                        <span>({{ item.foiSubstituido.posicao }})</span>
                                    </td>
                                    <td>
                                        <div class="vertical-center">
                                            <span class="cartao cartao-amarelo" *ngIf="item.foiSubstituido.cartaoAmarelo"></span>
                                            <span class="cartao cartao-amarelo" *ngIf="item.foiSubstituido.cartaoAmarelo2"></span>
                                            <span class="cartao cartao-vermelho" *ngIf="item.foiSubstituido.cartaoVermelho"></span>
                                            <i class="icon-goal" *ngIf="item.foiSubstituido.gols > 0" [title]="getMinutosGol(item.foiSubstituido.golsInMinutes)"></i>
                                            <!-- <i class="icon-get_in_player text-green"></i> -->
                                            <i class="icon-get_in_player text-green" [title]="item.nomeJogador + ' - ' + item.foiSubstituido.tempo"></i>
                                        </div>
                                    </td>
                                    <td>
                                        {{ item.foiSubstituido.posicao }}
                                    </td>
                                </ng-container>
                                <ng-template #foiSubstituidoMandante>
                                    <td>{{ item.ranking }}</td>
                                    <td>
                                        <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice >= 7">
                                            <span class="text-green">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #00C056 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                        <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice < 7 && item.indice >= 5">
                                            <span class="text-blue">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #009BBF 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                        <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice < 5">
                                            <span class="text-red">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #B8312F 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                    </td>
                                    <td>
                                        {{ item.nomeJogador }}
                                        <span>({{ item.posicao }})</span>
                                    </td>
                                    <td>
                                        <div class="vertical-center">
                                            <span class="cartao cartao-amarelo" *ngIf="item.cartaoAmarelo"></span>
                                            <span class="cartao cartao-amarelo" *ngIf="item.cartaoAmarelo2"></span>
                                            <span class="cartao cartao-vermelho" *ngIf="item.cartaoVermelho"></span>
                                            <i class="icon-goal" *ngIf="item.gols > 0" [title]="getMinutosGol(item.golsInMinutes)"></i>
                                        </div>
                                    </td>
                                    <td>
                                        {{ item.posicao }}
                                    </td>
                                </ng-template>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <strong>
                        <img [src]="partida.visitante.urlLogo">
                        {{ partida.visitante.nome }}
                    </strong>

                    <table>
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Score</th>
                                <th>Nome</th>
                                <th></th>
                                <th>Posição</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of escalacao.titular.visitante">
                                <ng-container *ngIf="item.foiSubstituido != null; else foiSubstituidoVisitante">
                                    <td>{{ item.foiSubstituido.ranking }}</td>
                                    <td>
                                        <div class="graphic graphic-circle" *ngIf="item.foiSubstituido.indice != null && item.foiSubstituido.indice >= 7">
                                            <span class="text-green">{{ useful.makeMeInin1decimal(item.foiSubstituido.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #00C056 0 ' + (item.foiSubstituido.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                        <div class="graphic graphic-circle" *ngIf="item.foiSubstituido.indice != null && item.foiSubstituido.indice < 7 && item.foiSubstituido.indice >= 5">
                                            <span class="text-blue">{{ useful.makeMeInin1decimal(item.foiSubstituido.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #009BBF 0 ' + (item.foiSubstituido.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                        <div class="graphic graphic-circle" *ngIf="item.foiSubstituido.indice != null && item.foiSubstituido.indice < 5">
                                            <span class="text-red">{{ useful.makeMeInin1decimal(item.foiSubstituido.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #B8312F 0 ' + (item.foiSubstituido.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                    </td>
                                    <td>
                                        {{ item.foiSubstituido.nomeJogador }}
                                        <span>({{ item.foiSubstituido.posicao }})</span>
                                    </td>
                                    <td>
                                        <div class="vertical-center">
                                            <span class="cartao cartao-amarelo" *ngIf="item.foiSubstituido.cartaoAmarelo"></span>
                                            <span class="cartao cartao-amarelo" *ngIf="item.foiSubstituido.cartaoAmarelo2"></span>
                                            <span class="cartao cartao-vermelho" *ngIf="item.foiSubstituido.cartaoVermelho"></span>
                                            <i class="icon-goal" *ngIf="item.foiSubstituido.gols > 0" [title]="getMinutosGol(item.foiSubstituido.golsInMinutes)"></i>
                                            <!-- <i class="icon-get_in_player text-green"></i> -->
                                            <i class="icon-get_in_player text-green" [title]="item.nomeJogador + ' - ' + item.foiSubstituido.tempo"></i>
                                        </div>
                                    </td>
                                    <td>
                                        {{ item.foiSubstituido.posicao }}
                                    </td>
                                </ng-container>
                                <ng-template #foiSubstituidoVisitante>
                                    <td>{{ item.ranking }}</td>
                                    <td>
                                        <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice >= 7">
                                            <span class="text-green">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #00C056 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                        <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice < 7 && item.indice >= 5">
                                            <span class="text-blue">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #009BBF 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                        <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice < 5">
                                            <span class="text-red">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                            <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #B8312F 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                        </div>
                                    </td>
                                    <td>
                                        {{ item.nomeJogador }}
                                        <span>({{ item.posicao }})</span>
                                    </td>
                                    <td>
                                        <div class="vertical-center">
                                            <span class="cartao cartao-amarelo" *ngIf="item.cartaoAmarelo"></span>
                                            <span class="cartao cartao-amarelo" *ngIf="item.cartaoAmarelo2"></span>
                                            <span class="cartao cartao-vermelho" *ngIf="item.cartaoVermelho"></span>
                                            <i class="icon-goal" *ngIf="item.gols > 0" [title]="getMinutosGol(item.golsInMinutes)"></i>
                                        </div>
                                    </td>
                                    <td>
                                        {{ item.posicao }}
                                    </td>
                                </ng-template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Fora de Campo">
            <div class="container-escalacao">
                <div>
                    <strong>
                        <img [src]="partida.mandante.urlLogo">
                        {{ partida.mandante.nome }}
                    </strong>

                    <table>
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Score</th>
                                <th>Nome</th>
                                <th></th>
                                <th>Posição</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of escalacao.reserva.mandante">
                                <td>{{ item.ranking }}</td>
                                <td>
                                    <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice >= 7">
                                        <span class="text-green">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                        <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #00C056 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                    </div>
                                    <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice < 7 && item.indice >= 5">
                                        <span class="text-blue">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                        <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #009BBF 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                    </div>
                                    <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice < 5">
                                        <span class="text-red">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                        <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #B8312F 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                    </div>
                                </td>
                                <td>
                                    {{ item.nomeJogador }}
                                    <span>({{ item.posicao }})</span>
                                </td>
                                <td>
                                    <div class="vertical-center">
                                        <span class="cartao cartao-amarelo" *ngIf="item.cartaoAmarelo"></span>
                                        <span class="cartao cartao-amarelo" *ngIf="item.cartaoAmarelo2"></span>
                                        <span class="cartao cartao-vermelho" *ngIf="item.cartaoVermelho"></span>
                                        <i class="icon-goal" *ngIf="item.gols > 0" [title]="getMinutosGol(item.golsInMinutes)"></i>
                                        <!-- <i class="icon-get_in_player text-green"></i> -->
                                        <i class="icon-exit_player text-red" *ngIf="item.foiSubstituido != null" [title]="item.foiSubstituido.nomeJogador + ' - ' + item.foiSubstituido.tempo"></i>
                                    </div>
                                </td>
                                <td>
                                    {{ item.posicao }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <strong>
                        <img [src]="partida.visitante.urlLogo">
                        {{ partida.visitante.nome }}
                    </strong>

                    <table>
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Score</th>
                                <th>Nome</th>
                                <th></th>
                                <th>Posição</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of escalacao.reserva.visitante">
                                <td>{{ item.ranking }}</td>
                                <td>
                                    <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice >= 7">
                                        <span class="text-green">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                        <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #00C056 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                    </div>
                                    <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice < 7 && item.indice >= 5">
                                        <span class="text-blue">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                        <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #009BBF 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                    </div>
                                    <div class="graphic graphic-circle" *ngIf="item.indice != null && item.indice < 5">
                                        <span class="text-red">{{ useful.makeMeInin1decimal(item.indice) }}</span>
                                        <div class="bar" [style.background-image]="'conic-gradient(from 0deg, #B8312F 0 ' + (item.indice*10) +'%, transparent 0)'"></div>
                                    </div>
                                </td>
                                <td>
                                    {{ item.nomeJogador }}
                                    <span>({{ item.posicao }})</span>
                                </td>
                                <td>
                                    <div class="vertical-center">
                                        <span class="cartao cartao-amarelo" *ngIf="item.cartaoAmarelo"></span>
                                        <span class="cartao cartao-amarelo" *ngIf="item.cartaoAmarelo2"></span>
                                        <span class="cartao cartao-vermelho" *ngIf="item.cartaoVermelho"></span>
                                        <i class="icon-goal" *ngIf="item.gols > 0" [title]="getMinutosGol(item.golsInMinutes)"></i>
                                        <!-- <i class="icon-get_in_player text-green"></i> -->
                                        <i class="icon-exit_player text-red" *ngIf="item.foiSubstituido != null" [title]="item.foiSubstituido.nomeJogador + ' - ' + item.foiSubstituido.tempo"></i>
                                    </div>
                                </td>
                                <td>
                                    {{ item.posicao }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>