import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { MenuModule } from '../../shared/menu/menu.module';
import { RankingJogadoresComponent } from './ranking-jogadores.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatTableModule } from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';

@NgModule({
	declarations: [
		RankingJogadoresComponent
	],
	imports: [
		CommonModule,
		BrowserModule,
        MenuModule,
		LayoutModule,
		MatTableModule,
		MatSortModule
	],
		exports:[ RankingJogadoresComponent ]

	})
	export class RankingJogadoresModule { }
