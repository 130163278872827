<section>

    <div *ngIf="partidas !== undefined">
        <h1>
            <a href="javascript:void(0);" [title]="verifyPartidaAnterior(rodadaEscolhida)" (click)="getPartidaAnterior()" [attr.class]="btPartidaAntHab ? 'anteriorRodada' : 'btnDisabled anteriorRodada'">
                <i class="icon-return"></i>
            </a>
            Rodada {{ partidas.rodadas[rodadaEscolhida].rodada }}
            <a href="javascript:void(0);" [title]="verifyPartidaProxima(rodadaEscolhida)" class="proximaRodada" (click)="getPartidaProxima()" [attr.class]="btPartidaProHab ? 'proximaRodada' : 'btnDisabled proximaRodada'">
                <i class="icon-return"></i>
            </a>
        </h1>

        <div class="cardPartidaContainer">
            <div [class]="item.temporeal ? item.scout && item.periodoJogo != 'Não Inic.' ? 'aovivo cardPartida aovivo-animacao' : 'semDetalhes aovivo cardPartida aovivo-animacao' : item.scout && item.periodoJogo != 'Não Inic.' ? 'cardPartida' : 'cardPartida semDetalhes'"  *ngFor="let item of partidas.rodadas[rodadaEscolhida].partidas" (click)="scoutEscolhido(item);">
                <h2>
                    {{ useful.campeonatoAtual }}
                    <small *ngIf="item.temporeal" class="flag aovivo">Ao vivo</small>
                </h2>
                <small>{{ useful.formatDate(item.dataHora) }}</small>
                <div class="placarJogo">
                    <div class="placarEsq">
                        <img [src]="item.mandante.urlLogo" [title]="item.mandante.nome">
                        <span>{{ item.mandante.sigla }}</span>
                    </div>

                    <div class="placarGols">
                        <strong *ngIf="item.mandante.gols !== null">
                            {{ item.mandante.gols }} x {{ item.visitante.gols }}
                        </strong>
                        <small>{{ item.periodoJogo }}</small>
                    </div>

                    <div class="placarDir">
                        <img [src]="item.visitante.urlLogo" [title]="item.visitante.nome">
                        <span>{{ item.visitante.sigla }}</span>
                    </div>
                </div>
                <ng-container *ngIf="item.scout && item.periodoJogo != 'Não Inic.'; else semDetalhes">
                    <a href="javascript:void(0);" class="verDetalhes">Ver detalhes <i class="icon-arrow-right"></i></a>
                </ng-container>
                <ng-template #semDetalhes>
                    <span class="verDetalhes">Sem detalhes disponíveis</span>
                </ng-template>
            </div>
        </div>

    </div>
</section>
