import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { MenuModule } from '../../shared/menu/menu.module';
import { NotFoundComponent } from './not-found.component';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
	declarations: [
		NotFoundComponent
	],
	imports: [
		CommonModule,
		BrowserModule,
        MenuModule,
		LayoutModule
	],
		exports:[ NotFoundComponent ]

	})
	export class NotFoundModule { }
