<div class="container-inner individual">
    <h2>Individual</h2>
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="updateIndividualGraphs({tempoJogo: $event});">
        <mat-tab label="Jogo completo">
            </mat-tab>
            <mat-tab label="1° tempo">
            </mat-tab>
            <mat-tab label="2° tempo">
            </mat-tab>
    </mat-tab-group>
    <div class="container-individual content">
        <ul class="menu-selects" *ngIf="!useful.isMobile">
            <li>
                <label for="">Fundamentos</label>
                <select name="fundamentosIndividual" #fundamentosIndividual (change)="updateIndividualGraphs({selectFundamento: fundamentosIndividual.value});">
                    <option *ngFor="let item of fundamentoData" [value]="item.idFundamento" [selected]="item.idFundamento == fundamentoEscolhido">
                        {{ item.nome }}
                    </option>
                </select>
            </li>
        </ul>

      <mat-accordion *ngIf="useful.isMobile">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filtro
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="menu-selects">
              <li>
                  <label for="">Fundamentos</label>
                  <select name="fundamentosIndividual" #fundamentosIndividual (change)="updateIndividualGraphs({selectFundamento: fundamentosIndividual.value});">
                      <option *ngFor="let item of fundamentoData" [value]="item.idFundamento" [selected]="item.idFundamento == fundamentoEscolhido">
                          {{ item.nome }}
                      </option>
                  </select>
                </li>
            </ul>
        </mat-expansion-panel>
      </mat-accordion>

        <div class="graph-container">
            <div>
                <strong>
                    <img [src]="partida.mandante.urlLogo">
                    {{ partida.mandante.nome }}
                </strong>
                <app-graph-bar [idChart]="idChartHome" [data]="dataChartHome" *ngIf="dataChartHome != null && dataChartHome['players'].length > 0; else contElseMandante" [maxPlot]="maxPlotIndividual"></app-graph-bar>
                <ng-template #contElseMandante>
                  <div class="msg-alert">Não há dados disponíveis</div>
                </ng-template>
            </div>
            <div>
                <strong>
                    <img [src]="partida.visitante.urlLogo">
                    {{ partida.visitante.nome }}
                </strong>
                <app-graph-bar [idChart]="idChartAway" [data]="dataChartAway" *ngIf="dataChartAway != null && dataChartAway['players'].length > 0; else contElseVisitante" [maxPlot]="maxPlotIndividual"></app-graph-bar>
                <ng-template #contElseVisitante>
                  <div class="msg-alert">Não há dados disponíveis</div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
