import { Component, OnInit } from '@angular/core';
import { UsefulService } from '../../shared/useful/useful.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-scouts',
  templateUrl: './scouts.component.html',
  styleUrls: ['./scouts.component.scss']
})
export class ScoutsComponent implements OnInit {
    idCampeonato: Number;
    idPartida: Number;
    interval: any;
    partida: any;
    tempo: any;
    aovivo: Boolean = false;
    subscription: Subscription;
    subscriptionAoVivo: Subscription;
    myHref: any = encodeURIComponent(window.location.href);

    constructor(
      public useful : UsefulService,
      public router: Router,
      public activatedRoute: ActivatedRoute
    ) {
      this.activatedRoute.params.subscribe( parametros => {
        this.idCampeonato = parametros['campeonato'];
        this.idPartida = parametros['partida']
        this.useful.partidaBrasil  = this.idPartida;
      });
      this.activatedRoute.queryParams.subscribe(params => {
        let partida = params['partida'];
        console.log(partida, "o que eu pedi para dar console")
        if (partida != undefined) {
          this.aovivo = true;
        }
      });



    }

    ngOnInit() {
      // verifico se partida existe nas rodadas
      this.useful.getRodadas(this.idCampeonato);
      if (this.aovivo) {
        this.tempo = 10000;
        this.interval = setInterval(() => {
          this.useful.getRodadas(this.idCampeonato);
        }, this.tempo);
      }
      this.subscription = this.useful.dataObservableRodadas.subscribe((res:any) => {
        let matchSelected;
          res.rodadas.forEach(round => {
            round.partidas.find(match => {
              match.id === Number(this.idPartida) && (matchSelected = match);
            })
        })
          // console.log('matchSelected', matchSelected)
          if (matchSelected != undefined) {
            this.partida = matchSelected;

            // menu com scroll
            this.menuScroll();

            // console.log('partida', this.partida)
          } else {
            this.router.navigateByUrl(`/pagina-nao-encontrada`);
          }
      });


      if (this.partida != undefined) {
        if (this.aovivo) {
          this.tempo = 10000;
          this.interval = setInterval(() => {
            this.useful.getRodadas(this.idCampeonato);
          }, this.tempo);
        }
      } else {
        this.useful.getJogosAoVivo();
        this.subscriptionAoVivo = this.useful.dataObservableAoVivo.subscribe((res:any) => {
          let matchSelected = res.filter(x => x.id == this.idCampeonato);
          if (matchSelected.length > 0) {
            this.partida = {
              "visitante": {
                  "gols": matchSelected[0].goalsAway,
                  "nome": matchSelected[0].teamInitialsAway,
                  "sigla": matchSelected[0].teamInitialsAway,
                  "urlLogo": matchSelected[0].teamUrlLogoAway
              },
              "temporeal": matchSelected[0].realtime,
              "dataHora": matchSelected[0].isoDate,
              "periodoJogo": matchSelected[0].gameTime,
              "mandante": {
                  "gols": matchSelected[0].goalsHome,
                  "nome": matchSelected[0].teamInitialsHome,
                  "sigla":matchSelected[0].teamInitialsHome,
                  "urlLogo": matchSelected[0].teamUrlLogoHome
              },
              "scout": matchSelected[0].hasScout,
              "id": matchSelected[0].id
          }

            // menu com scroll
            this.menuScroll();

            // console.log('endpoint 2', this.partida)
            if (this.aovivo) {
              this.tempo = 10000;
              this.interval = setInterval(() => {
                this.useful.getJogosAoVivo();
              }, this.tempo);
            }
          }
      });
    }
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscription.unsubscribe();
      this.subscriptionAoVivo.unsubscribe();
    }

    menuScroll() {
      const links = document.querySelectorAll('.navList li a');
      const sections = document.querySelectorAll<HTMLElement>('.container-menu');

      window.addEventListener('scroll', function() {
        let index = sections.length;

       if (sections.length > 0) {
         while(--index && window.scrollY + (window.scrollY/6) < sections[index].offsetTop) {}

         links.forEach((link) => link.classList.remove('actived'));
         links[index] != undefined && (links[index].classList.add('actived'));
        }
      });
    }

    jump(h){
      let top = document.getElementById(h).offsetTop;
      window.scrollTo(0, top - 80);
  }
}
