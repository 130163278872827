import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from './loading.service';
import { LoaderState } from './loader';

@Component({
	selector: 'app-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
	show = false;
	private subscription: Subscription;
	constructor(private loaderService: LoadingService) { }
	ngOnInit() {
	  this.subscription = this.loaderService.loaderState
	  .subscribe((state: LoaderState) => {
		this.show = state.show;
	  });
	}
	ngOnDestroy() {
	  this.subscription.unsubscribe();
	}

}