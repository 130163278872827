<div class="container-inner tempo">
    <h2>Tempo</h2>
    <div class="container-tempo">
        <ul class="menu-selects" *ngIf="!useful.isMobile">
            <li>
                <label for="">Fundamentos</label>
                <select name="fundamentosTempo" #fundamentosTempo (change)="updateTempoGraph({selectFundamento: fundamentosTempo.value})">
                    <option *ngFor="let item of orderedFoundations" [value]="item.id">
                        {{ item.nome }}
                    </option>
                </select>
            </li>
            <li>
                <label for="">Jogadores {{ this.partida.mandante.sigla }}</label>
                <select name="jogadoresMandanteTempo" #jogadoresMandanteTempo (change)="updateTempoGraph({jogadorMandante: jogadoresMandanteTempo.value})">
                    <option value="todos">Todos</option>
                    <option *ngFor="let item of jogadoresTempoMandante" [value]="item.idPlayer" [selected]="playerValueMandante == item.idPlayer">
                        {{ item.player }}
                    </option>
                </select>
            </li>
            <li>
                <label for="">Jogadores {{ this.partida.visitante.sigla }}</label>
                <select name="jogadoresVisitanteTempo" #jogadoresVisitanteTempo (change)="updateTempoGraph({jogadorVisitante: jogadoresVisitanteTempo.value})">
                    <option value="todos">Todos</option>
                    <option *ngFor="let item of jogadoresTempoVisitante" [value]="item.idPlayer" [selected]="playerValueVisitante == item.idPlayer">
                        {{ item.player }}
                    </option>
                </select>
            </li>
        </ul>

        <mat-accordion *ngIf="useful.isMobile">
          <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                    Filtro
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="menu-selects">
                <li>
                    <label for="">Fundamentos</label>
                    <select name="fundamentosTempo" #fundamentosTempo (change)="updateTempoGraph({selectFundamento: fundamentosTempo.value})">
                        <option *ngFor="let item of orderedFoundations" [value]="item.id">
                            {{ item.nome }}
                        </option>
                    </select>
                </li>
                <li>
                    <label for="">Jogadores {{ this.partida.mandante.sigla }}</label>
                    <select name="jogadoresMandanteTempo" #jogadoresMandanteTempo (change)="updateTempoGraph({jogadorMandante: jogadoresMandanteTempo.value})">
                        <option value="todos">Todos</option>
                        <option *ngFor="let item of jogadoresTempoMandante" [value]="item.idPlayer" [selected]="playerValueMandante == item.idPlayer">
                            {{ item.player }}
                        </option>
                    </select>
                </li>
                <li>
                    <label for="">Jogadores {{ this.partida.visitante.sigla }}</label>
                    <select name="jogadoresVisitanteTempo" #jogadoresVisitanteTempo (change)="updateTempoGraph({jogadorVisitante: jogadoresVisitanteTempo.value})">
                        <option value="todos">Todos</option>
                        <option *ngFor="let item of jogadoresTempoVisitante" [value]="item.idPlayer" [selected]="playerValueVisitante == item.idPlayer">
                            {{ item.player }}
                        </option>
                    </select>
                </li>
              </ul>
          </mat-expansion-panel>
        </mat-accordion>


        <div class="graph-container content">
            <div>
                <strong>
                    <img [src]="partida.mandante.urlLogo">
                    {{ partida.mandante.nome }}
                </strong>
                <div class="six columns interaction" *ngIf="graphTempoMandante != null">
                    <app-line-chart [maxY]="playerSelectedMandante ? fundTime.maxYPlayer : fundTime.maxYTotal" [idChart]="idChartTimeHome" [data]="graphTempoMandante"></app-line-chart>
                  </div>

            </div>
            <div>
                <strong>
                    <img [src]="partida.visitante.urlLogo">
                    {{ partida.visitante.nome }}
                </strong>
                <div class="six columns interaction" *ngIf="graphTempoVisitante != null">
                    <app-line-chart [maxY]="playerSelectedVisitante ? fundTime.maxYPlayer : fundTime.maxYTotal" [idChart]="idChartTimeAway" [data]="graphTempoVisitante"></app-line-chart>
                  </div>
            </div>
        </div>
    </div>
</div>
