<div class="container-inner heatmap">
    <h2>Heatmap</h2>

    <mat-tab-group animationDuration="0ms" (selectedTabChange)="generateHeatMapMandante(null, $event); generateHeatMapVisitante(null, $event)">
        <mat-tab label="Jogo completo">

            <div class="container-heatmap content">
                <ul class="menu-selects" *ngIf="heatmapData != undefined && !useful.isMobile">
                    <li>
                        <label for="">Jogadores {{ this.partida.mandante.sigla }}</label>
                        <select name="jogadoresMandanteHeatmap" id="jogadoresMandanteHeatmap" (change)="generateHeatMapMandante($event)">
                            <option value="todos">Todos</option>
                            <option *ngFor="let item of heatmapData.mandante" [value]="item.idJogador" [selected]="item.idJogador == jogadorMandante">
                                {{ item.player }}
                            </option>
                        </select>
                    </li>
                    <li>
                        <label for="">Jogadores {{ this.partida.visitante.sigla }}</label>
                        <select name="jogadoresVisitanteHeatmap" id="jogadoresVisitanteHeatmap" (change)="generateHeatMapVisitante($event)">
                            <option value="todos">Todos</option>
                            <option *ngFor="let item of heatmapData.visitante" [value]="item.idJogador">
                                {{ item.player }}
                            </option>
                        </select>
                    </li>
                </ul>

                <mat-accordion *ngIf="useful.isMobile">
                  <mat-expansion-panel>
                      <mat-expansion-panel-header>
                          <mat-panel-title>
                              Filtro
                          </mat-panel-title>
                      </mat-expansion-panel-header>

                      <ul class="menu-selects" *ngIf="heatmapData != undefined">
                        <li>
                            <label for="">Jogadores {{ this.partida.mandante.sigla }}</label>
                            <select name="jogadoresMandanteHeatmap" id="jogadoresMandanteHeatmap" (change)="generateHeatMapMandante($event)">
                                <option value="todos">Todos</option>
                                <option *ngFor="let item of heatmapData.mandante" [value]="item.idJogador" [selected]="item.idJogador == jogadorMandante">
                                    {{ item.player }}
                                </option>
                            </select>
                        </li>
                        <li>
                            <label for="">Jogadores {{ this.partida.visitante.sigla }}</label>
                            <select name="jogadoresVisitanteHeatmap" id="jogadoresVisitanteHeatmap" (change)="generateHeatMapVisitante($event)">
                                <option value="todos">Todos</option>
                                <option *ngFor="let item of heatmapData.visitante" [value]="item.idJogador">
                                    {{ item.player }}
                                </option>
                            </select>
                        </li>
                      </ul>

                    </mat-expansion-panel>
                  </mat-accordion>

                <div class="container-overflow">
                    <div>
                        <strong>
                            <img [src]="partida.mandante.urlLogo">
                            {{ partida.mandante.nome }}
                        </strong>

                        <div id="heatmapContainerMandante" class="fields-heatmap">
                            <img src="assets/images/svg/campo-heatmap.svg" *ngIf="!useful.isMobileSm">
                            <img src="assets/images/svg/HeatmapPortrait.svg" *ngIf="useful.isMobileSm">
                        </div>

                        <!-- <i class="icon-arrow-right-field"></i> -->
                    </div>

                    <div>
                        <strong>
                            <img [src]="partida.visitante.urlLogo">
                            {{ partida.visitante.nome }}
                        </strong>

                        <div id="heatmapContainerVisitante" class="fields-heatmap">
                            <img src="assets/images/svg/campo-heatmap.svg" *ngIf="!useful.isMobileSm">
                            <img src="assets/images/svg/HeatmapPortrait.svg" *ngIf="useful.isMobileSm">
                        </div>

                        <!-- <i class="icon-arrow-right-field"></i> -->
                    </div>
                </div>
            </div>

        </mat-tab>

        <mat-tab label="1º tempo">

            <div class="container-heatmap content">
                <ul class="menu-selects" *ngIf="heatmapData != undefined">
                    <li>
                        <label for="">Jogadores {{ this.partida.mandante.sigla }}</label>
                        <select name="jogadoresMandanteHeatmap" id="jogadoresMandanteHeatmap" (change)="generateHeatMapMandante($event, {index:1})">
                            <option value="todos">Todos</option>
                            <option *ngFor="let item of heatmapData.mandante" [value]="item.idJogador" [selected]="item.idJogador == jogadorMandante">
                                {{ item.player }}
                            </option>
                        </select>
                    </li>
                    <li>
                        <label for="">Jogadores {{ this.partida.visitante.sigla }}</label>
                        <select name="jogadoresVisitanteHeatmap" id="jogadoresVisitanteHeatmap" (change)="generateHeatMapVisitante($event, {index:1})">
                            <option value="todos">Todos</option>
                            <option *ngFor="let item of heatmapData.visitante" [value]="item.idJogador">
                                {{ item.player }}
                            </option>
                        </select>
                    </li>
                </ul>

                <div>
                    <div>
                        <strong>
                            <img [src]="partida.mandante.urlLogo">
                            {{ partida.mandante.nome }}
                        </strong>

                        <div id="heatmapContainerMandante" class="fields-heatmap">
                            <img src="assets/images/svg/campo-heatmap.svg" *ngIf="!useful.isMobileSm">
                            <img src="assets/images/svg/HeatmapPortrait.svg" *ngIf="useful.isMobileSm">
                        </div>

                        <!-- <i class="icon-arrow-right-field"></i> -->
                    </div>

                    <div>
                        <strong>
                            <img [src]="partida.visitante.urlLogo">
                            {{ partida.visitante.nome }}
                        </strong>

                        <div id="heatmapContainerVisitante" class="fields-heatmap">
                            <img src="assets/images/svg/campo-heatmap.svg" *ngIf="!useful.isMobileSm">
                            <img src="assets/images/svg/HeatmapPortrait.svg" *ngIf="useful.isMobileSm">
                        </div>

                        <!-- <i class="icon-arrow-right-field"></i> -->
                    </div>
                </div>
            </div>

        </mat-tab>

        <mat-tab label="2º tempo">

            <div class="container-heatmap content">
                <ul class="menu-selects" *ngIf="heatmapData != undefined">
                    <li>
                        <label for="">Jogadores {{ this.partida.mandante.sigla }}</label>
                        <select name="jogadoresMandanteHeatmap" id="jogadoresMandanteHeatmap" (change)="generateHeatMapMandante($event, {index:2})">
                            <option value="todos">Todos</option>
                            <option *ngFor="let item of heatmapData.mandante" [value]="item.idJogador" [selected]="item.idJogador == jogadorMandante">
                                {{ item.player }}
                            </option>
                        </select>
                    </li>
                    <li>
                        <label for="">Jogadores {{ this.partida.visitante.sigla }}</label>
                        <select name="jogadoresVisitanteHeatmap" id="jogadoresVisitanteHeatmap" (change)="generateHeatMapVisitante($event, {index:2})">
                            <option value="todos">Todos</option>
                            <option *ngFor="let item of heatmapData.visitante" [value]="item.idJogador">
                                {{ item.player }}
                            </option>
                        </select>
                    </li>
                </ul>

                <div>
                    <div>
                        <strong>
                            <img [src]="partida.mandante.urlLogo">
                            {{ partida.mandante.nome }}
                        </strong>

                        <div id="heatmapContainerMandante" class="fields-heatmap">
                            <img src="assets/images/svg/campo-heatmap.svg" *ngIf="!useful.isMobileSm">
                            <img src="assets/images/svg/HeatmapPortrait.svg" *ngIf="useful.isMobileSm">
                        </div>

                        <i class="icon-arrow-right-field"></i>
                    </div>

                    <div>
                        <strong>
                            <img [src]="partida.visitante.urlLogo">
                            {{ partida.visitante.nome }}
                        </strong>

                        <div id="heatmapContainerVisitante" class="fields-heatmap">
                            <img src="assets/images/svg/campo-heatmap.svg" *ngIf="!useful.isMobileSm">
                            <img src="assets/images/svg/HeatmapPortrait.svg" *ngIf="useful.isMobileSm">
                        </div>

                        <i class="icon-arrow-right-field"></i>
                    </div>
                </div>
            </div>

        </mat-tab>
    </mat-tab-group>
</div>
