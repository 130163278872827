import { Component, Input, OnInit } from '@angular/core';
import { UsefulService } from '../../../shared/useful/useful.service';
import * as h337 from 'heatmap.js';
import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss']
})
export class HeatmapComponent implements OnInit {
    @Input() partida: any;
    @Input() atualizacao: any;

    heatmapData: any;
    heatmapConfig: any;
    interval: any;
    subscription: Subscription;
    jogadorMandante: any = 'todos';
    jogadorVisitante: any = 'todos';
    tempoMandante: any = 0;
    tempoVisitante: any = 0;
    myY: any;
    myX: any;

    constructor(
      public useful : UsefulService
    ) { }

    ngOnInit() {
      this.useful.getHeatmap(this.partida);

      if (this.atualizacao != undefined) {
        this.interval = setInterval(() => {
          this.useful.getHeatmap(this.partida);
        }, this.atualizacao);
      }

      this.subscription = this.useful.dataObservableHeatMap.subscribe((res:any) => {
        this.heatmapData = res;

        if (this.heatmapData != undefined) {
          document.querySelector('#heatmapContainerMandante.fields-heatmap').classList.add("temporary-width");
          document.querySelector('#heatmapContainerVisitante.fields-heatmap').classList.add("temporary-width");

          this.generateHeatMapMandante();
          this.generateHeatMapVisitante();
        };

        // console.log('HeatMap', this.heatmapData)
      });
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscription.unsubscribe();
    }

    generateHeatMapMandante(e?, tempo?) {
      e != undefined && e != null && (this.jogadorMandante = e.target.value);
      tempo != undefined && tempo != null && (this.tempoMandante = tempo.index);


      // console.log('generateHeatMapMandante tempo', tempo);
      // console.log('generateHeatMapMandante jogador', e);

      const containerMandante = document.getElementById('heatmapContainerMandante');
      // console.log('containerMandante', containerMandante);

      if (containerMandante.querySelector('.heatmap-canvas')) {
        containerMandante.querySelector('.heatmap-canvas').remove();
      }

      if (this.jogadorMandante == 'todos'){
        this.heatmapConfig = {
          container: containerMandante,
          radius: 41,
          maxOpacity: 0.9,
          minOpacity: 0,
          blur: 0.15,
          visible: true,
          gradient: {
            '0.47': '#2000FF',
            '0.35': 'blue',
            '0.52': 'cyan',
            '0.55': 'lime',
            '0.71': 'yellow',
            '0.80': 'red'
          }
        }
      } else {
          this.heatmapConfig = {
            container: containerMandante,
            radius: 7,
            maxOpacity: .9,
            minOpacity: .9,
            blur: .4,
            visible: true,
            gradient: {
              '0.1': 'lime',
              '0.18': 'yellow',
              '0.5': 'red'
            }
          }
      }

      const heatmapMandante = h337.create(this.heatmapConfig);

      let tempM = [];

      for (const i of this.heatmapData.mandante) {
        if(i.idJogador == this.jogadorMandante) {
          for (const j of i.interacao) {
            const temp = j;
            if (this.tempoMandante != 0 && temp.idPeriodoJogo == this.tempoMandante) {
              let mx = j.x*500 as any;
              let my = j.y*350 as any;
              if (this.useful.isMobileSm) {
                tempM.push({x:parseInt(my), y:parseInt(mx), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              } else {
                tempM.push({x:parseInt(mx), y:parseInt(my), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              }
            } else if (this.tempoMandante == 0) {
              let mx = j.x*500 as any;
              let my = j.y*350 as any;
              if (this.useful.isMobileSm) {
                tempM.push({x:parseInt(my), y:parseInt(mx), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              } else {
                tempM.push({x:parseInt(mx), y:parseInt(my), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              }
            }
          }

          heatmapMandante.setData({
            max: 13,
            min: 0,
            data: tempM
          });
        }

        if (this.jogadorMandante == 'todos') {
          for (const j of i.interacao) {
            const temp = j;
            if (temp.idPeriodoJogo == this.tempoMandante) {
              let mx = j.x*500 as any;
              let my = j.y*350 as any;
              if (this.useful.isMobileSm) {
                tempM.push({x:parseInt(my), y:parseInt(mx), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              } else {
                tempM.push({x:parseInt(mx), y:parseInt(my), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              }
            } else if (this.tempoMandante == 0) {
              let mx = j.x*500 as any;
              let my = j.y*350 as any;
              if (this.useful.isMobileSm) {
                tempM.push({x:parseInt(my), y:parseInt(mx), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              } else {
                tempM.push({x:parseInt(mx), y:parseInt(my), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              }
            }
          }

          heatmapMandante.setData({
            max: 30,
            min: 0,
            data: tempM
          });
        }

      }
      document.querySelector('#heatmapContainerMandante.fields-heatmap').classList.remove("temporary-width");

      // console.log('tempm mandante', tempM);
    }

    generateHeatMapVisitante(e?, tempo?) {
      e != undefined && e != null && (this.jogadorVisitante = e.target.value);
      tempo != undefined && tempo != null && (this.tempoVisitante = tempo.index);

      // console.log('generateHeatMapVisitante tempo', tempo);
      // console.log('generateHeatMapVisitante jogador', e);

      const containerVisitante = document.getElementById('heatmapContainerVisitante');
      // console.log('containerVisitante', containerVisitante);

      if (containerVisitante.querySelector('.heatmap-canvas')) {
        containerVisitante.querySelector('.heatmap-canvas').remove();
      }

        if (this.jogadorVisitante == 'todos'){
          this.heatmapConfig = {
            container: containerVisitante,
            radius: 41,
            maxOpacity: 0.9,
            minOpacity: 0,
            blur: 0.15,
            visible: true,
            gradient: {
              '0.47': '#2000FF',
              '0.35': 'blue',
              '0.52': 'cyan',
              '0.55': 'lime',
              '0.71': 'yellow',
              '0.80': 'red'}
            }
        } else {
          this.heatmapConfig = {
            container: containerVisitante,
            radius: 7,
            maxOpacity: .9,
            minOpacity: .9,
            blur: .4,
            visible: true,
            gradient: {
              '0.1': 'lime',
              '0.18': 'yellow',
              '0.5': 'red'
            }
          }
        }

      const heatmapVisitante = h337.create(this.heatmapConfig);

      let tempM = [];

      for (const i of this.heatmapData.visitante) {

        if(i.idJogador == this.jogadorVisitante) {
          for (const j of i.interacao) {
            const temp = j;
            if (this.tempoVisitante != 0 && temp.idPeriodoJogo == this.tempoVisitante) {
              let mx = j.x*500 as any;
              let my = j.y*350 as any;
              if (this.useful.isMobileSm) {
                tempM.push({x:parseInt(my), y:parseInt(mx), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              } else {
                tempM.push({x:parseInt(mx), y:parseInt(my), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              }
            } else if (this.tempoVisitante == 0) {
              let mx = j.x*500 as any;
              let my = j.y*350 as any;
              if (this.useful.isMobileSm) {
                tempM.push({x:parseInt(my), y:parseInt(mx), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              } else {
                tempM.push({x:parseInt(mx), y:parseInt(my), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              }
            }
          }

          heatmapVisitante.setData({
            max: 13,
            min: 0,
            data: tempM
          });
        }


        if (this.jogadorVisitante == 'todos') {
          for (const j of i.interacao) {
            const temp = j;
            if (temp.idPeriodoJogo == this.tempoVisitante) {
              let mx = j.x*500 as any;
              let my = j.y*350 as any;
              if (this.useful.isMobileSm) {
                tempM.push({x:parseInt(my), y:parseInt(mx), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              } else {
                tempM.push({x:parseInt(mx), y:parseInt(my), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              }
            } else if (this.tempoVisitante == 0) {
              let mx = j.x*500 as any;
              let my = j.y*350 as any;
              if (this.useful.isMobileSm) {
                tempM.push({x:parseInt(my), y:parseInt(mx), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              } else {
                tempM.push({x:parseInt(mx), y:parseInt(my), player:i.player, idJogador: i.idJogador, idPeriodoJogo: j.idPeriodoJogo});
              }
            }
          }

          heatmapVisitante.setData({
            max: 30,
            min: 0,
            data: tempM
          });
        }
      }
      document.querySelector('#heatmapContainerVisitante.fields-heatmap').classList.remove("temporary-width");

      // console.log('tempm visitante', tempM);
    }
}
