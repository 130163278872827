import { Component, SimpleChanges, OnChanges, AfterViewInit, HostListener } from '@angular/core';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import * as borderRadius from 'highcharts-border-radius'

More(Highcharts);

export interface GraphBarData {
  players: string[];
  success: number[];
  error: number[];
}

@Component({
  selector: 'app-graph-bar',
  inputs: ['data', 'idChart', 'maxPlot'],
  templateUrl: './graph-bar.component.html',
  styleUrls: ['./graph-bar.component.scss'],
})
export class GraphBarComponent implements OnChanges, AfterViewInit {
  data: GraphBarData;
  idChart: string;
  chart: any;
  options: any;
  maxPlot: any;

  ngAfterViewInit(): void {
    this.initChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.previousValue) {
      if (changes.data.previousValue != this.data) {
        this.updateChart();
      }
    }
  }

  updateChart() {
    this.updateChartOptions();
    this.chart.update(this.options);
  }

  initChart() {
    borderRadius(Highcharts);
    this.updateChartOptions();
    this.chart = Highcharts.chart(this.idChart, this.options);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateChart();
  }

  updateChartOptions() {
    this.options = {
      colors: ['#00c056', '#b8312f'],
      chart: {
        type: 'bar'
      },
      title: { text: '' },
      tooltip: {
        backgroundColor: 'rgba(241,241,241,0.96)',
        borderWidth: 0,
        borderRadius: 8,
        shadow: false,
        useHTML: true,
        headerFormat: `
          <span style="font-weight:500; color:#2a2f36">{point.x}</span>
          <br/>
        `,
        pointFormat: `
          <span style=" color:{series.color};">●</span>
          <span style="color:#55606f">{series.name}: {point.y}</span>
          <br/>
          <span style="color:#55606f">● Total: {point.stackTotal}</span>
        `,
      },
      xAxis: {
        categories: this.data.players,
      },
      yAxis: {
        title: '',
        min: 0,
        minRange: this.maxPlot,
        labels: {
          style: {
            fontSize: '10px',
            color: '#ccc',
          },
        },
        crosshair: {
          enabled: true,
          color: '#ccc',
        },
        lineWidth: 0,
        tickInterval: 5,
        reversedStacks: false,
        endOnTick: true,
        showLastLabel: true,
      },
      plotOptions: {
        series: {
          animation:{
            defer:0,
            duration:1000
          },
          // borderRadiusTopLeft: 3,
          // borderRadiusTopRight: 3,
          // stacking: 'normal',
          // groupPadding: .00010,
          // pointPadding: .1,
          borderRadius: 3,
          // pointWidth: 10,
          dataLabels: {
            enabled: true,
            color: '#636363',
            style: {
              fontWeight: '500',
              fontSize: '9px',
              textOutline: '0px',
              textAlign: 'right',
              lineHeight: '10px'
            },
          },
        },
        column: {
          // stacking: 'normal',
        },
      },
      legend: { enabled: false },
      credits: { enabled: false },
      series: [
        {
          name: 'Certos',
          data: this.data.success
        },
        {
          name: 'Errados',
          data: this.data.error
        },
      ],
    };
  }
}
