import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UsefulService } from '../useful/useful.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
	public campeonatos: any;
	public menuItens: any;
  public colorBrazil : any;
  public colorBrazilMenuHouver : any;
  public colorBrazilMenuBlack : any;
  idPartida: any;

	constructor(
	public router: Router,
	public http: HttpClient,
	public useful: UsefulService,
  public activatedRoute: ActivatedRoute
	) {
    this.activatedRoute.params.subscribe( parametros => {

      this.useful.partidaBrasil  = parametros['partida'];
    });
   }

	ngOnInit() {
		this.useful.getCampeonatos();

		this.useful.dataObservableCampeonatos.subscribe((res:any) => {
			this.campeonatos = res;

			// monto o meni principal
			this.menuItens = [
				{
					name: 'Partidas',
					icon: 'icon-ball',
					url: null,
					submenu: this.campeonatos
				},
				{
					name: 'Ranking',
					icon: 'icon-ranking',
					url: null,
					submenu: [
						{
							name: 'Equipes',
							url: null,
							submenu: this.campeonatos
						},
						{
							name: 'Jogadores',
							url: null,
							submenu: this.campeonatos
						}
					]
				},
			];

			// ajusto altura do menu com pelo menos o mínimo do menu lateral
			// mudar esse settimeout depois
			setTimeout(() => {
				if (!this.useful.isMobile) {
					const elems = document.querySelectorAll('#menu-itens .menuSide ul')  as unknown as HTMLCollectionOf<HTMLElement>;
					const elMenu = <HTMLElement>document.querySelector('#menu');
					let maxHeight = 0;

					Array.from(elems).forEach((el) => el.offsetHeight > maxHeight && (maxHeight = el.offsetHeight));

					// maxHeight = maxHeight + 350;

					Array.from(elems).forEach((el) => el.style.minHeight = maxHeight+'px');

					elMenu.style.minHeight = maxHeight+'px';
				} else {
					console.log('else');
					const mainMenu = <HTMLElement>document.querySelector('#main-menu');
					const elems = document.querySelectorAll('#menu-itens .menuSide')  as unknown as HTMLCollectionOf<HTMLElement>;

					let myHeight = window.innerHeight - 60;

					Array.from(elems).forEach((el) => el.style.height = myHeight+'px');
					mainMenu.style.height = myHeight+'px';
				}
			}, 1000);
		});
	}

	openMenuMobile() {
		document.getElementById('open-menu').classList.toggle('open');
		document.getElementById('menu').classList.toggle('open-menu');
		Array.from(document.querySelectorAll('.menuSide')).forEach((el) => el.classList.remove('submenu-opened'));
	}

	openMainSubmenu(e:any) {

	  // fecha item do menu ao abrir outro
	  const mainContainer = document.getElementById('main-container');
		let menuItems = document.querySelectorAll("#menu-itens a")
    Array.from(menuItems).forEach( (value) => {
      if(value != e.target && !value.parentElement.contains(e.target)){

          value.classList.remove("actived");
          value.nextElementSibling.classList.remove('submenu-opened');
          mainContainer.classList.remove('menuSideOpened');
      }
    })

    e.target.classList.toggle('actived');
		e.target.nextElementSibling.classList.toggle('submenu-opened');

	}

	campeonatoSelecionado(item) {
		this.useful.saveInLocalStore('nomeCampeonato', item.nome);
		this.router.navigateByUrl(`/partidas/${item.id}`);
    this.useful.partidaBrasil = 0;
	}

	rankingSelecionado(item, type) {
		this.router.navigateByUrl(`/ranking/${type}/${item.id}`);
    this.useful.partidaBrasil = 0;
	}
  clickValueBrasil(){
    this.useful.partidaBrasil = 0;
  }

	closeMenuCampeonatos(e) {
		const mainContainer = document.getElementById('main-container');
		mainContainer.classList.remove('menuSideOpened');

		Array.from(document.querySelectorAll('.menuSide')).forEach((el) => el.classList.remove('submenu-opened'));
		if (e.target.classList.value != "actived") {
			Array.from(document.querySelectorAll('.menuSide li')).forEach((el) => el.classList.remove('actived'));

			e.target.classList.add('actived');
		} else {
			return false
		}

		// fecho o menu mobile
		if (this.useful.isMobile) {
			this.openMenuMobile();
		}
	}

	openMenuCampeonatos(e:any) {
	  const mainContainer = document.getElementById('main-container');

		if (e.target.classList.value != "actived") {
			Array.from(document.querySelectorAll('#menu-itens .submenu a')).forEach((el) => el.classList.remove('actived'));
			Array.from(document.querySelectorAll('#menu-itens .menuSide')).forEach((el) => el.classList.remove('submenu-opened'));
			mainContainer.classList.remove('menuSideOpened');
		}


    mainContainer.classList.toggle('menuSideOpened');
		e.target.classList.toggle('actived');
		e.target.nextElementSibling.classList.toggle('submenu-opened');
	}

	openSubmenu(e:any) {
		e.target.classList.toggle('actived');

		const mySubmenu = document.getElementById('submenu');
		if(mySubmenu != null) {
			mySubmenu.classList.toggle('submenu-opened');
		}
	}

}
