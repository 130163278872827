import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PartidasComponent } from './pages/partidas/partidas.component';
import { ScoutsComponent } from './pages/scouts/scouts.component';
import { RankingEquipesComponent } from './pages/ranking-equipes/ranking-equipes.component';
import { RankingJogadoresComponent } from './pages/ranking-jogadores/ranking-jogadores.component';
import { PaginaInicialComponent } from './pages/pagina-inicial/pagina-inicial.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';


const routes: Routes = [
  { path: "", component: PaginaInicialComponent },
  { path: "partidas/:campeonato", component: PartidasComponent },
  { path: "partidas/:campeonato/scouts/:partida", component: ScoutsComponent },
  { path: "ranking/equipes/:id", component: RankingEquipesComponent },
  { path: "ranking/jogadores/:id", component: RankingJogadoresComponent },
  { path: "pagina-nao-encontrada", component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
