import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsefulService } from '../../../shared/useful/useful.service';

@Component({
  selector: 'app-campo',
  templateUrl: './campo.component.html',
  styleUrls: ['./campo.component.scss']
})
export class CampoComponent implements OnInit {
    @Input() partida: any;
    @Input() idCampeonato: Number;
    @Input() atualizacao: any;

    interval: any;
    interval1: any;
    quadranteData: any;
    fundamentoData: any;
    jogadores: any;
    subscription: Subscription;
    orderedFoundations = [
      { id: 2, nome: 'Passes'},
      { id: 3, nome: 'Finalizações'},
      { id: 5, nome: 'Desarmes'},
      { id: 14, nome: 'Cruzamentos'},
      { id: 1, nome: 'Lançamentos'},
      { id: 24, nome: 'Interceptações'},
      { id: 4, nome: 'Dribles'},
      { id: 22, nome: 'Viradas de jogo'},
      { id: 8, nome: 'Gols'},
      { id: 20, nome: 'Posse de bola'},
      { id: 16, nome: 'Escanteios'},
      { id: 28, nome: 'Assistências para finalização'},
      { id: 27, nome: 'Assistências'},
      { id: 15, nome: 'Defesas'},
      { id: 23, nome: 'Defesas difíceis'},
      { id: 19, nome: 'Rebatidas'},
      { id: 6, nome: 'Faltas cometidas'},
      { id: 9, nome: 'Impedimentos'},
      { id: 11, nome: 'Pênaltis'},
      { id: 12, nome: 'Perdas de posse de bola'},
      { id: 25, nome: 'Cartões amarelos'},
      { id: 26, nome: 'Cartões vermelhos'},
    ];

    /**Valores dos campos */
    valoresQuadMandantes:(number | '')[]|string[] = [];
    valoresQuadVisitantes:(number | '')[]|string[] = [];

    /**Necessário para gravar o estado das seleções */
    stateCampo: {tempoJogo: string|number, fundamento: string|number, visualizacao: string, tipoNum: string, tipoQuad: string, jogadorMandante: string|number, jogadorVisitante: string|number};


    constructor(
      public useful : UsefulService
    ) { }

    ngOnInit() {
      // pego os quadrantes
      this.useful.getQuadrante(this.partida);

      if (this.atualizacao != undefined) {
        this.interval = setInterval(() => {
          this.useful.getQuadrante(this.partida);
        }, this.atualizacao);
      }

      this.subscription = this.useful.dataObservableQuadrante.subscribe((res:any) => {
        this.quadranteData = res;
        // console.log('Campo', this.quadranteData)

        // container campo
        this.selectCampo({});
      });

      // pego os nomes dos jogadores
      this.jogadores = this.useful.getHeatmap(this.partida);
      // this.interval1 = setInterval(() => {
      //   this.useful.getHeatmap(this.partida);
      // }, this.atualizacao);
      // this.useful.dataObservableHeatMap.subscribe((res:any) => {
        // this.jogadores = res;
        // console.log('Jogadores', this.quadranteData);
      // });

      this.stateCampo = {
        tempoJogo: '0',
        fundamento: this.orderedFoundations.length !== 0 ? this.orderedFoundations[0].id : 2,
        visualizacao: 'todos',
        tipoNum: 'absolutos',
        tipoQuad: 'por9',
        jogadorMandante: 'todos',
        jogadorVisitante: 'todos'
      };
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscription.unsubscribe();
      // clearInterval(this.interval1);
    }

    selectCampo(options: {
      tempoJogo?: string|number,
      fundamento?: string|number,
      visualizacao?: string,
      tipoNum?: string,
      tipoQuad?: string,
      jogadorMandante?: string|number,
      jogadorVisitante?: string|number
    }) {

      this.stateCampo = {...this.stateCampo, ...options};

      const idTeamMand = this.partida.mandante.id;
      const idTeamVis = this.partida.visitante.id;

      // Esses valores são fixos, portanto vou defini-los assim
      // Nessa ordem devido a forma que é incluso no HTML
      const quad36 = [1,7,13,19,25,31,2,8,14,20,26,32,3,9,15,21,27,33,4,10,16,22,28,34,5,11,17,23,29,35,6,12,18,24,30,36];

      // Esses valores são fixos, portanto vou defini-los assim
      // Nessa ordem devido a forma que é incluso no HTML
      const quad9 = [
        [1,2,7,8],
        [13,14,19,20],
        [25,26,31,32],
        [3,4,9,10],
        [15,16,21,22],
        [27,28,33,34],
        [5,6,11,12],
        [17,18,23,24],
        [29,30,35,36],
      ];

      const dataQuad = this.stateCampo.tipoQuad === 'por9'
        ? quad9.map(quad => quad.map(q => this.quadranteData.filter(d => d.idQuadrant36 == q).flat()))
        : quad36.map(quad => [quad]).map(quad => quad.map(q => this.quadranteData.filter(d => d.idQuadrant36 == q).flat()));

      const data = dataQuad.map(quad => quad.map(q => q
        .filter(tempo => this.stateCampo.tempoJogo != 0 ? tempo.tempo == this.stateCampo.tempoJogo : tempo)
        .filter(fund => fund.idFundamento == this.stateCampo.fundamento))
        );

        const dataMandante = data.map(quad => quad.map(q => q.filter(quad => quad.idTeam == idTeamMand)
        .filter(jogMan => this.stateCampo.jogadorMandante !== 'todos' ? jogMan.idJogado == this.stateCampo.jogadorMandante : jogMan)
        .reduce((total, val) => {
            if(this.stateCampo.visualizacao === 'acertos') {
              return val.certos + total;
            } else if(this.stateCampo.visualizacao === 'erros') {
              return val.errados + total;
            }
            return val.certos + val.errados + total;
          },0)
          ).reduce((val, total) => val + total, 0));

        const dataVisitante = data.map(quad => quad.map(q => q.filter(quad => quad.idTeam == idTeamVis)
          .filter(jogVis => this.stateCampo.jogadorVisitante !== 'todos' ? jogVis.idJogado == this.stateCampo.jogadorVisitante : jogVis)
          .reduce((total, val) => {
            if(this.stateCampo.visualizacao === 'acertos') {
              return val.certos + total;
            } else if(this.stateCampo.visualizacao === 'erros') {
              return val.errados + total;
            }
            return val.certos + val.errados + total;
          },0)
        ).reduce((val, total) => val + total, 0));

        const divisorMandate = this.stateCampo.tipoNum === 'absolutos' ? 1 : dataMandante.reduce((val, total) => val + total, 0);
        const divisorVisitante = this.stateCampo.tipoNum === 'absolutos' ? 1 : dataVisitante.reduce((val, total) => val + total, 0);

        this.valoresQuadMandantes = this.stateCampo.tipoNum === 'absolutos'
          ? dataMandante.map(d => d > 0 ? d/divisorMandate : '')
          : dataMandante.map(d => d > 0 ? ((d/divisorMandate)*100).toFixed(1) + '%' : '');

          this.valoresQuadVisitantes = this.stateCampo.tipoNum === 'absolutos'
          ? dataVisitante.map(d => d > 0 ? d/divisorVisitante : '')
          : dataVisitante.map(d => d > 0 ? ((d/divisorVisitante)*100).toFixed(1) + '%' : '');
    }
}
