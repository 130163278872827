<section *ngIf="data != undefined">
  <!-- <ng-container *ngIf="data.length < 1 ? true : false; else PartidasAoVivo">
    <div class="semPartidas">
      <h1><strong>Bem vindo ao I2A</strong></h1>
      <h2>A melhor plataforma de dados <br> e insights sobre futebol</h2>
    </div>
    <img src="assets/images/svg/jogador.svg" alt="Jogador">
  </ng-container>

  <ng-template #PartidasAoVivo> -->
  <h1 class="bigger">Bem vindo ao I2A</h1>

  <h1 *ngIf="partidasAoVivo.length > 0 || partidasProximas.length > 0">
    <ng-container *ngIf="partidasAoVivo.length < 1; else h1aoVivo">Não há partidas ao vivo no momento,</ng-container>
    <ng-template #h1aoVivo>
      <span>Confira as partidas ao vivo,</span>
    </ng-template>
    <br />
    <ng-container *ngIf="partidasProximas.length < 1; else h1partidasProximas">
      não há partidas previstas para hoje.</ng-container>
    <ng-template #h1partidasProximas>
      <span> não perca as próximas partidas do dia!</span>
    </ng-template>
  </h1>
  <div class="cardPartidaContainer">

  <div id="mc_embed_shell">
    <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
    <style type="text/css">
            #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; width: 400px;}
            /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
               We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    </style>
    <div id="mc_embed_signup">
    <form action="https://footstats.us21.list-manage.com/subscribe/post?u=ea44335f22d90d9a95ae220e1&amp;id=44062c2400&amp;f_id=008a5ee1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
    <div id="mc_embed_signup_scroll"><h2>Vem aí o app da i2a com insights para apostas de futebol. Seja o primeiro a saber sobre todas as novidades!    </h2>
    <div class="indicates-required"><span class="asterisk">*</span>Campo obrigatório</div>
    <div class="mc-field-group"><label for="mce-EMAIL">Endereço de e-mail <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div>
    <div id="mce-responses" class="clear foot">
    <div class="response" id="mce-error-response" style="display: none;"></div>
    <div class="response" id="mce-success-response" style="display: none;"></div>
    </div>
    <div aria-hidden="true" style="position: absolute; left: -5000px;">
            /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
    <input type="text" name="b_ea44335f22d90d9a95ae220e1_44062c2400" tabindex="-1" value="">
    </div>
    <div class="optionalParent">
    <div class="clear foot">
    <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe">
    <!-- <p style="margin: 0px auto;"><a href="http://eepurl.com/ivq19E" title="Mailchimp - email marketing made easy and fun"><span style="display: inline-block; background-color: transparent; border-radius: 4px;"><img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style="width: 220px; height: 40px; display: flex; padding: 2px 0px; justify-content: center; align-items: center;"></span></a></p> -->
    </div>
    </div>
    </div>
    </form>
    </div>
    <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>


    <div [class]="
        item.hasScout
          ? 'aovivo cardPartida aovivo-animacao'
          : 'semDetalhes aovivo cardPartida aovivo-animacao'
      " *ngFor="let item of partidasAoVivo" (click)="scoutEscolhido(item)">
      <h2>
        <span>{{ item.championship }}</span>
        <small *ngIf="item.realtime" class="flag aovivo">Ao vivo</small>
      </h2>
      <small>{{ useful.formatDate(item.isoDate) }}</small>
      <div class="placarJogo">
        <div class="placarEsq">
          <img [src]="item.teamUrlLogoHome" [title]="item.teamInitialsHome" />
          <span>{{ item.teamInitialsHome }}</span>
        </div>

        <div class="placarGols">
          <strong *ngIf="item.goalsHome !== null">
            {{ item.goalsHome }} x {{ item.goalsAway }}
          </strong>
          <small>{{ item.gameTime }}</small>
        </div>

        <div class="placarDir">
          <img [src]="item.teamUrlLogoAway" [title]="item.teamInitialsAway" />
          <span>{{ item.teamInitialsAway }}</span>
        </div>
      </div>

      <div class="placarEstatistica">
        <ul>
          <li>
            <div class="graphic graphic-circle">
              <span class="text-gray-700">
                <ng-container *ngIf="item.predicao != undefined">
                  {{
                  useful.makeMeInin1decimal(
                  item.predicao.homeTeamProbability
                  )
                  }}%
                </ng-container>
              </span>
              <div class="bar" *ngIf="item.predicao != undefined" [style.background-image]="
                  'conic-gradient(from 0deg, #9d9d9d 0 ' +
                  useful.makeMeInin1decimal(item.predicao.homeTeamProbability) +
                  '%, transparent 0)'
                "></div>
            </div>
          </li>
          <li>
            <div class="graphic graphic-circle">
              <span class="text-gray-700">
                <ng-container *ngIf="item.predicao != undefined">
                  {{
                  useful.makeMeInin1decimal(item.predicao.drawProbability)
                  }}%
                </ng-container>
              </span>
              <div class="bar" *ngIf="item.predicao != undefined" [style.background-image]="
                  'conic-gradient(from 0deg, #9d9d9d 0 ' +
                  useful.makeMeInin1decimal(item.predicao.drawProbability) +
                  '%, transparent 0)'
                "></div>
            </div>
          </li>
          <li>
            <div class="graphic graphic-circle">
              <span class="text-gray-700">
                <ng-container *ngIf="item.predicao != undefined">
                  {{
                  useful.makeMeInin1decimal(
                  item.predicao.awayTeamProbability
                  )
                  }}%
                </ng-container>
              </span>
              <div class="bar" *ngIf="item.predicao != undefined" [style.background-image]="
                  'conic-gradient(from 0deg, #9d9d9d 0 ' +
                  useful.makeMeInin1decimal(item.predicao.awayTeamProbability) +
                  '%, transparent 0)'
                "></div>
            </div>
          </li>
        </ul>
        <small>Probabilidades</small>
      </div>

      <ng-container *ngIf="item.hasScout; else semDetalhes">
        <a href="javascript:void(0);" class="verDetalhes">Ver detalhes <i class="icon-arrow-right"></i></a>
      </ng-container>
      <ng-template #semDetalhes>
        <span class="verDetalhes">Sem detalhes disponíveis</span>
      </ng-template>
    </div>

    <div class="semDetalhes cardPartida" *ngFor="let item of partidasProximas" (click)="scoutEscolhido(item)">
      <h2>
        <span>{{ item.championship }}</span>
        <small *ngIf="item.realtime" class="flag aovivo">Ao vivo</small>
      </h2>
      <small>{{ useful.formatDate(item.isoDate) }}</small>
      <div class="placarJogo">
        <div class="placarEsq">
          <img [src]="item.teamUrlLogoHome" [title]="item.teamInitialsHome" />
          <span>{{ item.teamInitialsHome }}</span>
        </div>

        <div class="placarGols">
          <strong *ngIf="item.goalsHome !== null">
            {{ item.goalsHome }} x {{ item.goalsAway }}
          </strong>
          <small>{{ item.gameTime }}</small>
        </div>

        <div class="placarDir">
          <img [src]="item.teamUrlLogoAway" [title]="item.teamInitialsAway" />
          <span>{{ item.teamInitialsAway }}</span>
        </div>
      </div>

      <div class="placarEstatistica">
        <ul>
          <li>
            <div class="graphic graphic-circle">
              <span class="text-gray-700">
                <ng-container *ngIf="item.predicao != undefined">
                  {{
                  useful.makeMeInin1decimal(
                  item.predicao.homeTeamProbability
                  )
                  }}%
                </ng-container>
              </span>
              <div class="bar" *ngIf="item.predicao != undefined" [style.background-image]="
                  'conic-gradient(from 0deg, #9d9d9d 0 ' +
                  useful.makeMeInin1decimal(item.predicao.homeTeamProbability) +
                  '%, transparent 0)'
                "></div>
            </div>
          </li>
          <li>
            <div class="graphic graphic-circle">
              <span class="text-gray-700">
                <ng-container *ngIf="item.predicao != undefined">
                  {{
                  useful.makeMeInin1decimal(item.predicao.drawProbability)
                  }}%
                </ng-container>
              </span>
              <div class="bar" *ngIf="item.predicao != undefined" [style.background-image]="
                  'conic-gradient(from 0deg, #9d9d9d 0 ' +
                  useful.makeMeInin1decimal(item.predicao.drawProbability) +
                  '%, transparent 0)'
                "></div>
            </div>
          </li>
          <li>
            <div class="graphic graphic-circle">
              <span class="text-gray-700">
                <ng-container *ngIf="item.predicao != undefined">
                  {{
                  useful.makeMeInin1decimal(
                  item.predicao.awayTeamProbability
                  )
                  }}%
                </ng-container>
              </span>
              <div class="bar" *ngIf="item.predicao != undefined" [style.background-image]="
                  'conic-gradient(from 0deg, #9d9d9d 0 ' +
                  useful.makeMeInin1decimal(item.predicao.awayTeamProbability) +
                  '%, transparent 0)'
                "></div>
            </div>
          </li>
        </ul>
        <small>Probabilidades</small>
      </div>

      <span class="verDetalhes">Sem detalhes disponíveis</span>
    </div>
  </div>

  <hr *ngIf="partidasAoVivo.length > 0 || partidasProximas.length > 0" />

  <h1 *ngIf="partidasFinalizadas.length > 0">Partidas do dia finalizadas</h1>
  <div class="cardPartidaContainer" *ngIf="partidasFinalizadas.length > 0">
    <div [class]="item.hasScout ? 'cardPartida' : 'semDetalhes cardPartida'" *ngFor="let item of partidasFinalizadas"
      (click)="scoutEscolhido(item)">
      <h2>
        <span>{{ item.championship }}</span>
        <small *ngIf="item.realtime" class="flag aovivo">Ao vivo</small>
      </h2>
      <small>{{ useful.formatDate(item.isoDate) }}</small>
      <div class="placarJogo">
        <div class="placarEsq">
          <img [src]="item.teamUrlLogoHome" [title]="item.teamInitialsHome" />
          <span>{{ item.teamInitialsHome }}</span>
        </div>

        <div class="placarGols">
          <strong *ngIf="item.goalsHome !== null">
            {{ item.goalsHome }} x {{ item.goalsAway }}
          </strong>
          <small>{{ item.gameTime }}</small>
        </div>

        <div class="placarDir">
          <img [src]="item.teamUrlLogoAway" [title]="item.teamInitialsAway" />
          <span>{{ item.teamInitialsAway }}</span>
        </div>
      </div>

      <div class="placarEstatistica">
        <ul>
          <li>
            <div class="graphic graphic-circle">
              <span class="text-gray-700">
                <ng-container *ngIf="item.predicao != undefined">
                  {{
                  useful.makeMeInin1decimal(
                  item.predicao.homeTeamProbability
                  )
                  }}%
                </ng-container>
              </span>
              <div class="bar" *ngIf="item.predicao != undefined" [style.background-image]="
                  'conic-gradient(from 0deg, #9d9d9d 0 ' +
                  useful.makeMeInin1decimal(item.predicao.homeTeamProbability) +
                  '%, transparent 0)'
                "></div>
            </div>
          </li>
          <li>
            <div class="graphic graphic-circle">
              <span class="text-gray-700">
                <ng-container *ngIf="item.predicao != undefined">
                  {{
                  useful.makeMeInin1decimal(item.predicao.drawProbability)
                  }}%
                </ng-container>
              </span>
              <div class="bar" *ngIf="item.predicao != undefined" [style.background-image]="
                  'conic-gradient(from 0deg, #9d9d9d 0 ' +
                  useful.makeMeInin1decimal(item.predicao.drawProbability) +
                  '%, transparent 0)'
                "></div>
            </div>
          </li>
          <li>
            <div class="graphic graphic-circle">
              <span class="text-gray-700">
                <ng-container *ngIf="item.predicao != undefined">
                  {{
                  useful.makeMeInin1decimal(
                  item.predicao.awayTeamProbability
                  )
                  }}%
                </ng-container>
              </span>
              <div class="bar" *ngIf="item.predicao != undefined" [style.background-image]="
                  'conic-gradient(from 0deg, #9d9d9d 0 ' +
                  useful.makeMeInin1decimal(item.predicao.awayTeamProbability) +
                  '%, transparent 0)'
                "></div>
            </div>
          </li>
        </ul>
        <small>Probabilidades</small>
      </div>

      <ng-container *ngIf="item.hasScout; else semDetalhes">
        <a href="javascript:void(0);" class="verDetalhes">Ver detalhes <i class="icon-arrow-right"></i></a>
      </ng-container>
      <ng-template #semDetalhes>
        <span class="verDetalhes">Sem detalhes disponíveis</span>
      </ng-template>
    </div>
  </div>





  <!-- </ng-template> -->
</section>
