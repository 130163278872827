import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsefulService } from '../../../shared/useful/useful.service';

@Component({
  selector: 'app-scouts-details',
  templateUrl: './scouts-details.component.html',
  styleUrls: ['./scouts-details.component.scss']
})
export class ScoutsDetailsComponent implements OnInit {
    @Input() partida: number;
    @Input() idCampeonato: Number;
    @Input() idPartida: Number;
    @Input() atualizacao: any;

    scoutDetails: any = {};
    show: Boolean = false;

    interval: any;
    subscription: Subscription;
    logoMandante: any;
    logoVisitante: any
    fundamentoObj: any;
    fundamentoScout:any = 'Passes';
    fundamentoMobile:any = 'total';
    fundamentoIcon:any = '';
    slideConfig = {
      dots: false,
      slidesToShow: 5,
      arrows: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
            // centerMode: true,
            initialSlide: 2
          }
        },
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            dots: true,
            arrows: true,
            // centerMode: true,
            initialSlide: 2
          }
        },
        {
          breakpoint: 1030,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
            // centerMode: true,
            initialSlide: 2
          }
        },
        {
          breakpoint: 1375,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
            // centerMode: true,
            initialSlide: 1
          }
        },
      ]
    };

    constructor(
      public useful : UsefulService
    ) { }

    ngOnInit() {
      this.useful.getScouts(this.idCampeonato, this.partida);
      if (this.atualizacao != undefined) {
        this.interval = setInterval(() => {
          this.useful.getScouts(this.idCampeonato, this.partida);
        }, this.atualizacao);
      }

      this.subscription = this.useful.dataObservableScoutDetails.subscribe((res:any) => {
        this.scoutDetails = this.useful.getInLocalStore('scoutDetails');
        this.scoutDetails.shift();
        // console.log('this.show', this.show)
        // console.log('ScoutDetails', this.scoutDetails)
        this.getDetalhe(this.fundamentoScout)
      });
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscription.unsubscribe();
    }

    mudarFundamentoMobile(e, tempo) {
      Array.from(document.querySelectorAll('.navList.nav-scouts a')).forEach((el) => el.classList.remove('actived'));

      e.target.classList.toggle('actived');

      this.fundamentoMobile = tempo;
    }

    verMaisScouts(e) {
      const elMenu = <HTMLElement>document.querySelector('.content-table');
      elMenu.classList.toggle('opened');
      e.target.textContent == "Ver mais" ? e.target.textContent = "Ver menos" : e.target.textContent = "Ver mais";
    }

    estatisticaScouts(fundamento) {
      // console.log(fundamento)
      this.fundamentoScout = fundamento;
      switch (fundamento) {
        case "Gols":
          this.fundamentoIcon = "icon-Gol"
          break;
        case "Posse de bola":
          this.fundamentoIcon = "icon-Perdas-de-posse-de-bola"
          break;
        case "Escanteios":
          this.fundamentoIcon = "icon-Escanteio"
          break;
        case "Assistências para finalização":
        case "Assistências":
          this.fundamentoIcon = "icon-Assistncias"
          break;
        case "Defesas":
          this.fundamentoIcon = "icon-Defesas"
          break;
        case "Defesas difíceis":
          this.fundamentoIcon = "icon-Defesas-difceis"
          break;
        case "Rebatidas":
          this.fundamentoIcon = "icon-Rebatidas"
          break;
        case "Faltas cometidas":
        case "Faltas sofridas":
          this.fundamentoIcon = "icon-Faltas-cometidas"
          break;
        case "Impedimentos":
          this.fundamentoIcon = "icon-Impedimentos"
          break;
        case "Pênaltis":
          this.fundamentoIcon = "icon-Pnaltis"
          break;
        case "Perda da posse de bola":
          this.fundamentoIcon = "icon-Perdas-de-posse-de-bola"
          break;
        case "Cartões amarelos":
          this.fundamentoIcon = "icon-card_yellow"
          break;
        case "Cartões vermelhos":
          this.fundamentoIcon = "icon-card_red"
          break;
      }
      this.getDetalhe(this.fundamentoScout);
    }

    getDetalhe(inf) {
      const temp = this.scoutDetails.filter((fund) => inf == fund.nome);
      this.fundamentoObj = temp[0];

      this.scoutDetails != undefined && !this.show && (this.show = true);
      this.fundamentoObj.mandante.urlLogo != null && (this.logoMandante = this.fundamentoObj.mandante.urlLogo);
      this.fundamentoObj.visitante.urlLogo != null && (this.logoVisitante = this.fundamentoObj.visitante.urlLogo)
    }
}
