<ul class="notification" *ngIf="show">
  <ng-container *ngFor="let item of messages; let i = index">
    <li [style.background]="item.color" [@slideInOut] [id]="item.id">
      <img [src]="!item.urlTeam ? 'assets/images/svg/icon-empate.svg' : item.urlTeam">
      <div>
        {{ item.message }}
      </div>
      <span class="bt-close" (click)="closeNotification(item.id)"></span>
    </li>
  </ng-container>
</ul>
