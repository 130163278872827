import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsefulService } from '../../../shared/useful/useful.service';

@Component({
  selector: 'app-interacoes',
  templateUrl: './interacoes.component.html',
  styleUrls: ['./interacoes.component.scss']
})
export class InteracoesComponent implements OnInit {
    @Input() partida: any;
    @Input() idCampeonato: Number;
    @Input() idPartida: Number;
    @Input() atualizacao: any;

    interval: any;
    subscription: Subscription;
    interacoes: any;
    interacao: any = 2;
    interacoesSoma: any;
    orderedFoundations = [
      { id: 2, nome: 'Passes'},
      // { id: 3, nome: 'Finalizações'},
      // { id: 5, nome: 'Desarmes'},
      { id: 14, nome: 'Cruzamentos'},
      { id: 1, nome: 'Lançamentos'},
      // { id: 24, nome: 'Interceptações'},
      // { id: 4, nome: 'Dribles'},
      { id: 22, nome: 'Viradas de jogo'},
      // { id: 8, nome: 'Gols'},
      // { id: 20, nome: 'Posse de bola'},
      // { id: 16, nome: 'Escanteios'},
      // { id: 28, nome: 'Assistências para finalização'},
      // { id: 27, nome: 'Assistências'},
      // { id: 15, nome: 'Defesas'},
      // { id: 23, nome: 'Defesas difíceis'},
      // { id: 19, nome: 'Rebatidas'},
      // { id: 6, nome: 'Faltas cometidas'},
      // { id: 9, nome: 'Impedimentos'},
      // { id: 11, nome: 'Pênaltis'},
      // { id: 12, nome: 'Perdas de posse de bola'},
      // { id: 25, nome: 'Cartões amarelos'},
      // { id: 26, nome: 'Cartões vermelhos'},
    ];

    constructor(
      public useful : UsefulService
    ) { }

    ngOnInit() {
      this.useful.getInteracao(this.idCampeonato, this.idPartida, this.interacao);
      if (this.atualizacao != undefined) {
        this.interval = setInterval(() => {
          this.useful.getInteracao(this.idCampeonato, this.idPartida, this.interacao);
        }, this.atualizacao);
      }

      this.subscription = this.useful.dataObservableInteracao.subscribe((res:any) => {
        this.interacoes = res.jogadores;

        if (this.interacoes != null) {
          this.sortInteracoes();
          this.getSomaInteracoes();
        }

        // console.log('Interações', this.interacoes)
      });
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscription.unsubscribe();
    }

   sortInteracoes() {
      for (const i of this.interacoes) {
        i.interacoes = i.interacoes.sort((a,b) => a.jogador.localeCompare(b.jogador, undefined, {sensitivity: 'base'}));
      }
      return this.interacoes.sort((a,b) => a.jogador.localeCompare(b.jogador, undefined, {sensitivity: 'base'}))
    }

    getSomaInteracoes() {
      var result = [];
      for (const i of this.interacoes) {
        let interacoes = [];
        for (const j of i.interacoes) {
          let sum = 0;
          for (const k of i.interacoes) {
            if (j.jogador == k.jogador) {
              sum += k.quantidade;
            }
          }
          interacoes.push({
            'idPlayer': j.idPlayer,
            'jogador': j.jogador,
            'quantidade': sum
          });
        }
        interacoes = [...new Map(interacoes.map(item => [item.idPlayer, item])).values()]
        result.push({
          'idPlayer': i.idPlayer,
          'idTeam': i.idTeam,
          'jogador': i.jogador,
          interacoes
        })
      }
      this.interacoesSoma = result;
      return result;
    }

    backgroundDecimals(v) {
      return (Math.round(v * 100) / 1000).toFixed(2);
    }

    interacaoEscolhida(e) {
      this.interacao = e.target.value;
      this.useful.getInteracao(this.idCampeonato, this.idPartida, this.interacao)
    }
}
