import { Component, SimpleChanges, OnChanges, AfterViewInit, HostListener, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import * as borderRadius from 'highcharts-border-radius'
import { UsefulService } from '../useful/useful.service';

More(Highcharts);

@Component({
  selector: 'app-graph-column-stacked',
  inputs: ['data', 'idChart', 'nameMandante', 'nameVisitante'],
  templateUrl: './graph-column-stacked.component.html',
  styleUrls: ['./graph-column-stacked.component.scss'],
})
export class GraphColumnStackedComponent implements OnChanges, AfterViewInit, OnInit {
  data: any;
  idChart: string;
  chart: any;
  options: any;
  predictions: any = [];
  nameMandante: string;
  nameVisitante: string;

  constructor(
    public useful : UsefulService
  ) {}

  ngOnInit() {
    this.changeData();
  }

  changeData() {
    // junto a predição com o 1 e 2 tempo
    this.predictions = [];
    if (this.data.length != undefined) {
      this.data.filter(e => {
        if(e.gametime == 0 || e.gametime == 2 || e.gametime == 4) {
          return e.predictions.map(v => {
              v['awayTeam'] = v.awayTeam*100;
              v['draw'] =  v.draw*100;
              v['homeTeam'] =  v.homeTeam*100;
              this.predictions.push(v);
            });
        }
      })

      // verifico se há menos do que 90 minutos
      if (this.predictions.length < 90) {
        for (let i = this.predictions.length; i < 90; i++) {
          this.predictions.push({
              awayTeam: 0,
              draw: 0,
              homeTeam: 0
            })

        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.initChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.previousValue) {
      if (changes.data.previousValue != this.data) {
        this.changeData();
        this.updateChart();
      }
    }
  }

  updateChart() {
    this.updateChartOptions();
    this.chart.update(this.options);
  }

  initChart() {
    borderRadius(Highcharts);
    this.updateChartOptions();
    this.chart = Highcharts.chart(this.idChart, this.options);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateChart();
  }

  updateChartOptions() {
    var that = this;
    this.options = {
      colors: ['#4019C7', '#C9B820', '#3C91FF'],
      chart: {
        type: 'column'
      },
      title: {
        text: '1x2',
        margin: 0,
        useHTML: true,
        style: {
          fontSize: '11',
          textIndent: '999em'
        }
      },
      tooltip: {
        formatter: function () {
          let times = this.points.reduce(function (s, point) {
            // console.log('s', s)
            // console.log('point', point)
              return  s  +
              '<b style="color:'+ point.series.color +'">' + point.series.name + ':</b> ' +
              that.useful.makeMeInin1decimal(point.percentage) + '%<br />';
          }, '<b>' + this.x + ' minuto(s)</b><br />');

          let details = this.points.reduce(function (s, point) {
            let gols = '';
            let cartoes = '';

            if (point.point.options.gol != undefined && point.point.options.gol != null) {
              for (const key of point.point.options.gol) {
                gols += `<span style="color: ${key['goalAgainst'] == true ? '#B8312F' : '#000'}">&#x26BD;</span> ` + `${key['goalAgainst'] == true ? `<span style="color: #B8312F; font-weight: bold"> Contra:</span> ${key['player']}` : key['player']}` + "<br />"
              }
            }

            if (point.point.options.cartao != undefined && point.point.options.cartao != null) {
              for (const key of point.point.options.cartao) {
                cartoes += `<span style="color:${key['type'] == 'yellow' ? '#FBCA1F' : '#B8312F'}">&#x25AE;</span>${key['player']}<br />`
              }
            }
             return  s + gols + cartoes;
          }, '<br />');

          return times + details;
        },
        shared: true,
        backgroundColor: '#fff',
        borderColor: '#f1f1f1',
        followPointer: true,
        outside: true,
        // overflow: 'auto',
        // floating: true
      },
      xAxis: {
        categories: this.predictions.map((v, i) =>  i),
      },
      yAxis: {
        title: '',
        min: 0,
        max: 100,
        minRange: 100,
        // labels: {
        //   style: {
        //     color: '#ccc',
        //   },
        // },
        crosshair: {
          enabled: true,
          color: '#ccc',
        },
        lineWidth: 0,
        tickInterval: 5,
        reversedStacks: true,
        endOnTick: true,
        showLastLabel: true,
      },
      plotOptions: {
        series: {
          animation:{
            defer:0,
            duration:500
          },
          // dataLabels: {
          //   enabled: false,
          //   color: '#636363',
          //   style: {
          //     fontWeight: '500',
          //     fontSize: '9px',
          //     textOutline: '0px',
          //     textAlign: 'right',
          //     lineHeight: '10px'
          //   },
          // },
        },
        column: {
          stacking: 'percent',
          pointPadding: -.29,
            borderWidth: 1
        }
      },
      series: [
        {
          name: this.nameMandante,
          data : this.predictions.map(v => {
            let tempObj = {
                'y': v.homeTeam,
                'gol': v.homeGoals != undefined && v.homeGoals.length > 0 ? v.homeGoals : null,
                'cartao': v.homeCards != undefined && v.homeCards.length > 0 ? v.homeCards : null,
                'iconeGol': v.homeGoals != undefined && v.homeGoals.length > 0 ? `<span style="color: ${v.homeGoals[0].goalAgainst == true ? '#B8312F' : '#000'}">&#x26BD</span>` : null,
                'iconeCartao': v.homeCards != undefined && v.homeCards.length > 0 ? `<span style="color:${v.homeCards[0].type == 'yellow' ? '#FBCA1F' : '#B8312F'}">&#x25AE</span>` : null
            }
            return tempObj
          }),
          dataLabels: {
            allowOverlap: true,
            enabled: true,
            distance: 120,
            useHTML: true,
            inside: true,
            format: `{point.iconeGol}{point.iconeCartao}`,
            crop: false,
            overflow: "auto",
            y: -25,
            verticalAlign: 'top'
          }
        },
        {
          name: 'Empate',
          // data: this.predictions.map(v => v.draw)
          data : this.predictions.map(v => {
            let tempObj = {
                'y': v.draw
            }
            return tempObj
          })
        },
        {
          name: this.nameVisitante,
          // data: this.predictions.map(v => v.awayTeam)
          data : this.predictions.map(v => {
            let tempObj = {
                'y': v.awayTeam,
                'gol': v.awayGoals != undefined && v.awayGoals.length > 0 ? v.awayGoals : null,
                'cartao': v.awayCards != undefined && v.awayCards.length > 0 ? v.awayCards : null,
                'iconeGol': v.homeGoals != undefined && v.awayGoals.length > 0 ? `<span style="color: ${v.awayGoals[0].goalAgainst == true ? '#B8312F' : '#000'}">&#x26BD</span>` : null,
                'iconeCartao': v.homeCards != undefined && v.awayCards.length > 0 ? `<span style="color:${v.awayCards[0].type == 'yellow' ? '#FBCA1F' : '#B8312F'}">&#x25AE</span>` : null
            }
            return tempObj
          }),
          dataLabels: {
            allowOverlap: true,
            enabled: true,
            distance: 0,
            useHTML: true,
            inside: true,
            format: `{point.iconeCartao}{point.iconeGol}`,
            crop: false,
            overflow: "visible",
            y: 45,
            verticalAlign: 'bottom'
          }
        }
      ],

      legend: {
        enabled: true,
        backgroundColor: '#f5f5f5',
        borderColor:'#fff',
        borderRadius:3,
        borderWidth:3,
        padding: 10,
        lineHeight:16,
        margin: 30,
      },
      credits: { enabled: false }
    };
  }
}
