import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsefulService } from '../../../shared/useful/useful.service';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { NotificationComponent } from '../../../shared/notification/notification.component';
@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {
    @Input() partida: any;
    @Input() idCampeonato: any;
    @Input() idPartida: Number;
    @Input() atualizacao: any;

    interval: any;
    subscription: Subscription;
    messages: Array<any> =[];
    connection: any;
    msgNotification: any;
    colorNotification: any;
    teamUrl: any;
    show: any = false;

    constructor(
      public useful : UsefulService
    ) { }

    ngOnInit() {
      this.useful.getNotifications(this.idCampeonato, this.idPartida);
      if (this.atualizacao != undefined) {
        this.interval = setInterval(() => {
          this.useful.getNotifications(this.idCampeonato, this.idPartida);
        }, this.atualizacao);
      }
      this.subscription = this.useful.dataObservableNotifications.subscribe((res:any) => {
        this.messages = this.orderInsights(res);
        this.show = true;

        console.log('Messages', this.messages);
      });

      this.broadcastMessage();
    }

    orderInsights(data) {
      let tempo1 = [];
      let tempo2 = [];
      let encerramento = [];

      tempo1 = data.filter(function(item) {
          return item.idGameTime == 2
      });

      tempo2 = data.filter(function(item) {
        return item.idGameTime == 4
      });

      encerramento = data.filter((item) => {
        return item.idGameTime === 11;
      })

      tempo1 = tempo1.sort(function(a,b) {
        let temp1:any = new Date(a.timeInMinutes);
        let temp2:any = new Date(b.timeInMinutes);

        return  temp2 - temp1 ;
      });

      tempo2 = tempo2.sort(function(a,b) {
        let temp1:any = new Date(a.timeInMinutes);
        let temp2:any = new Date(b.timeInMinutes);

        return  temp2 - temp1 ;
      });

      return [...encerramento, ...tempo2, ...tempo1];
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscription.unsubscribe();
    }

    mountTime(minutes, tempo) {
      if (tempo == 2) {
        return minutes > 45 ? `45+${(minutes-45)}` : minutes;
      }

      if (tempo == 4) {
        return minutes > 45 ? `90+${(minutes-45)}` : (minutes+45);
      }

      if (tempo == 11) {
        return ''
      }
    }

    broadcastMessage() {
      const that = this as any;
      this.connection = new HubConnectionBuilder().withUrl('https://footstats-prediction.azurewebsites.net/gameNotificationHub').build();

      this.connection.on("SendEvent", function (messageModel) {
        if (messageModel.idMatch == that.idPartida) {
          that.msgNotification = messageModel.message;
          that.colorNotification = messageModel.color;
          that.teamUrl = messageModel.teamLogoUrl;
        }
      });

      this.connection.start().then(function () {
          console.log('connection started');

      }).catch(function (err) {
          return console.error(err.toString());
      });
    }
}
