<div class="container-inner interacoes" *ngIf="interacoes != undefined && interacoes != null">
    <h2>Interações</h2>
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Jogo completo">

            <div class="container-interacoes content">
                <ul class="menu-selects" *ngIf="!useful.isMobile">
                    <li>
                        <label for="">Fundamentos</label>
                        <select name="fundamentosInteracoes" id="fundamentosInteracoes" (change)="interacaoEscolhida($event)">
                            <option *ngFor="let item of orderedFoundations" [value]="item.id" [selected]="interacao == item.id">
                                {{ item.nome }}
                            </option>
                        </select>
                    </li>
                 </ul>

                 <mat-accordion *ngIf="useful.isMobile">
                  <mat-expansion-panel>
                      <mat-expansion-panel-header>
                          <mat-panel-title>
                              Filtro
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                      <ul class="menu-selects">
                        <li>
                            <label for="">Fundamentos</label>
                            <select name="fundamentosInteracoes" id="fundamentosInteracoes" (change)="interacaoEscolhida($event)">
                                <option *ngFor="let item of orderedFoundations" [value]="item.id" [selected]="interacao == item.id">
                                    {{ item.nome }}
                                </option>
                            </select>
                        </li>
                    </ul>
                  </mat-expansion-panel>
                </mat-accordion>


                <div class="container-overflow">
                    <div>
                        <strong>
                            <img [src]="partida.mandante.urlLogo">
                            {{  partida.mandante.nome }}
                        </strong>
                        <table>
                            <tbody>
                                <ng-container *ngFor="let item of interacoesSoma">
                                    <tr *ngIf="item.idTeam == partida.mandante.id">
                                        <th>{{ useful.isMobile ? useful.abbreviateName(item.jogador) : item.jogador }}</th>
                                        <ng-container *ngFor="let subitem of item.interacoes">
                                            <td  [style.background]="'rgba(251,202,31,'+ backgroundDecimals(subitem.quantidade) +')'" *ngIf="subitem.idTeam != partida.mandante.id">
                                                {{ subitem.quantidade == 0 ? ' ' : subitem.quantidade }}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tfoot>
                                <tr >
                                    <td></td>
                                    <ng-container *ngFor="let item of interacoesSoma">
                                        <td *ngIf="item.idTeam == partida.mandante.id"><span>{{ useful.isMobile ? useful.abbreviateName(item.jogador) : item.jogador }}</span></td>
                                    </ng-container>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div>
                        <strong>
                            <img [src]="partida.visitante.urlLogo">
                            {{ partida.visitante.nome }}
                        </strong>
                        <table>
                            <tbody>
                                <ng-container *ngFor="let item of interacoesSoma">
                                    <tr *ngIf="item.idTeam == partida.visitante.id">
                                        <th>{{ useful.isMobile ? useful.abbreviateName(item.jogador) : item.jogador }}</th>
                                        <ng-container *ngFor="let subitem of item.interacoes">
                                            <td  [style.background]="'rgba(251,202,31,'+ backgroundDecimals(subitem.quantidade) +')'" *ngIf="subitem.idTeam != partida.visitante.id">
                                                {{ subitem.quantidade == 0 ? ' ' : subitem.quantidade }}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tfoot>
                                <tr >
                                    <td></td>
                                    <ng-container *ngFor="let item of interacoesSoma">
                                        <td *ngIf="item.idTeam == partida.visitante.id"><span>{{ useful.isMobile ? useful.abbreviateName(item.jogador) : item.jogador }}</span></td>
                                    </ng-container>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                </div>
            </div>

        </mat-tab>

        <mat-tab label="1º tempo">

            <div class="container-interacoes content">
                <ul class="menu-selects">
                    <li>
                        <label for="">Fundamentos</label>
                        <select name="fundamentosInteracoes" id="fundamentosInteracoes" (change)="useful.getInteracao(idCampeonato, idPartida, $event.target.value)">
                            <option *ngFor="let item of orderedFoundations" [value]="item.idSkill">
                                {{ item.nome }}
                            </option>
                        </select>
                    </li>
                 </ul>

                <div>
                    <div>
                        <strong>
                            <img [src]="partida.mandante.urlLogo">
                            {{ partida.mandante.nome }}
                        </strong>
                        <table>
                            <tbody>
                                <ng-container *ngFor="let item of interacoes">
                                    <tr *ngIf="item.idTeam == partida.mandante.id">
                                        <th>{{ item.jogador }}</th>
                                        <ng-container *ngFor="let subitem of item.interacoes">
                                            <td  [style.background]="'rgba(251,202,31,'+ backgroundDecimals(subitem.quantidade) +')'" *ngIf="subitem.idTeam != partida.mandante.id && subitem.idTime == 2">
                                                {{ subitem.quantidade == 0 ? ' ' : subitem.quantidade }}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tfoot>
                                <tr >
                                    <td></td>
                                    <ng-container *ngFor="let item of interacoes">
                                        <td *ngIf="item.idTeam == partida.mandante.id"><span>{{ item.jogador }}</span></td>
                                    </ng-container>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div>
                        <strong>
                            <img [src]="partida.visitante.urlLogo">
                            {{ partida.visitante.nome }}
                        </strong>
                        <table>
                            <tbody>
                                <ng-container *ngFor="let item of interacoes">
                                    <tr *ngIf="item.idTeam == partida.visitante.id">
                                        <th>{{ item.jogador }}</th>
                                        <ng-container *ngFor="let subitem of item.interacoes">
                                            <td  [style.background]="'rgba(251,202,31,'+ backgroundDecimals(subitem.quantidade) +')'" *ngIf="subitem.idTeam != partida.visitante.id && subitem.idTime == 2">
                                                {{ subitem.quantidade == 0 ? ' ' : subitem.quantidade }}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tfoot>
                                <tr >
                                    <td></td>
                                    <ng-container *ngFor="let item of interacoesSoma">
                                        <td *ngIf="item.idTeam == partida.visitante.id"><span>{{ item.jogador }}</span></td>
                                    </ng-container>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                </div>
            </div>

        </mat-tab>

        <mat-tab label="2º tempo">

            <div class="container-interacoes content">
                <ul class="menu-selects">
                    <li>
                        <label for="">Fundamentos</label>
                        <select name="fundamentosInteracoes" id="fundamentosInteracoes" (change)="useful.getInteracao(idCampeonato, idPartida, $event.target.value)">
                            <option *ngFor="let item of orderedFoundations" [value]="item.id">
                                {{ item.nome }}
                            </option>
                        </select>
                    </li>
                 </ul>

                <div>
                    <div>
                        <strong>
                            <img [src]="partida.mandante.urlLogo">
                            {{ partida.mandante.nome }}
                        </strong>
                        <table>
                            <tbody>
                                <ng-container *ngFor="let item of interacoes">
                                    <tr *ngIf="item.idTeam == partida.mandante.id">
                                        <th>{{ item.jogador }}</th>
                                        <ng-container *ngFor="let subitem of item.interacoes">
                                            <td  [style.background]="'rgba(251,202,31,'+ backgroundDecimals(subitem.quantidade) +')'" *ngIf="subitem.idTeam != partida.mandante.id && subitem.idTime == 4">
                                                {{ subitem.quantidade == 0 ? ' ' : subitem.quantidade }}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tfoot>
                                <tr >
                                    <td></td>
                                    <ng-container *ngFor="let item of interacoes">
                                        <td *ngIf="item.idTeam == partida.mandante.id"><span>{{ item.jogador }}</span></td>
                                    </ng-container>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div>
                        <strong>
                            <img [src]="partida.visitante.urlLogo">
                            {{ partida.visitante.nome }}
                        </strong>
                        <table>
                            <tbody>
                                <ng-container *ngFor="let item of interacoes">
                                    <tr *ngIf="item.idTeam == partida.visitante.id">
                                        <th>{{ item.jogador }}</th>
                                        <ng-container *ngFor="let subitem of item.interacoes">
                                            <td  [style.background]="'rgba(251,202,31,'+ backgroundDecimals(subitem.quantidade) +')'" *ngIf="subitem.idTeam != partida.visitante.id && subitem.idTime == 4">
                                                {{ subitem.quantidade == 0 ? ' ' : subitem.quantidade }}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tfoot>
                                <tr >
                                    <td></td>
                                    <ng-container *ngFor="let item of interacoesSoma">
                                        <td *ngIf="item.idTeam == partida.visitante.id"><span>{{ item.jogador }}</span></td>
                                    </ng-container>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                </div>
            </div>

        </mat-tab>
    </mat-tab-group>
</div>
