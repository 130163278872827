<section *ngIf="ranking != undefined">
    <ul class="menu-selects">
        <li>
            <label for="fundamentos">Fundamentos</label>
            <select name="fundamentos" id="fundamentos" (change)="getFundamento($event)">
                <option *ngFor="let item of fundamentos" [value]="item.id" [selected]="item.id == 2">
                    {{ item.nome }}
                </option>
            </select>
        </li>
        <li>
            <label for="time">Time</label>
            <select name="time" id="time" (change)="getFiltroTime($event.target.value)">
                <option value="todos">Todos</option>
                <option *ngFor="let item of filterThis(ranking, 'nomeTime')" [value]="item.idTeam" [selected]="idTime == item.idTeam">{{ item.nomeTime }}</option>
            </select>
        </li>
        <li>
            <label for="filtro">Filtrar por</label>
            <select name="filtro" id="filtro" (change)="getFiltro($event)">
                <option value="jogos">Jogos</option>
                <option value="pros_minuto">Prós por minuto</option>
            </select>
        </li>
    </ul>
    <div class="container-inner">
        <table mat-table matSort [dataSource]="dataSource" >
            <!-- Coluna jogador -->
            <ng-container matColumnDef="nomeJogador">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('nomeJogador', $event)">
                    Jogador
                </th>
                <td mat-cell *matCellDef="let item; let i = index;">
                    <!-- {{ i+1 }}º -->
                    <img [src]="item.srcLogo">
                    {{ item.nomeJogador }}
                </td>
            </ng-container>

            <!-- Coluna jogos -->
            <ng-container matColumnDef="jogos">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('jogos', $event)">
                    Jogos
                </th>
                <td mat-cell *matCellDef="let item">
                    {{ item.jogos }}
                </td>
            </ng-container>

            <!-- Coluna Certos -->
            <ng-container matColumnDef="passeCerto">
                <ng-container *ngIf="filtro != 'pros_minuto'; else elseBlockCertos">
                    <th *matHeaderCellDef mat-sort-header (click)="sortThis('detalhes', $event, fundamento != 32 ? 'total' : 'totalFloat', 0)">
                        {{ columnsName.certos }}
                    </th>
                    <td mat-cell *matCellDef="let item">
                        <small class="label label-green">{{ item.id != 32 ? item.detalhes[0].total : item.detalhes[0].totalFloat }}</small>
                    </td>
                </ng-container>
                <ng-template #elseBlockCertos>
                    <th *matHeaderCellDef mat-sort-header (click)="sortThis('detalhes', $event, 'media', 0)">
                        {{ columnsName.certos }}
                    </th>
                    <td mat-cell *matCellDef="let item">
                        <small class="label label-green">{{  item.detalhes[0].media > 0 ? prosMinuto(item.detalhes[0].media) : '0' }}</small>
                    </td>
                </ng-template>
            </ng-container>

            <!-- Coluna tempojogado -->
            <ng-container matColumnDef="minutosJogados">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('segundosJogados', $event)">
                    Tempo jogado
                </th>
                <td mat-cell *matCellDef="let item">
                    {{ maskTempo(item.segundosJogados) }}
                </td>
            </ng-container>

             <!-- Coluna Certos média -->
             <ng-container matColumnDef="passeCertoMedia">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('detalhes', $event, 'media', 0)">
                    Média
                </th>
                <td mat-cell *matCellDef="let item">
                    {{ useful.makeMeInin2decimals(item.detalhes[0].media) }}
                </td>
            </ng-container>

            <!-- Coluna Certos porcentagem -->
            <ng-container matColumnDef="passeCertoPorc">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('detalhes', $event, 'percentual', 0)">
                    %
                </th>
                <td mat-cell *matCellDef="let item">
                    {{ item.detalhes[0].percentual }}
                </td>
            </ng-container>

            <!-- Coluna Errados -->
            <ng-container matColumnDef="passeErrado">
                <ng-container *ngIf="filtro != 'pros_minuto'; else elseBlockErrado">
                    <th *matHeaderCellDef mat-sort-header (click)="sortThis('detalhes', $event, 'total', 1)">
                        {{ columnsName.errados }}
                    </th>
                    <td mat-cell *matCellDef="let item">
                        <small class="label label-red">{{ item.detalhes[1] != undefined ? item.detalhes[1].total : '' }}</small>
                    </td>
                </ng-container>
                <ng-template #elseBlockErrado>
                    <th *matHeaderCellDef mat-sort-header (click)="sortThis('detalhes', $event, 'media', 1)">
                        {{ columnsName.errados }}
                    </th>
                    <td mat-cell *matCellDef="let item">
                        <small class="label label-red">{{ item.detalhes[1] != undefined && item.detalhes[1].media > 0 ? prosMinuto(item.detalhes[1].media) : '0' }}</small>
                    </td>
                </ng-template>
            </ng-container>

             <!-- Coluna Errados média -->
             <ng-container matColumnDef="passeErradoMedia">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('detalhes', $event, 'media', 1)">
                    Média
                </th>
                <td mat-cell *matCellDef="let item">
                    {{ item.detalhes[1] != undefined ? item.detalhes[1].media : '-'}}
                </td>
            </ng-container>

            <!-- Coluna Errados porcentagem -->
            <ng-container matColumnDef="passeErradoPorc">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('detalhes', $event, 'percentual', 1)">
                    %
                </th>
                <td mat-cell *matCellDef="let item">
                    {{ item.detalhes[1] != undefined ? item.detalhes[1].percentual : '-' }}
                </td>
            </ng-container>

            <!-- Coluna Media total -->
            <ng-container matColumnDef="total">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('total', $event)">
                    Totais
                </th>
                <td mat-cell *matCellDef="let item">
                    {{ item.total != null ? item.total : '-' }}
                </td>
            </ng-container>

            <!-- Coluna Media porcentagem -->
            <ng-container matColumnDef="totalMedia">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('totalMedia', $event)">
                    Média
                </th>
                <td mat-cell *matCellDef="let item">
                    {{ item.totalMedia != null ? item.totalMedia : '-' }}
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</section>