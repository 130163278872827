<div class="container-inner container-estatistica estatistica" *ngIf="show">
    <h2>
        Estatística 1 x 2
        <div class="placarPorcentagem">
            <strong class="color-mandante">{{ partida['mandante'].nome }} {{ lastPrediction['mandante'] }}%</strong>
            <strong class="color-empate">Empate {{ lastPrediction['empate'] }}%</strong>
            <strong class="color-visitante">{{ partida['visitante'].nome }} {{ lastPrediction['visitante'] }}%</strong>
        </div>
        <a href="javascript:void(0);" title="Ajuda com o 1x2" class="icon-help" id="icon-help-1x2" (click)="modalHelp1x2(true)">&#63;</a>
    </h2>
    <app-graph-column-stacked [nameMandante]="this.partida['mandante'].nome" [nameVisitante]="this.partida['visitante'].nome" [data]="predictions" idChart="prediction1x2" *ngIf="predictions != undefined"></app-graph-column-stacked>

    <div id="video1x2" *ngIf="showVideo1x2">
      <iframe  width="560" height="315" src="https://www.youtube.com/embed/mgxFjrE6Ry4" title="Vídeo do YouTube sobre o 1x2 da footstats" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <span class="bt-close" (click)="modalHelp1x2(false)"></span>
    </div>
</div>

