import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsefulService } from '../../../shared/useful/useful.service';

@Component({
  selector: 'app-estatistica1x2',
  templateUrl: './estatistica1x2.component.html',
  styleUrls: ['./estatistica1x2.component.scss']
})
export class Estatistica1x2Component implements OnInit {
    @Input() partida: number;
    @Input() idCampeonato: Number;
    @Input() idPartida: Number;
    @Input() atualizacao: any;

    predictions: any = {};
    lastPrediction: any = {};
    show: Boolean = true;
    showVideo1x2: Boolean = false;

    interval: any;
    subscription: Subscription;

    constructor(
      public useful : UsefulService
    ) { }

    ngOnInit() {
      this.useful.getPredicaoTimeline(this.idCampeonato, this.idPartida);

      if (this.atualizacao != undefined) {
        this.interval = setInterval(() => {
          this.useful.getPredicaoTimeline(this.idCampeonato, this.idPartida);
        }, this.atualizacao);
      }

      this.subscription = this.useful.dataObservablePredicaoTimeline.subscribe((res:any) => {
        this.predictions = res;
        if (this.predictions != undefined) {
          this.show = true;
        } else {
          this.show = false;
        }
        this.calculatePercentage();
        console.log('predictions', this.predictions)
      });
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscription.unsubscribe();
    }

    calculatePercentage() {
      let temp = [];
      if (this.predictions != undefined) {
        this.predictions.filter(e => {
          if(e.gametime == 0 || e.gametime == 2 || e.gametime == 4) {
            return e.predictions.map(v => {
                temp.push(v);
              });
          }
        })

        Object.assign(this.lastPrediction, {
          mandante: this.useful.makeMeInin2decimals(temp[temp.length-1].homeTeam*100),
          empate:this.useful.makeMeInin2decimals(temp[temp.length-1].draw*100),
          visitante:this.useful.makeMeInin2decimals(temp[temp.length-1].awayTeam*100)
        });
      }
    }

    modalHelp1x2(option) {
      this.showVideo1x2 = option;
    }
}
