<div id="menu" [class]="useful.isMobile ? 'i-am-mobile' : ''">
    <div id="main-menu-mobile" *ngIf="useful.isMobile">

        <a href="javascript:void(0);" id="open-menu" title="Abrir menu" (click)="openMenuMobile()">
            <span></span>
            <span></span>
            <span></span>
        </a>
        <a title="Voltar a página inicial" [routerLink]="['/']" id="logo">
            <img src="assets/images/svg/logo-i2a.svg" alt="I2A">
        </a>
        <div class="divsBrasil-mobile" *ngIf="useful.partidaBrasil == 207435 && useful.isMobile" >
          <div id="primeiro"></div>
          <div id="segundo"></div>
        </div>

    </div>
    <div id="main-menu">

        <a title="Voltar a página inicial" [routerLink]="['/']" id="logo" *ngIf="!useful.isMobile" (click)="clickValueBrasil()">
            <img src="assets/images/svg/logo-i2a.svg" alt="I2A" >

        </a>
        <ul id="menu-itens">
            <li *ngFor="let item of menuItens">
                <a [routerLink]="item.url" (click)="item.submenu !== null ? openMainSubmenu($event) : false">
                    <i [class]="item.icon"></i>
                    {{ item.name }}
                    <i class="icon-chevron-down" *ngIf="item.submenu !== null"></i>
                </a>

                <ul *ngIf="item.submenu !== null && item.name == 'Partidas'" class="submenu">
                    <li *ngFor="let sublink of item.submenu.categorias">
                        <a href="javascript:void(0);" (click)="openMenuCampeonatos($event)">
                            {{ sublink.categoria }}
                        </a>

                        <div class="menuSide">
                            <ul>
                                <li *ngFor="let item of sublink.campeonatos" (click)="campeonatoSelecionado(item); closeMenuCampeonatos($event)">
                                    <span>
                                        <img [src]="item.urlLogo" [alt]="item.nome">
                                    </span>
                                    {{ item.nome }}
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>

                <ul *ngIf="item.submenu !== null && item.name != 'Partidas'" class="submenu">
                    <ng-container *ngFor="let sublink of item.submenu">
                        <li *ngIf="sublink.url != null">
                            <a [routerLink]="sublink.url">
                                {{ sublink.name }}
                            </a>
                        </li>
                        <li *ngIf="sublink.submenu !== null && item.name == 'Ranking'">
                            <a href="javascript:void(0);" (click)="item.submenu !== null ? openMainSubmenu($event) : false">
                                {{ sublink.name }}
                            </a>

                            <ul>
                                <li *ngFor="let subitem of sublink.submenu.categorias">
                                    <a href="javascript:void(0);" (click)="openMenuCampeonatos($event)">
                                        {{ subitem.categoria }}
                                    </a>

                                    <div class="menuSide" *ngIf="subitem.submenu !== null && sublink.name == 'Equipes'">
                                        <ul>
                                            <li *ngFor="let item of subitem.campeonatos" (click)="rankingSelecionado(item, 'equipes'); closeMenuCampeonatos($event)">
                                                <span>
                                                    <img [src]="item.urlLogo" [alt]="item.nome">
                                                </span>
                                                {{ item.nome }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="menuSide" *ngIf="subitem.submenu !== null && sublink.name == 'Jogadores'">
                                        <ul>
                                            <li *ngFor="let item of subitem.campeonatos" (click)="rankingSelecionado(item, 'jogadores'); closeMenuCampeonatos($event)">
                                                <span>
                                                    <img [src]="item.urlLogo" [alt]="item.nome">
                                                </span>
                                                {{ item.nome }}
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ng-container>
                </ul>
            </li>
        </ul>

    </div>
    <div class="divsBrasil" *ngIf="useful.partidaBrasil == 207435" >
      <div id="primeiro"></div>
      <div id="segundo"></div>
    </div>
    <div class="semJogoEspecial" *ngIf="useful.partidaBrasil == 0">
    </div>
</div>
