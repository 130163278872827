<section *ngIf="ranking != undefined">
    <ul class="menu-selects">
        <li>
            <label for="fundamentos">Fundamentos</label>
            <select name="fundamentos" id="fundamentos" (change)="getFundamento($event)">
                <ng-container *ngFor="let item of ranking.contra">
                    <option *ngIf="item.id != 28 && item.id != 23 && item.id != 26 && item.id != 7" [value]="item.id" [selected]="item.id == 2">
                        {{ item.id != 25 && item.id != 6 ? item.nome : '' }}
                        {{ item.id == 25 ? "Cartões" : '' }}
                        {{ item.id == 6 ? "Faltas" : '' }}
                    </option>
                </ng-container>
            </select>
        </li>
        <li>
            <label for="filtro">Filtrar por</label>
            <select name="filtro" id="filtro" (change)="getFiltro($event)">
                <option value="pros">Prós</option>
                <option value="pros_minuto">Prós por minuto</option>
                <option value="contra">Contra</option>
            </select>
        </li>
    </ul>
    <div class="container-inner">
        <table mat-table matSort [dataSource]="dataSource" >
            <!-- Coluna times -->
            <ng-container matColumnDef="nomeEquipe">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('nomeEquipe', $event)">
                    Times
                </th>
                <td mat-cell *matCellDef="let item">
                    <img [src]="item.srcLogo">
                    {{ item.nomeEquipe }}
                </td>
            </ng-container>

            <!-- Coluna jogos -->
            <ng-container matColumnDef="qtdJogos">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('qtdJogos', $event)">
                    Jogos
                </th>
                <td mat-cell *matCellDef="let item">
                    {{ item.qtdJogos }}
                </td>
            </ng-container>

            <!-- Coluna Certos -->
            <ng-container matColumnDef="certos.total">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('certos', $event, 'total')">
                    {{ nameColumns.certo }}
                </th>
                <td mat-cell *matCellDef="let item">
                        <ng-container *ngIf="filtro != 'pros_minuto'; else elseBlock">
                          <ng-container *ngIf="fundamentoId == 32">
                            {{ item.certos != null ? item.certos.totalFloat : '-' }}
                        </ng-container>
                        <ng-container *ngIf="fundamentoId == 27 || fundamentoId == 15 || fundamentoId == 25 || fundamentoId == 19 || fundamentoId == 20 || fundamentoId == 6 || fundamentoId == 9; else elseInfSec" >
                            {{ item.certos != null ? item.certos.total : '-' }}
                        </ng-container>
                        <ng-template #elseInfSec>
                            <small class="label label-green" *ngIf="fundamentoId != 32">
                                {{ item.certos != null ? item.certos.total : '-' }}
                            </small>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseBlock>
                        <ng-container *ngIf="fundamentoId == 27 || fundamentoId == 15 || fundamentoId == 25 || fundamentoId == 19 || fundamentoId == 20 || fundamentoId == 6 || fundamentoId == 9; else elseInfSec" >
                            {{ item.certos != null ? prosMinuto(item.certos.media) : '-' }}
                        </ng-container>
                        <ng-template #elseInfSec>
                            <small class="label label-green">{{ item.certos != null ? prosMinuto(item.certos.media) : '-' }}</small>
                        </ng-template>
                    </ng-template>
                </td>
            </ng-container>

             <!-- Coluna Certos média -->
             <ng-container matColumnDef="certos.media">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('certos', $event, 'media')">
                    Média
                </th>
                <td mat-cell *matCellDef="let item">
                    <ng-container *ngIf="fundamentoId == 27 || fundamentoId == 15 || fundamentoId == 25 || fundamentoId == 20 || fundamentoId == 19 || fundamentoId == 6 || fundamentoId == 9; else elseInfSec" >
                        {{ item.certos != null ? useful.makeMeInin2decimals(item.certos.media) : '-' }}
                    </ng-container>
                    <ng-template #elseInfSec>
                        <span class="text-green">{{ item.certos != null ? useful.makeMeInin2decimals(item.certos.media) : '-' }}</span>
                    </ng-template>
                </td>
            </ng-container>

            <!-- Coluna Certos porcentagem -->
            <ng-container matColumnDef="certos.porcentagem">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('certos', $event, 'porcentagem')">
                    %
                </th>
                <td mat-cell *matCellDef="let item">
                    <ng-container *ngIf="fundamentoId == 27 || fundamentoId == 15 || fundamentoId == 25 || fundamentoId == 6 || fundamentoId == 9; else elseInfSec" >
                        {{ item.certos != null ? useful.makeMeInin2decimals(item.certos.porcentagem) : '-' }}
                    </ng-container>
                    <ng-template #elseInfSec>
                        {{ item.certos != null ? useful.makeMeInin2decimals(item.certos.porcentagem) : '-' }}
                    </ng-template>
                </td>
            </ng-container>

            <!-- Coluna Errados -->
            <ng-container matColumnDef="errados.total">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('errados', $event, 'total')">
                    {{ nameColumns.errado }}
                </th>
                <td mat-cell *matCellDef="let item">
                    <ng-container *ngIf="filtro != 'pros_minuto'; else elseBlock">
                        <ng-container *ngIf="fundamentoId == 27 || fundamentoId == 15 || fundamentoId == 25 || fundamentoId == 6; else elseInfSec" >
                            {{ item.infSec.certos != null ? item.infSec.certos.total : '-' }}
                        </ng-container>
                        <ng-template #elseInfSec>
                            <small class="label label-red">
                                 {{ item.errados != null ? item.errados.total : '-' }}
                            </small>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseBlock>
                        <ng-container *ngIf="fundamentoId == 27 || fundamentoId == 15 || fundamentoId == 25 || fundamentoId == 6; else elseInfSec" >
                                {{ item.infSec.certos != null ? prosMinuto(item.infSec.certos.media) : '-' }}
                        </ng-container>
                        <ng-template #elseInfSec>
                            <small class="label label-red">
                                {{ item.errados != null ? prosMinuto(item.errados.media) : '-' }}
                            </small>
                        </ng-template>
                    </ng-template>
                </td>
            </ng-container>

             <!-- Coluna Errados média -->
             <ng-container matColumnDef="errados.media">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('errados', $event, 'media')">
                    Média
                </th>
                <td mat-cell *matCellDef="let item">
                    <ng-container *ngIf="fundamentoId == 27 || fundamentoId == 15 || fundamentoId == 25 || fundamentoId == 6; else elseInfSec">
                        {{ item.infSec.certos != null ? useful.makeMeInin2decimals(item.infSec.certos.media) : '-' }}
                    </ng-container>
                    <ng-template #elseInfSec>
                        <small class="label label-red">
                            {{ item.errados != null ? useful.makeMeInin2decimals(item.errados.media) : '-' }}
                        </small>
                    </ng-template>
                </td>
            </ng-container>

            <!-- Coluna Errados porcentagem -->
            <ng-container matColumnDef="errados.porcentagem">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('errados', $event, 'porcentagem')">
                    %
                </th>
                <td mat-cell *matCellDef="let item">
                    <ng-container *ngIf="fundamentoId == 27 || fundamentoId == 15 || fundamentoId == 25 || fundamentoId == 6; else elseInfSec">
                        {{ item.infSec.certos != null ? useful.makeMeInin2decimals(item.infSec.certos.porcentagem) : '-' }}
                    </ng-container>
                    <ng-template #elseInfSec>
                        <small class="label label-red">
                            {{ item.errados != null ? useful.makeMeInin2decimals(item.errados.porcentagem) : '-' }}
                        </small>
                    </ng-template>
                </td>
            </ng-container>

            <!-- Coluna Media total -->
            <ng-container matColumnDef="totais.total">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('totais', $event, 'total')">
                    Totais
                </th>
                <td mat-cell *matCellDef="let item">
                    {{ item.totais != null ? item.totais.total : '-' }}
                </td>
            </ng-container>

            <!-- Coluna Media porcentagem -->
            <ng-container matColumnDef="totais.porcentagem">
                <th *matHeaderCellDef mat-sort-header (click)="sortThis('totais', $event, 'totalMedia')">
                    Média
                </th>
                <td mat-cell *matCellDef="let item">
                    {{ item.totais != undefined ? useful.makeMeInin2decimals(item.totais.totalMedia) : '-' }}
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</section>
