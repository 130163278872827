import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsefulService } from '../../../shared/useful/useful.service';

@Component({
  selector: 'app-tempo',
  templateUrl: './tempo.component.html',
  styleUrls: ['./tempo.component.scss']
})
export class TempoComponent implements OnInit {
    @Input() partida: any;
    @Input() idPartida: Number;
    @Input() atualizacao: any;

    orderedFoundations = [
      { id: 2, nome: 'Passes', detail: true, maxYTotal: 150, maxYPlayer: 25},
      { id: 3, nome: 'Finalizações', detail: true, maxYTotal: 10, maxYPlayer: 4},
      { id: 5, nome: 'Desarmes', detail: true, maxYTotal: 10, maxYPlayer: 5},
      { id: 14, nome: 'Cruzamentos', detail: true, maxYTotal: 20, maxYPlayer: 10},
      { id: 1, nome: 'Lançamentos', detail: true, maxYTotal: 15, maxYPlayer: 5},
      { id: 24, nome: 'Interceptações', detail: true, maxYTotal: 10, maxYPlayer: 5},
      { id: 4, nome: 'Dribles', detail: true, maxYTotal: 10, maxYPlayer: 5},
      { id: 22, nome: 'Viradas de jogo', detail: true, maxYTotal: 10, maxYPlayer: 5},
      { id: 8, nome: 'Gols', detail: false, maxYTotal: 5, maxYPlayer: 3},
      { id: 20, nome: 'Posse de bola', detail: false, maxYTotal: 100, maxYPlayer: 0},
      { id: 16, nome: 'Escanteios', detail: false, maxYTotal: 10, maxYPlayer: 0},
      { id: 28, nome: 'Assistências para finalização', detail: false, maxYTotal: 10, maxYPlayer: 5},
      { id: 27, nome: 'Assistências', detail: false, maxYTotal: 10, maxYPlayer: 5},
      { id: 15, nome: 'Defesas', detail: false, maxYTotal: 10, maxYPlayer: 5},
      { id: 23, nome: 'Defesas difíceis', detail: false, maxYTotal: 10, maxYPlayer: 5},
      { id: 19, nome: 'Rebatidas', detail: false, maxYTotal: 20, maxYPlayer: 10},
      { id: 6, nome: 'Faltas cometidas', detail: false, maxYTotal: 10, maxYPlayer: 5},
      { id: 9, nome: 'Impedimentos', detail: false, maxYTotal: 10, maxYPlayer: 5},
      { id: 11, nome: 'Pênaltis', detail: false, maxYTotal: 5, maxYPlayer: 2},
      { id: 12, nome: 'Perdas de posse de bola', detail: false, maxYTotal: 15, maxYPlayer: 7},
      { id: 25, nome: 'Cartões amarelos', detail: false, maxYTotal: 10, maxYPlayer: 2},
      { id: 26, nome: 'Cartões vermelhos', detail: false, maxYTotal: 10, maxYPlayer: 2},
    ];

    interval: any;
    interval1: any;
    subscription: Subscription;
    subscription1: Subscription;
    intervaloData: any;
    fundamentoData: any;
    idChartTimeHome: string;
    idChartTimeAway: string;
    fundTime: any = null;
    playerSelectedMandante: boolean = false;
    playerSelectedVisitante: boolean = false;
    playerValueMandante: any = 'todos';
    playerValueVisitante: any = 'todos';

    /**Necessário guardar a referência dos gráficos para rerender */
    graphTempoMandante: any = null;
    graphTempoVisitante: any = null;

    /**Seletores para intervalos */
    jogadoresTempoMandante: number[] = [];
    jogadoresTempoVisitante: number[] = [];

    /**Necessário para gravar o estado das seleções */
    stateIntervalo: {fundamento: number|string, jogadorVisitante: string|number, jogadorMandante: string|number};

    fundamentoEscolhido: any = 2;

    constructor(
      public useful : UsefulService
    ) { }

    ngOnInit() {
      this.useful.getIntervalo(this.partida.id, this.fundamentoEscolhido);
      if (this.atualizacao != undefined) {
        this.interval = setInterval(() => {
          this.useful.getIntervalo(this.partida.id, this.fundamentoEscolhido);
        }, this.atualizacao);
      }
      this.subscription = this.useful.dataObservableIntervalo.subscribe((res:any) => {
        this.intervaloData = res;

        this.generateTimeGraphs();
        this.setJogadoresTempo();
        // console.log('Tempo', this.intervaloData)

      });

      this.useful.getFundamento(this.idPartida);
      console.log('atualização', this.atualizacao)

      if (this.atualizacao != undefined) {
        this.interval1 = setInterval(() => {
          this.useful.getFundamento(this.idPartida);
        }, this.atualizacao);
      }

      this.subscription1 = this.useful.dataObservableFundamento.subscribe((res:any) => {
        res.shift();
        this.fundamentoData = res;

        this.stateIntervalo = {fundamento: this.fundamentoEscolhido, jogadorMandante: 'todos', jogadorVisitante: 'todos'};
      });
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      clearInterval(this.interval1);
      this.subscription.unsubscribe();
      this.subscription1.unsubscribe();
    }

    setJogadoresTempo() {
      if (this.fundTime == null) {
        this.getFundamentoTime(this.fundamentoEscolhido);
      }
      this.jogadoresTempoMandante = this.intervaloData
        .filter(intervalo => intervalo.idTeam == this.partida.mandante.id)
        .reduce((unique: any[], item) => unique.find(u => u.idPlayer === item.idPlayer) ? unique : [...unique, item], []);

      this.jogadoresTempoVisitante = this.intervaloData
        .filter(intervalo => intervalo.idTeam == this.partida.visitante.id)
        .reduce((unique: any[], item) => unique.find(u => u.idPlayer === item.idPlayer) ? unique : [...unique, item], []);
      }

    generateTimeGraphs() {
      // Salvo para não ser acessado o tempo todo
      const mandante = this.partida.mandante.id;
      const visitante = this.partida.visitante.id;

      const dataMandante = this.calculateTimeData(mandante, this.playerValueMandante);
      const dataVisitante = this.calculateTimeData(visitante, this.playerValueVisitante);

      this.idChartTimeHome = "idChartTimeHome";
      this.idChartTimeAway = "idChartTimeAway";

      this.graphTempoMandante = dataMandante;
      this.graphTempoVisitante = dataVisitante;
    }

    calculateTimeData(time: number|string, player: number|string) {

      const timespans = [15, 30, 45, 60, 75, 90];

      const intervalosPorTimeETempo = timespans.map(timespan => this.intervaloData.filter(intervalo => intervalo.timePlayIn15minutes === timespan && intervalo.idTeam === time));

      const intervalosCorretos = intervalosPorTimeETempo.map(tempo => tempo.filter(p => p.isCorrect === 1));
      const intervalosIncorretos = intervalosPorTimeETempo.map(tempo => tempo.filter(p => p.isCorrect === 0));

      const correto = player === 'todos' // Subistituir para verificar o valor do dropdown
        ? intervalosCorretos.map(tempo => tempo.reduce((total, val) => val.total + total, 0))
        : intervalosCorretos.map(tempo => tempo.filter(jogador => jogador.idPlayer == player).reduce((total, val) => val.total + total, 0));

      const incorreto = player === 'todos' // Subistituir para verificar o valor do dropdown
        ? intervalosIncorretos.map(tempo => tempo.reduce((total, val) => val.total + total, 0))
        : intervalosIncorretos.map(tempo => tempo.filter(jogador => jogador.idPlayer == player).reduce((total, val) => val.total + total, 0));

        return {correto,incorreto}
    }

    updateTempoGraph(options:{selectFundamento?: number|string, jogadorVisitante?: string|number, jogadorMandante?: string|number}) {
      let {selectFundamento: fundamento, jogadorVisitante, jogadorMandante} = options;

      this.getFundamentoTime(fundamento);

      if (jogadorMandante != undefined && jogadorMandante != 'todos') {
        this.playerSelectedMandante = true;
        this.playerValueMandante = jogadorMandante;
      } else if (jogadorMandante != undefined) {
        this.playerSelectedMandante = false;
        this.playerValueMandante = 'todos';
      }

      if (jogadorVisitante != undefined && jogadorVisitante != 'todos') {
        this.playerSelectedVisitante = true;
        this.playerValueVisitante = jogadorVisitante;
      } else if (jogadorVisitante != undefined) {
        this.playerSelectedVisitante = false;
        this.playerValueVisitante = 'todos';
      }

      if(this.playerValueMandante) {
        jogadorMandante = this.playerValueMandante;
        this.stateIntervalo = {...this.stateIntervalo, jogadorMandante};
      }
      if(this.playerValueVisitante){
        jogadorVisitante = this.playerValueVisitante;
        this.stateIntervalo = {...this.stateIntervalo, jogadorVisitante}
      }
      if(fundamento) {
        //Reinicializar o estado dos jogadores para um novo fundamento
        //O jogador por de não estar presente naquele fundamento
        this.stateIntervalo = {...this.stateIntervalo, fundamento, jogadorMandante: 'todos' ,jogadorVisitante: 'todos'}
        this.playerSelectedMandante = false;
        this.playerSelectedVisitante = false;
        this.fundamentoEscolhido = fundamento;
        this.playerValueMandante = 'todos';
        this.playerValueVisitante = 'todos';

        this.useful.getIntervalo(this.partida.id, fundamento);
      } else {
        this.updateTempoGraphMandante();
        this.updateTempoGraphVisitante();
      }

    }

    updateTempoGraphFundamento() {
      this.intervaloData = this.useful.getInLocalStore('intervalo');
      this.setJogadoresTempo();
      this.updateTempoGraphMandante();
      this.updateTempoGraphVisitante();
    }

    updateTempoGraphMandante() {
      const dataMandante = this.calculateTimeData(this.partida.mandante.id, this.stateIntervalo.jogadorMandante);
      const dataVisitante = this.calculateTimeData(this.partida.visitante.id, this.stateIntervalo.jogadorVisitante);

      this.graphTempoMandante = dataMandante;
      this.graphTempoVisitante = dataVisitante;

  }

  updateTempoGraphVisitante() {

    const dataMandante = this.calculateTimeData(this.partida.mandante.id, this.stateIntervalo.jogadorMandante);
    const dataVisitante = this.calculateTimeData(this.partida.visitante.id, this.stateIntervalo.jogadorVisitante);

    this.graphTempoMandante = dataMandante;
    this.graphTempoVisitante = dataVisitante;
  }

    getFundamentoTime(e) {
      const fund = e;
      for (const i of this.orderedFoundations) {
        if (i.id == fund) {
          this.fundTime = i;
          return i;
        }
      }
    }
}
