import { Component, OnInit, ViewChild } from '@angular/core';
import { UsefulService } from '../../shared/useful/useful.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-ranking-equipes',
  templateUrl: './ranking-equipes.component.html',
  styleUrls: ['./ranking-equipes.component.scss']
})
export class RankingEquipesComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  idCampeonato: any;
  ranking: any;
  fundamento: any = 'Passes';
  fundamentoId: any = 2;
  filtro: any = 'pros';
  nameColumns: any = {
    certo: "Certos",
    errado: "Errados"
  }
  displayedColumns: string[] = [
		'nomeEquipe',
    'qtdJogos',
    'certos.total',
    'certos.media',
    'certos.porcentagem',
    'errados.total',
    'errados.media',
    'errados.porcentagem',
    'totais.total',
    'totais.porcentagem'
	];

	dataSource = new MatTableDataSource<any>([]);

    constructor(
      public useful : UsefulService,
      public router: Router,
      public activatedRoute: ActivatedRoute
    ) {
      this.activatedRoute.params.subscribe( parametros => {
        this.idCampeonato = parametros['id'];
        this.useful.getEquipes(this.idCampeonato);
      });
    }

    ngOnInit() {
      this.useful.dataObservableEquipes.subscribe((res:any) => {
        console.log('- equipes: ', res);
        this.ranking = res.data;

        this.configMergeFundamentos('contra');
        this.configMergeFundamentos('pros');

        this.calcMedia('contra');
        this.calcMedia('pros');

        this.dataSource = new MatTableDataSource<any>(this.getDetalhe(this.fundamento, 'nome', this.filtro).equipes);
      });

    }

    calcMedia(filtro) {
      this.ranking[filtro].map(f => {
        f['equipes'].map(e => {
          if(e.totais != undefined && e.totais.total != null) {
            Object.assign(e.totais, {totalMedia: e.totais.total / e.qtdJogos});
          }
        })
      })
    }

    configMergeFundamentos(filtro) {
      for (const i of this.ranking[filtro]) {
        if (i.id == 28) {
          for (const j of this.ranking[filtro]) {
            if (j.id == 27) {
              for (const k of j.equipes) {
                for (const l of i.equipes) {
                  if (l.id == k.id) {
                    Object.assign(k, {infSec: l});
                  }
                }
              }
              break;
            }
          }
        }
        if (i.id == 23) {
          for (const j of this.ranking[filtro]) {
            if (j.id == 15) {
              for (const k of j.equipes) {
                for (const l of i.equipes) {
                  if (l.id == k.id) {
                    Object.assign(k, {infSec: l});
                  }
                }
              }
              break;
            }
          }
        }
        if (i.id == 26) {
          for (const j of this.ranking[filtro]) {
            if (j.id == 25) {
              for (const k of j.equipes) {
                for (const l of i.equipes) {
                  if (l.id == k.id) {
                    Object.assign(k, {infSec: l});
                  }
                }
              }
              break;
            }
          }
        }
        if (i.id == 7) {
          for (const j of this.ranking[filtro]) {
            if (j.id == 6) {
              for (const k of j.equipes) {
                for (const l of i.equipes) {
                  if (l.id == k.id) {
                    Object.assign(k, {infSec: l});
                  }
                }
              }
              break;
            }
          }
        }
      }
    }

    configColumnCertosErrados(fundId) {
      const columns = [2, 3, 5, 14, 1, 24, 11, 4, 22, 8, 16];
      let result = columns.indexOf(parseInt(fundId)) != -1;
      this.fundamentoId = fundId;

      if (!result) {
        this.displayedColumns = [
          'nomeEquipe',
          'qtdJogos',
          'certos.total',
          'certos.porcentagem'
        ];

      } else {
        this.displayedColumns = [
          'nomeEquipe',
          'qtdJogos',
          'certos.total',
          'certos.media',
          'certos.porcentagem',
          'errados.total',
          'errados.media',
          'errados.porcentagem',
          'totais.total',
          'totais.porcentagem'
        ];
      }

      if (this.fundamentoId == 15) {
        this.displayedColumns = [
          'nomeEquipe',
          'qtdJogos',
          'certos.total',
          'certos.media',
          'certos.porcentagem',
          'errados.total',
          'errados.media',
          'errados.porcentagem'
        ];
      }

      if (this.fundamentoId == 27 || this.fundamentoId == 6 || this.fundamentoId == 25) {
        this.displayedColumns = [
          'nomeEquipe',
          'qtdJogos',
          'certos.total',
          'certos.media',
          'errados.total',
          'errados.media',
        ];
      }

      if (this.fundamentoId == 9 || this.fundamentoId == 12 || this.fundamentoId == 19) {
        this.displayedColumns = [
          'nomeEquipe',
          'qtdJogos',
          'certos.total',
          'certos.media'
        ];
      }

      if (this.fundamentoId == 32) {
        this.displayedColumns = [
          'nomeEquipe',
          'qtdJogos',
          'certos.media',
          'certos.total'
        ];
      }

      if (this.fundamentoId == 20) {
        this.displayedColumns = [
          'nomeEquipe',
          'qtdJogos',
          'certos.media'
        ];
      }

      switch (this.fundamentoId) {
        case "6":
          this.nameColumns = {
            certo: "Cometidas",
            errado: "Recebidas"
          }
          break;
        case "8":
          this.nameColumns = {
            certo: "Marcados",
            errado: "Sofridos"
          }
          break;
        case "9":
          this.nameColumns = {
            certo: "Impedimentos"
          }
          break;
        case "27":
          this.nameColumns = {
            certo: "Gol",
            errado: "Finalização"
          }
          break;
        case "11":
          this.nameColumns = {
            certo: "Sofridos",
            errado: "Comeditos"
          }
          break;
        case "15":
          this.nameColumns = {
            certo: "Defesas",
            errado: "Difíceis"
          }
          break;
        case "16":
          this.nameColumns = {
            certo: "Favor",
            errado: "Cedido"
          }
          break;
        case "25":
          this.nameColumns = {
            certo: "Amarelos",
            errado: "Vermelhos"
          }
          break;
        case "32":
          this.nameColumns = {
            certo: "Índice"
          }
          break;
        default:
          this.nameColumns = {
            certo: "Certos",
            errado: "Errados"
          }
          break;
      }

      this.dataSource = new MatTableDataSource<any>(this.getDetalhe(this.fundamentoId, 'id', this.filtro).equipes);

      return result;
    }

    prosMinuto(v) {
      let result:any = v / 90;
      result = 60 / result;

      var sec_num:any = parseInt(result, 10); // don't forget the second param
      var hours:any   = Math.floor(sec_num / 3600);
      var minutes:any = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds:any = sec_num - (hours * 3600) - (minutes * 60);

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}

      return `${hours > 0 ? hours+'h' : ''}${minutes > 0 ? minutes+'m' : ''}${seconds}s`;

    }

    getFundamento(e) {
      console.log('e',e.target.value);
      this.fundamentoId = e.target.value;
      this.configColumnCertosErrados(this.fundamentoId);
    }

    getFiltro(e) {
      this.filtro = e.target.value;

      if (this.filtro == 'pros_minuto') {
        this.displayedColumns = [
          'nomeEquipe',
          'qtdJogos',
          'certos.total',
          'errados.total'
        ];
      } else {
        this.displayedColumns = [
          'nomeEquipe',
          'qtdJogos',
          'certos.total',
          'certos.media',
          'certos.porcentagem',
          'errados.total',
          'errados.media',
          'errados.porcentagem',
          'totais.total',
          'totais.porcentagem'
        ];
      }
      this.dataSource = new MatTableDataSource<any>(this.getDetalhe(this.fundamentoId, 'id', this.filtro).equipes);
    }

    getDetalhe(inf, field,  q) {
      let tempo = q == 'pros_minuto' ? 'pros' : q;
      for (const i of this.ranking[tempo]) {
        if (i[field] == inf) {
            i.equipes = i.equipes.sort(function(a,b){
              let temp1:any = a.certos.total;
              let temp2:any = b.certos.total;
              return  temp2 - temp1 ;
            });

          return i;
        }
      }
    }

    sortThis(field, e, fieldSec?) {
      this.fundamentoId == 32 && fieldSec == 'total' && (fieldSec = 'totalFloat');
      console.log('field', field);

      let tempo = this.filtro == 'pros_minuto' ? 'pros' : this.filtro;
      let arr = this.ranking[tempo].filter(e => e.id == this.fundamentoId)[0].equipes;
      let classTemp = e.target.classList;
      let dataTemp;

      if (arr[0] !== undefined && typeof arr[0][field] === 'string') {
        dataTemp = arr.sort(function(a,b){
          if (classTemp.value.indexOf("ascending") > -1) {
            return b[field].localeCompare(a[field]);
          } else {
            return a[field].localeCompare(b[field]);
          }
        });
      } else {
        // console.log('else')
        dataTemp = arr.sort(function(a,b){
          let temp1, temp2;
          if (field == 'errados' && a['infSec'] != undefined) {
            temp1 =  a['infSec']['certos'][fieldSec];
            temp2 =  b['infSec']['certos'][fieldSec];
          } else {
            temp1 = field == 'certos' || 'errados' || 'totais' ? a[field][fieldSec] : a[field];
            temp2 = field == 'certos' || 'errados' || 'totais' ? b[field][fieldSec] : b[field];
          }

          if (classTemp.value.indexOf("ascending") > -1) {
            if(temp1 < temp2) { return 1; }
            if(temp1 > temp2) { return -1; }
            return 0;
          } else {
            if(temp1 < temp2) { return -1; }
            if(temp1 > temp2) { return 1; }
            return 0;
          }
        });
      }

      e.target.classList.toggle('ascending');
      this.dataSource = new MatTableDataSource<any>(arr);

    }
}
