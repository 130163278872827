import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { MenuModule } from '../../shared/menu/menu.module';
import { ScoutsComponent } from './scouts.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatTabsModule } from '@angular/material/tabs';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { GraphBarComponent } from '../../shared/graph-bar/graph-bar.component';
import { GraphBarLinhaComponent } from '../../shared/graph-bar-linha/graph-bar-linha.component';
import { LineChartComponent } from '../../shared/line-chart/line-chart.component';
import { GraphColumnStackedComponent } from '../../shared/graph-column-stacked/graph-column-stacked.component';
import { NotificationComponent } from '../../shared/notification/notification.component';
import { MatExpansionModule } from '@angular/material/expansion';

import { OverComponent } from './over/over.component';
import { Estatistica1x2Component } from './estatistica1x2/estatistica1x2.component';
import { TempoComponent } from './tempo/tempo.component';
import { ScoutsDetailsComponent } from './scout-details/scouts-details.component';
import { EscalacaoComponent } from './escalacao/escalacao.component';
import { CampoComponent } from './campo/campo.component';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { InteracoesComponent } from './interacoes/interacoes.component';
import { IndividualComponent } from './individual/individual.component';
import { InsightsComponent } from './insights/insights.component';

@NgModule({
	declarations: [
		ScoutsComponent,
		GraphBarComponent,
		GraphBarLinhaComponent,
		LineChartComponent,
		GraphColumnStackedComponent,
		NotificationComponent,

		OverComponent,
		Estatistica1x2Component,
		InsightsComponent,
		ScoutsDetailsComponent,
		EscalacaoComponent,
		CampoComponent,
		HeatmapComponent,
		InteracoesComponent,
		IndividualComponent,
		TempoComponent
	],
	imports: [
		CommonModule,
		BrowserModule,
    MenuModule,
		LayoutModule,
		MatTabsModule,
		SlickCarouselModule,
    MatExpansionModule
	],
		exports:[ ScoutsComponent ]

	})
	export class ScoutsModule { }
