import { Component, Input, AfterViewInit, SimpleChanges, HostListener } from '@angular/core';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';

More(Highcharts);

export interface DataLineChart {
  correto: any,
  incorreto: any
}

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements AfterViewInit {
  @Input() idChart: string;
  @Input() data: DataLineChart;
  @Input() maxY: number;
  chart: any;
  options: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data != undefined) {
      if (changes.data.previousValue != undefined && changes.data.previousValue != this.data) {
        this.updateChart();
      }
    }
  }

  ngAfterViewInit(): void {
    this.initChart();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateChart();
  }

  initChart(): void {
    this.updateChartOptions();
    this.chart = Highcharts.chart(this.idChart, this.options);
  }

  updateChart() {
    this.updateChartOptions();
    this.chart.update(this.options);
  }

  updateChartOptions() {
    this.options = {
      colors: ['#00c056', '#b8312f'],
      chart: { type: 'spline' },
      legend: { enabled: false },
      credits: { enabled: false },
      title: { text: null },
      navigation: {
        buttonOptions: { enabled: false }
      },
      yAxis: {
        className: 'highcharts-axis-color',
        labels: {
          enabled: true,
          style: { fontSize: '9px' },
        },
        title: { enabled: false },
        max: this.maxY
      },
      xAxis: {
        className: 'highcharts-axis-color',
        accessibility: { description: '' },
        categories: ['15min', '30min', '45min', '60min', '75min', '90min']
      },
      tooltip: {
        shared: true,
        crosshairs: true,
        valueDecimals: 1,
        valueSuffix: '',
        backgroundColor: 'none',
        borderWidth: 0,
        borderRadius: 8,
        shadow: false,
        useHTML: true,
        headerFormat: `<span class="tooltip-header">Fundamento - {point.x}</span><br/>`,
        pointFormat: `
          <span style="color:{series.color};">●</span>
          <span class="tooltip-text">{series.name}: {point.y}</span><br/>
        `
      },
      plotOptions: {
        spline: {
          dataLabels: {
            className: 'highcharts-data-label-color',
            enabled: true,
            style: {
              textShadow: false,
              textOutline: '0px',
              fontWeight: 'normal',
            }
          },
          enableMouseTracking: true,
        }
      },

      series: [
        {
          name: 'Certos',
          data: this.data.correto
        },
        {
          name: 'Errados',
          data: this.data.incorreto,
          dashStyle: 'ShortDash'
        }
      ]
    }
  }
}
