import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsefulService } from '../../../shared/useful/useful.service';

@Component({
  selector: 'app-over',
  templateUrl: './over.component.html',
  styleUrls: ['./over.component.scss']
})
export class OverComponent implements OnInit {
    @Input() partida: number;
    @Input() idCampeonato: Number;
    @Input() idPartida: Number;
    @Input() atualizacao: any;

    scoutDetails: any = {};
    show: Boolean = false;

    interval: any;
    subscriptionOver: Subscription;
    subscriptionAmbos: Subscription;
    dataOver: any;
    dataAmbos: any;
    qtdGols: Number = 0;

    partidasAoVivo: any;
    partidaEstaAoVivo: Boolean;

    constructor(
      public useful : UsefulService
    ) { }

    ngOnInit() {
      console.log('partida', this.partida);
      this.useful.getOver(this.idCampeonato, this.idPartida);
      this.useful.getAmbosMarcam(this.idCampeonato, this.idPartida);

      this.partidasAoVivo = this.partida;
      this.partidaEstaAoVivo = this.partidasAoVivo.temporeal;

      if (this.atualizacao != undefined) {
        this.interval = setInterval(() => {
          this.useful.getOver(this.idCampeonato, this.idPartida);
          this.useful.getAmbosMarcam(this.idCampeonato, this.idPartida);
        }, this.atualizacao);
      }

      this.subscriptionOver = this.useful.dataObservableOver.subscribe((res:any) => {
        this.dataOver = res;
        this.dataOver != undefined && (this.show = true)
      });

      this.subscriptionAmbos = this.useful.dataObservableAmbosMarcam.subscribe((res:any) => {
        this.dataAmbos = res;
        this.dataAmbos != undefined && (this.show = true)
      });

      if (this.partida != undefined) {
        this.qtdGols = this.partida['mandante'].gols + this.partida['visitante'].gols;
      }
    }

    multiplyMe100(n:any) {
      const numTemp = n * 100;
      return this.useful.makeMeInin1decimal(numTemp);
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscriptionOver.unsubscribe();
      this.subscriptionAmbos.unsubscribe();
    }
}
