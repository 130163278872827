<!-- <div id="detailsHome" class="details" *ngIf="predictions != undefined">
  <ng-container *ngFor="let item of predictions; let i = index">
    <ng-container *ngIf="item.homeGoals != undefined && item.homeGoals.length > 0" >
      <i *ngFor="let gol of item.homeGoals;" class="icon-goal"  [style.left]="i > 50 ? (i+.5)+'%' : (i+1)+'%'" [title]="gol.player"></i>
    </ng-container>

    <ng-container *ngIf="item.homeCards != undefined && item.homeCards.length > 0">
      <img src="assets/images/svg/whistle.svg" *ngFor="let cartao of item.homeCards;" [style.left]="i > 65 ? (i-.7)+'%' : (i-.4)+'%'" [title]="cartao.type == 'yellow' ? 'Cartão amarelo - ' + cartao.player : 'Cartão vermelho - ' + cartao.player">
    </ng-container>
  </ng-container>
</div> -->
<figure class="highcharts-figure">
  <div [id]="idChart"></div>
</figure>
<!-- <div id="detailsAway" class='details'>
  <ng-container *ngFor="let item of predictions; let i = index">
    <ng-container *ngIf="item.awayGoals != undefined && item.awayGoals.length > 0" >
      <i *ngFor="let gol of item.awayGoals;" class="icon-goal" [style.left]="i > 50 ? (i-.2)+'%' : i+'%'" [title]="gol.player"></i>
    </ng-container>

    <ng-container *ngIf="item.awayCards != undefined && item.awayCards.length > 0">
      <img src="assets/images/svg/whistle.svg" *ngFor="let cartao of item.awayCards;" [style.left]="i > 65 ? (i-.7)+'%' : (i-.4)+'%'" [title]="cartao.type == 'yellow' ? 'Cartão amarelo - ' + cartao.player : 'Cartão vermelho - ' + cartao.player">
    </ng-container>
  </ng-container>
</div> -->
