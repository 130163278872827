<div class="container-inner campo" *ngIf="quadranteData != undefined && quadranteData != null">
    <h2>Campo</h2>

    <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectCampo({tempoJogo: $event.index});">
        <mat-tab label="Jogo completo">
        </mat-tab>
        <mat-tab label="1° Tempo">
        </mat-tab>
        <mat-tab label="2° Tempo">
        </mat-tab>
    </mat-tab-group>
    <div class="container-campo content">
        <ul class="menu-selects">
            <li>
                <label for="">Fundamentos</label>
                <select *ngIf="orderedFoundations != undefined" name="campoFundamentos" #campoFundamentos (change)="selectCampo({fundamento: campoFundamentos.value})">
                    <option *ngFor="let item of orderedFoundations" [value]="item.id">
                        {{ item.nome }}
                    </option>
                </select>
            </li>
            <li>
                <label for="">Visualização</label>
                <select name="campoVisualizacao" #campoVisualizacao (change)="selectCampo({visualizacao: campoVisualizacao.value})">
                    <option value="todos">Todos</option>
                    <option value="acertos">Acertos</option>
                    <option value="erros">Erros</option>
                </select>
            </li>
            <li>
                <label for="">Números</label>
                <select name="campoNum" #campoNum (change)="selectCampo({tipoNum: campoNum.value})">
                    <option value="absolutos">Absolutos</option>
                    <option value="porcentagem">Porcentagem</option>
                </select>
            </li>
            <li>
                <label for="">Quadrantes</label>
                <select name="campoQuad" #campoQuad (change)="selectCampo({tipoQuad: campoQuad.value})">
                    <option value="por9">9</option>
                    <option value="por36">36</option>
                </select>
            </li>
            <li *ngIf="jogadores != undefined">
                <label for="">Jogadores {{ this.partida.mandante.sigla }}</label>
                <select name="jogadoresMandanteCampo" #jogadoresMandanteCampo (change)="selectCampo({jogadorMandante: jogadoresMandanteCampo.value})">
                    <option value="todos">Todos</option>
                    <option *ngFor="let item of jogadores.mandante" [value]="item.idJogador">
                        {{ item.player }}
                    </option>
                </select>
            </li>
            <li *ngIf="jogadores != undefined">
                <label for="">Jogadores {{ this.partida.visitante.sigla }}</label>
                <select name="jogadoresVisitanteCampo" #jogadoresVisitanteCampo (change)="selectCampo({jogadorVisitante: jogadoresVisitanteCampo.value})">
                    <option value="todos">Todos</option>
                    <option *ngFor="let item of jogadores.visitante" [value]="item.idJogador">
                        {{ item.player }}
                    </option>
                </select>
            </li>
        </ul>
        <div class="quad-container">
            <div>
                <div class="campo-time">
                    <img [src]="partida.mandante.urlLogo">
                    <strong>
                        {{ partida.mandante.nome }}
                    </strong>
                </div>
                <div>
                    <div [class.por9]="stateCampo.tipoQuad === 'por9'" [class.por36]="stateCampo.tipoQuad === 'por36'">
                        <div *ngFor="let val of valoresQuadMandantes">
                        {{val}}
                        </div>
                    </div>
                    <img src="assets/images/football-field.jpg" class="img-field">
                    <i class="icon-arrow-right-field"></i>
                </div>
            </div>
            <div>
                <div class="campo-time">
                    <img [src]="partida.visitante.urlLogo">
                    <strong>
                        {{ partida.visitante.nome }}
                    </strong>
                </div>
                <div>
                    <div [class.por9]="stateCampo.tipoQuad === 'por9'" [class.por36]="stateCampo.tipoQuad === 'por36'">
                        <div *ngFor="let val of valoresQuadVisitantes">
                        {{val}}
                        </div>
                    </div>
                    <img src="assets/images/football-field.jpg" class="img-field">
                    <i class="icon-arrow-right-field"></i>
                </div>
            </div>
        </div>
    </div>
</div>