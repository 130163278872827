import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoadingService } from '../loading/loading.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { FormGroupDirective } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Injectable()

export class UsefulService {
  isMobile: Boolean = false;
  isMobileSm: Boolean = false;
	partidas: any;
	campeonatoAtual: any;
	scout: any;
	headers: any = new HttpHeaders({
		'Accept':'application/json',
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${environment.key}`
	});
  public partidaBrasil : any = 0;
	scoutDetails: any;

	mySubjectCampeonatos = new Subject<any>();
	dataObservableCampeonatos = this.mySubjectCampeonatos.asObservable();

	mySubjectRodadas = new Subject<any>();
	dataObservableRodadas = this.mySubjectRodadas.asObservable();

	mySubjectInteracao = new Subject<any>();
	dataObservableInteracao = this.mySubjectInteracao.asObservable();

	mySubjectJogadores = new Subject<any>();
	dataObservableJogadores = this.mySubjectJogadores.asObservable();

	mySubjectEquipes = new Subject<any>();
	dataObservableEquipes = this.mySubjectEquipes.asObservable();

	mySubjectHeatMap = new Subject<any>();
	dataObservableHeatMap = this.mySubjectHeatMap.asObservable();

	mySubjectEscalacao = new Subject<any>();
	dataObservableEscalacao = this.mySubjectEscalacao.asObservable();

	mySubjectQuadrante = new Subject<any>();
	dataObservableQuadrante = this.mySubjectQuadrante.asObservable();

	mySubjectScouts = new Subject<any>();
	dataObservableScouts = this.mySubjectScouts.asObservable();

	mySubjectFundamento = new Subject<any>();
	dataObservableFundamento = this.mySubjectFundamento.asObservable();

	mySubjectIntervalo = new Subject<any>();
	dataObservableIntervalo = this.mySubjectIntervalo.asObservable();

	mySubjectScoutDetails = new Subject<any>();
	dataObservableScoutDetails = this.mySubjectScoutDetails.asObservable();

	mySubjectAoVivo = new Subject<any>();
	dataObservableAoVivo = this.mySubjectAoVivo.asObservable();

	mySubjectPredicaoTimeline = new Subject<any>();
	dataObservablePredicaoTimeline = this.mySubjectPredicaoTimeline.asObservable();

	mySubjectNotifications = new Subject<any>();
	dataObservableNotifications = this.mySubjectNotifications.asObservable();

	mySubjectPredicaoPartida = new Subject<any>();
	dataObservablePredicaoPartida = this.mySubjectPredicaoPartida.asObservable();

	mySubjectOver = new Subject<any>();
	dataObservableOver = this.mySubjectOver.asObservable();

	mySubjectAmbosMarcam = new Subject<any>();
	dataObservableAmbosMarcam = this.mySubjectAmbosMarcam.asObservable();

	constructor(
		public http: HttpClient,
    public serviceLoading: LoadingService,
		public breakpointObserver: BreakpointObserver,
		public router: Router
	) {
        breakpointObserver.observe([
          Breakpoints.XSmall,
          Breakpoints.Handset,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
          Breakpoints.WebPortrait
        ]).subscribe(result => {
          this.isMobile = result.matches;
          this.isMobileSm = result.breakpoints['(max-width: 599.98px)'];
        });
    }

	//// uteis
	saveInLocalStore(nameData, data) {
        localStorage.setItem(nameData, JSON.stringify(data));
	}

	getInLocalStore(nameData) {
		return JSON.parse(localStorage.getItem(nameData));
	}

	deleteInLocalStore(nameData) {
		localStorage.removeItem(nameData);
	}

	formatDate(date) {
		const d = new Date(date);

		const mo = new Intl.DateTimeFormat('pt-br', { month: 'short' }).format(d);
		const da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
		let hours = d.getHours();
		let minutes:any = d.getMinutes();
		minutes = minutes < 10 ? '0'+minutes : minutes;

		let strTime = `${hours}:${minutes}h`;

		return `${da} ${mo} - ${strTime}`;
	}

	makeMeInindecimal(a) {
		return (Math.round(a * 100) / 100).toFixed(0);
	}

	makeMeInin1decimal(a) {
		return (Math.round(a * 100) / 100).toFixed(1);
	}

	makeMeInin2decimals(a) {
		return (Math.round(a * 100) / 100).toFixed(2);
	}

	abbreviateName(name) {
		let tempName = name.split(' ');
		if (tempName.length > 1) {
			return `${tempName[0]} ${tempName[1].substr(0, 1)}.`
		} else {
			return `${tempName[0]}`
		}
	}

	//// gets e posts
	getCampeonatos() {
		this.http
        .get(`${environment.api_endpoint}campeonatos`, {
			headers: this.headers
		}).subscribe(
            (data: any) => {
				// console.log('Campeonatos', data);
				this.mySubjectCampeonatos.next(data.data);

				return data.data;
            },
            (error: any) => { }
        );
	}

	getRodadas(id) {
		this.saveInLocalStore('idCampeonato', id);

        this.http
        .get(`${environment.api_endpoint}/campeonatos/${id}/calendario`, {
        	headers: this.headers
        }).subscribe(
            (data: any) => {
				// console.log('Rodadas ', data.data);
				this.saveInLocalStore('rodadas', data.data);

				this.mySubjectRodadas.next(data.data);
                return data.data;
            },
            (error: any) => { }
        );
	}

	getScouts(idCampeonato, arr) {
		const params = {
			'idAway': arr.visitante.id,
			'idChampionship': idCampeonato,
			'idHome': arr.mandante.id,
			'idMatch': arr.id
		};

        this.http
        .post(`${environment.api_endpoint}partidas/v2/scout/`, params, {
			headers: this.headers
        }).subscribe(
			(data: any) => {
				this.saveInLocalStore('scoutDetails', data.data.fundamentos);
				this.mySubjectScoutDetails.next(data.data);

				// console.log('Scouts ', data.data);

                return data.data;
            },
            (error: any) => { }
        );
	}

	getEquipes(idCampeonato) {
		const headers = new HttpHeaders({
            'Accept':'application/json',
            'Content-Type': 'application/json',
			'Authorization': `Bearer ${environment.key}`
        });

        this.http
        .get(`${environment.api_endpoint}ranking/${idCampeonato}/equipes`, {
        	headers: headers
        }).subscribe(
            (data: any) => {
				// console.log('equipes ', data);
				this.mySubjectEquipes.next(data);
                return data;
            },
            (error: any) => { }
        );
	}

	getJogadores(id, fundamento) {
		const headers = new HttpHeaders({
            'Accept':'application/json',
            'Content-Type': 'application/json',
			'Authorization': `Bearer ${environment.key}`
        });

		this.http
        .get(`${environment.api_endpoint}campeonatos/${id}/fundamento/${fundamento}/jogadores/ranking`, {
        	headers: headers
        }).subscribe(
            (data: any) => {
				 console.log('jogadores ', data);
				this.mySubjectJogadores.next(data.data);

                return data;
            },
            (error: any) => { }
        );
	}

	getHeatmap(arr) {
		const params = {
			'idAway': arr.visitante.id,
			'idChampionship': this.getInLocalStore('idCampeonato'),
			'idHome': arr.mandante.id,
			'idMatch': arr.id
		};

        this.http
        .post(`${environment.api_endpoint}/partidas/heatmapByTeam`, params, {
        	headers: this.headers
        }).subscribe(
            (data: any) => {
				this.saveInLocalStore('heatmap', data.data);
				this.mySubjectHeatMap.next(data.data);
				// console.log('heatmap ', data.data);
                return data.data;
            },
            (error: any) => { }
        );
	}

	getEscalacao(idPartida, idCampeonato, idHome, idAway) {
        this.http
        .get(`${environment.api_endpoint}/partidas/v2/${idPartida}/championship/${idCampeonato}/teamHome/${idHome}/teamAway/${idAway}/escalacao`, {
        	headers: this.headers
        }).subscribe(
            (data: any) => {
				// console.log('escalacao ', data.data);
				this.saveInLocalStore('escalacao', data.data);
				this.mySubjectEscalacao.next(data.data);
				return data.data;
            },
            (error: any) => { }
        );
	}

	getInteracao(idCampeonato, idPartida, idFundamento) {
        this.http
        .get(`${environment.api_endpoint}partidas/${idPartida}/fundamento/${idFundamento}/campeonato/${idCampeonato}/interacao`, {
        	headers: this.headers
        }).subscribe(
            (data: any) => {
				// console.log('interacao ', data.data);
				this.mySubjectInteracao.next(data.data);
				this.saveInLocalStore('interacao', data.data);
                return data.data;
            },
            (error: any) => { }
        );
	}

	getQuadrante(arr) {
		const params = {
			'idAway': arr.visitante.id,
			'idChampionship': this.getInLocalStore('idCampeonato'),
			'idHome': arr.mandante.id,
			'idMatch': arr.id
		};

        this.http
        .post(`${environment.api_endpoint}/partidas/scoutQuandranteByTeam`, params, {
        	headers: this.headers
        }).subscribe(
            (data: any) => {
				this.saveInLocalStore('quadrante', data.data);
				this.mySubjectQuadrante.next(data.data);
				// console.log('quadrante ', data.data);

                return data.data;
            },
            (error: any) => { }
        );
	}

	getFundamento(idPartida) {
        this.http
        .get(`${environment.api_endpoint}/partidas/v2/${idPartida}/fundamento`, {
        	headers: this.headers
        }).subscribe(
            (data: any) => {
				// console.log('fundamento ', data.data);
				this.saveInLocalStore('fundamento', data.data.fundamentos);
				this.mySubjectFundamento.next(data.data.fundamentos);
                return data.data;
            },
            (error: any) => { }
        );
	}

	getIntervalo(idPartida, idFundamento) {
        this.http
        .get(`${environment.api_endpoint}/partidas/${idPartida}/fundamento/${idFundamento}/intervalo`, {
        	headers: this.headers
        }).subscribe(
            (data: any) => {
				// console.log('intervalo ', data.data);
				this.saveInLocalStore('intervalo', data.data);
				this.mySubjectIntervalo.next(data.data);
                return data.data;
            },
            (error: any) => { }
        );
	}

	getJogosAoVivo() {
        this.http
        .get(`${environment.api_endpoint}partidas/hoje`, {
        	headers: this.headers
        }).subscribe(
            (data: any) => {
				// console.log('tempo real ', data.data);
				this.saveInLocalStore('aovivo', data.data);
				this.mySubjectAoVivo.next(data.data);

              return data.data;
            },
            (error: any) => { }
        );
	}

	getPredicaoTimeline(idCampeonato, idPartida) {
		const headers = new HttpHeaders({
			'Accept':'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Basic cG9ydGFsLWkyYTpndXYwdUFFY3FLdzc='
		});
        this.http
        .get(`${environment.api_endpoint_0}api/v1/prediction/1x2/${idCampeonato}/${idPartida}/timeline`, {
        	headers: headers
        }).subscribe(
            (data: any) => {
				console.log('getPredicaoTimeline ', data);
				this.saveInLocalStore('predicaoTimeline', data);
				this.mySubjectPredicaoTimeline.next(data);
                return data;
            },
            (error: any) => { }
        );
	}

	getPredicaoPartida(idCampeonato, idPartida) {
		const headers = new HttpHeaders({
			'Accept':'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Basic cG9ydGFsLWkyYTpndXYwdUFFY3FLdzc='
		});
        this.http
        .get(`${environment.api_endpoint_0}api/v1/prediction/1x2/${idCampeonato}/${idPartida}`, {
        	headers: headers
        }).subscribe(
            (data: any) => {
				console.log('getPredicaoPartida	', data);
				this.mySubjectPredicaoPartida.next(data);
                return data;
            },
            (error: any) => { }
        );
	}

	getOver(idCampeonato, idPartida) {
		const headers = new HttpHeaders({
			'Accept':'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Basic cG9ydGFsLWkyYTpndXYwdUFFY3FLdzc='
		});
        this.http
        .get(`${environment.api_endpoint_0}api/v1/prediction/over/${idCampeonato}/${idPartida}`, {
        	headers: headers
        }).subscribe(
            (data: any) => {
				console.log('Over', data);
				this.mySubjectOver.next(data);
                return data;
            },
            (error: any) => { }
        );
	}

	getAmbosMarcam(idCampeonato, idPartida) {
		const headers = new HttpHeaders({
			'Accept':'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Basic cG9ydGFsLWkyYTpndXYwdUFFY3FLdzc='
		});
        this.http
        .get(`${environment.api_endpoint_0}api/v1/prediction/ambos-marcam/${idCampeonato}/${idPartida}`, {
        	headers: headers
        }).subscribe(
            (data: any) => {
				console.log('ambos marcam o que eu preciso', data);
				this.mySubjectAmbosMarcam.next(data);
                return data;
            },
            (error: any) => { }
        );
	}

	getNotifications(idCampeonato, idPartida) {
		const headers = new HttpHeaders({
			'Accept':'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Basic cG9ydGFsLWkyYTpndXYwdUFFY3FLdzc='
		});
        this.http
        .get(`${environment.api_endpoint_0}api/v1/message/${idCampeonato}/${idPartida}`, {
        	headers: headers
        }).subscribe(
            (data: any) => {
				// console.log('Notifications ', data);
				this.saveInLocalStore('notifications', data);
				this.mySubjectNotifications.next(data);
                return data;
            },
            (error: any) => { }
        );
	}

}
