import { Component, OnInit } from '@angular/core';
import { UsefulService } from '../../shared/useful/useful.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-pagina-inicial',
  templateUrl: './pagina-inicial.component.html',
  styleUrls: ['./pagina-inicial.component.scss']
})
export class PaginaInicialComponent implements OnInit {
    data;
    interval;
    partidasAoVivo;
    partidasFinalizadas;
    partidasProximas;
    subscription: Subscription;
    mail : string | any;
    feito : boolean =  false;

    constructor(
      public useful : UsefulService,
      public router: Router,
      public http: HttpClient
    ) { }

    ngOnInit() {
      this.useful.getJogosAoVivo();

      // defino um tempo de 20s para atualizar as partidas
      const that = this;
      this.interval = setInterval(function() {
        that.useful.getJogosAoVivo();
      }, 10000);

      // obsrvable dos jogos ao vivo
      this.subscription = this.useful.dataObservableAoVivo.subscribe((res:any) => {
        this.data = this.orderPartidas(res);
        this.insertPredicao();
      });


    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscription.unsubscribe();
    }

    insertPredicao() {
      this.data.map(item => {
        // console.log('item', item);
        // if (item.realtime || item.gameTime !== "Final") {
          const headers = new HttpHeaders({
            'Accept':'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Basic cG9ydGFsLWkyYTpndXYwdUFFY3FLdzc='
          });
          this.http
          .get(`${environment.api_endpoint_0}api/v1/prediction/1x2/${item.idChampionship}/${item.id}`, {
            headers: headers
          }).subscribe(
              (data: any) => {
                // console.log('getPredicaoPartida	', data);
                Object.assign(item, {predicao: data});
                return data;
              },
              (error: any) => { }
          );
        // }
      });
    }

    orderPartidas(data) {
        data = data.sort(function(a,b){
          let temp1:any = new Date(a.isoDate);
          let temp2:any = new Date(b.isoDate);
          return  temp1 - temp2 ;
        });

        this.partidasAoVivo = data.filter(x => x.realtime );
        this.partidasFinalizadas = data.filter(x => x.gameTime == "Final");
        this.partidasProximas = data.filter(x => x.gameTime == "Não Inic.");

      return data;
  }

  scoutEscolhido(i) {
    this.router.navigateByUrl(`partidas/${i.idChampionship}/scouts/${i.id}${i.realtime ? '?partida=aovivo' : ''}`);
    this.useful.saveInLocalStore('partida', i);
    this.useful.partidaBrasil = i.id;
  }


}
