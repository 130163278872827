import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { LoaderInterceptorService } from './shared/loading/loading-interceptor.service';
import { LoadingService } from './shared/loading/loading.service';
import { AppComponent } from "./app.component";
import { LoadingComponent } from "./shared/loading/loading.component";
import { UsefulService } from "./shared/useful/useful.service";
import { ToastrModule } from 'ngx-toastr';

import { MenuModule } from "./shared/menu/menu.module";

import { PaginaInicialModule } from "./pages/pagina-inicial/pagina-inicial.module";
import { PartidasModule } from './pages/partidas/partidas.module';
import { ScoutsModule } from './pages/scouts/scouts.module';
import { RankingEquipesModule } from './pages/ranking-equipes/ranking-equipes.module';
import { RankingJogadoresModule } from './pages/ranking-jogadores/ranking-jogadores.module';
import { NotFoundModule } from './pages/not-found/not-found.module'
@NgModule({
  declarations: [ AppComponent, LoadingComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),

    MenuModule,
    PaginaInicialModule,
    PartidasModule,
    ScoutsModule,
    RankingEquipesModule,
    RankingJogadoresModule,
    NotFoundModule,

    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    UsefulService,
    LoadingService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
