import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsefulService } from '../../../shared/useful/useful.service';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss']
})
export class IndividualComponent implements OnInit {
    @Input() partida: any;
    @Input() idPartida: Number;
    @Input() atualizacao: any;

    idChartHome: string;
    idChartAway: string;
    dataChartHome: any;
    dataChartAway: any;
    maxPlotIndividual: any;
    stateIndividual: {tempo: string, fundamento: number|string};
    fundamentoData: any;
    interval: any;
    subscription: Subscription;
    fundamentoEscolhido: any;
    tempoEscolhido: any;

    constructor(
      public useful : UsefulService
    ) { }

    ngOnInit() {
      this.useful.getFundamento(this.idPartida);
      if (this.atualizacao != undefined) {
        this.interval = setInterval(() => {
          this.useful.getFundamento(this.idPartida);
        }, this.atualizacao);
      }

      this.subscription = this.useful.dataObservableFundamento.subscribe((res:any) => {
        this.fundamentoData = this.useful.getInLocalStore('fundamento');
        this.fundamentoData.shift();

        console.log('Individual', this.fundamentoData);

        if (this.fundamentoEscolhido == undefined) {
          this.fundamentoEscolhido = this.fundamentoData.length !== 0 ? this.fundamentoData[0].idFundamento : 0;
        }
        if (this.tempoEscolhido == undefined) {
          this.tempoEscolhido = 'jogoCompleto';
        }

        this.stateIndividual = {tempo: this.tempoEscolhido, fundamento: this.fundamentoEscolhido};

        this.generateIndividualGraphs();
      });
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscription.unsubscribe();
    }

    generateIndividualGraphs() {
      this.idChartHome = 'chart-home';
      this.idChartAway = 'chart-away';

      const primeiroFundamento = this.fundamentoEscolhido;
      let dataMandante = this.fundamentoData.find(el => el.idFundamento == primeiroFundamento).mandante[this.tempoEscolhido];
      let dataVisitante = this.fundamentoData.find(el => el.idFundamento == primeiroFundamento).visitante[this.tempoEscolhido];

      dataMandante = dataMandante.sort((a,b) => b.acoesCertas - a.acoesCertas);
      dataVisitante = dataVisitante.sort((a,b) => b.acoesCertas - a.acoesCertas);

      dataMandante = dataMandante.filter((a) => a.nomeJogador = this.useful.abbreviateName(a.nomeJogador));
      dataVisitante = dataVisitante.filter((a) => a.nomeJogador = this.useful.abbreviateName(a.nomeJogador));

      this.maxValueIndividual(dataMandante, dataVisitante);

      // console.log('dataMandante', this.getDataChartByFoundationArray(dataMandante));
      // console.log('dataVisitante', this.getDataChartByFoundationArray(dataVisitante));

      this.dataChartHome = this.getDataChartByFoundationArray(dataMandante);
      this.dataChartAway = this.getDataChartByFoundationArray(dataVisitante);

    }

    updateIndividualGraphs(options: {tempoJogo?: {index?: number}, selectFundamento?: number|string}) {
      const {tempoJogo, selectFundamento: fundamento} = options;

      if(tempoJogo) {
        const tiposJogos:string[] = ['jogoCompleto', 'primeiroTempo', 'segundoTempo'];
        const tempo: string = tiposJogos[tempoJogo.index]
        this.stateIndividual = {...this.stateIndividual, tempo}
        this.tempoEscolhido = tempo
      }

      if(fundamento) {
        this.stateIndividual = {...this.stateIndividual, fundamento}
        this.fundamentoEscolhido = fundamento;
      }

      let dataMandante = this.fundamentoData.find(el => el.idFundamento == this.stateIndividual.fundamento).mandante[this.stateIndividual.tempo];
      let dataVisitante = this.fundamentoData.find(el => el.idFundamento == this.stateIndividual.fundamento).visitante[this.stateIndividual.tempo];

      if (dataMandante != null && dataMandante[0].nomeJogador != null) {
        dataMandante = dataMandante.sort((a,b) => b.acoesCertas - a.acoesCertas);
        dataMandante = dataMandante.filter((a) => a.nomeJogador = this.useful.abbreviateName(a.nomeJogador));
      } else {
        dataMandante = [];
      }
      if (dataVisitante != null && dataVisitante[0].nomeJogador != null) {
        dataVisitante = dataVisitante.sort((a,b) => b.acoesCertas - a.acoesCertas);
        dataVisitante = dataVisitante.filter((a) => a.nomeJogador = this.useful.abbreviateName(a.nomeJogador));
      } else {
        dataVisitante = [];
      }

      this.maxValueIndividual(dataMandante, dataVisitante);

      this.dataChartHome = this.getEmptyDataChart();
      this.dataChartAway = this.getEmptyDataChart();

      this.dataChartHome = this.getDataChartByFoundationArray(dataMandante);
      this.dataChartAway = this.getDataChartByFoundationArray(dataVisitante);
    }

    maxValueIndividual(dataMandante, dataVisitante) {
      const tudoMandanteCerto = dataMandante ? dataMandante.map(d => d.acoesCertas) : [0];
      const tudoVisitanteCerto = dataVisitante ? dataVisitante.map(d => d.acoesCertas) : [0];
      const tudoMandanteErrado = dataMandante ? dataMandante.map(d => d.acoesErradas) : [0];
      const tudoVisitanteErrado = dataVisitante ? dataVisitante.map(d => d.acoesErradas) : [0];

      this.maxPlotIndividual = Math.max(...tudoMandanteCerto, ...tudoVisitanteCerto, ...tudoMandanteErrado, ...tudoVisitanteErrado);
      return Math.max(...tudoMandanteCerto, ...tudoVisitanteCerto, ...tudoMandanteErrado, ...tudoVisitanteErrado);
    }

    getDataChartByFoundationArray(array: any): any {
      let dataAux = this.getEmptyDataChart();
      if (array) {
        array.forEach(element => {
          dataAux.players.push(element.nomeJogador)
          dataAux.success.push(element.acoesCertas ? element.acoesCertas : null)
          dataAux.error.push(element.acoesErradas ? element.acoesErradas : null)
        });
      }
      return dataAux
    }

    getEmptyDataChart(): any {
      return { players: [], success: [], error: [] }
    }

}
