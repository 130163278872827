<h2>Insights da partida</h2>
<div class="container-insights">
  <ul>
    <li class="type-message danger-msg" *ngFor="let item of messages" [style.border-left-color]="item.color">
      <small>
        {{ mountTime(item.timeInMinutes, item.idGameTime) }}
      </small>
      <img [src]="!item.teamLogoUrl ? 'assets/images/svg/icon-empate-cinza.svg' : item.teamLogoUrl">

      <div class="message-group">
        <b>{{ item.message }}.
          <span *ngIf="item.idRecordType === 8 && item.linkOdds !== null && !item.oddHasValuable" class="red">Essa não é
            uma aposta de valor.</span>
          <span *ngIf="item.idRecordType === 8 && item.linkOdds !== null && item.oddHasValuable" class="green">Essa é
            uma aposta de valor.</span>
        </b>
        <img *ngIf="item.idRecordType === 8 && item.linkOdds !== null" class="arrow"
          [src]="item.oddHasValuable ? 'assets/images/svg/up.svg' : 'assets/images/svg/down.svg'">
      </div>
      <div class="bet" *ngIf="item.idRecordType === 8 && item.linkOdds !== null">
        <img class="logo" src="assets/images/betano.png">

        <a [href]="item.linkOdds" target="_blank" class="btn-bet">
          <img [src]="item.logoBetHouse">
          {{ item.ODD }}x
        </a>
      </div>
    </li>
  </ul>
  <app-notification [color]="colorNotification" [message]="msgNotification" [urlTeam]="teamUrl"></app-notification>
</div>
