import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  segundos: any = 35;
  interval: any;

    constructor(
      public router: Router,
    ) { }

    ngOnInit() {
    this.interval = setInterval(() => {
      if (this.segundos == 0) {
        clearInterval(this.interval);
        this.router.navigateByUrl(`/`);
      } else {
        this.segundos = (this.segundos - 1);
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
