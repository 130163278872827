import { Component, OnInit, ViewChild } from '@angular/core';
import { UsefulService } from '../../shared/useful/useful.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-ranking-jogadores',
  templateUrl: './ranking-jogadores.component.html',
  styleUrls: ['./ranking-jogadores.component.scss']
})
export class RankingJogadoresComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  ranking: any;
  fundamento: any = 'Passes';
  filtro: any = 'jogos';
  times: any;
  idTime: any;
  idCampeonato: Number;
  displayedColumns: string[] = [
		'nomeJogador',
    'jogos',
    'passeCerto',
    'passeCertoMedia',
    'passeCertoPorc',
    'passeErrado',
    'passeErradoMedia',
    'passeErradoPorc',
    'total',
    'totalMedia'
	];
  columnsName:any = {
    certos: '',
    errados: ''
  }
  fundamentos:any = [
      { id: 32, nome: "Índice"},
      { id: 2, nome: "Passes"},
      { id: 3, nome: "Finalizações"},
      { id: 5, nome: "Desarmes"},
      { id: 14, nome: "Cruzamentos"},
      { id: 1, nome: "Lançamentos"},
      { id: 24, nome: "Interceptações"},
      { id: 4, nome: "Dribles"},
      { id: 22, nome: "Viradas de jogo"},
      { id: 8, nome: "Gols"},
      // { id: 20, nome: "Posse de bola"},
      // { id: 16, nome: "Escanteios"},
      // { id: 28, nome: "Assistências para finalização"},
      { id: 27, nome: "Assistências"},
      { id: 15, nome: "Defesas"},
      // { id: 23, nome: "Defesas difíceis"},
      { id: 19, nome: "Rebatidas"},
      { id: 6, nome: "Faltas"},
      // { id: 7, nome: "Faltas sofridas"},
      { id: 9, nome: "Impedimentos"},
      { id: 11, nome: "Pênaltis"},
      { id: 12, nome: "Perda da posse de bola"},
      { id: 25, nome: "Cartões amarelos"},
      { id: 26, nome: "Cartões vermelhos"}
  ]
	dataSource = new MatTableDataSource<any>([]);

    constructor(
      public useful : UsefulService,
      public router: Router,
      public activatedRoute: ActivatedRoute
    ) {
      this.activatedRoute.params.subscribe( parametros => {
        this.idCampeonato = parametros['id'];
        this.useful.getJogadores(this.idCampeonato, 2);
      });
    }

    ngOnInit() {
      this.useful.dataObservableJogadores.subscribe((res:any) => {
        this.ranking = res.fundamentos;
        this.orderJogadores();
        console.log('- jogadores: ', this.ranking);


        this.dataSource = new MatTableDataSource<any>(this.ranking);
        this.columnsName = {
          certos: res.fundamentos[0].detalhes[0] != undefined ? res.fundamentos[0].detalhes[0].nome : '',
          errados: res.fundamentos[0].detalhes[1] != undefined ? res.fundamentos[0].detalhes[1].nome : ''
        }
        this.idTime != '' && (this.getFiltroTime());
      })
    }

    prosMinuto(v) {
      let result:any = v / 90;
      result = 60 / result;

      var sec_num:any = parseInt(result, 10);
      var hours:any   = Math.floor(sec_num / 3600);
      var minutes:any = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds:any = sec_num - (hours * 3600) - (minutes * 60);

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}

      return `${hours > 0 ? hours+'h' : ''}${minutes > 0 ? minutes+'m' : ''}${seconds}s`;

    }

    maskTempo(v) {
      var sec_num:any = parseInt(v);
      var hours:any   = Math.floor(sec_num / 3600);
      var minutes:any = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds:any = sec_num - (hours * 3600) - (minutes * 60);

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}

      return `${hours > 0 ? hours+'h' : ''}${minutes > 0 ? minutes+'m' : ''}${seconds}s`;
    }

    filterThis(arr, field) {
      return [...new Map(arr.map(item => [item[field], item])).values()];
    }

    getFiltroTime(e?) {
      e != undefined && (this.idTime = e);
      let temp = this.getSames(this.ranking, 'idTeam', this.idTime);
      if (temp.length > 0) {
        this.dataSource = new MatTableDataSource<any>(temp);
      } else {
        this.dataSource = new MatTableDataSource<any>(this.ranking);
      }

      this.orderJogadores();
    }

    getSames(arr, field,  inf) {
      let temp:any = [];
      for (const i of arr) {
        if (i[field] == inf) {
          temp.push(i);
        }
      }
      return temp;
    }

    columnShow() {
      if (this.filtro == 'pros_minuto' ) {
        switch (this.fundamento) {
          case 8:
          case 9:
          case 12:
          case 19:
          case 25:
          case 26:
          case 32:
            this.displayedColumns = [
              'nomeJogador',
              'jogos',
              'minutosJogados'
            ];
            break;
          default:
            this.displayedColumns = [
              'nomeJogador',
              'jogos',
              'minutosJogados',
              'passeCerto',
              'passeErrado'
            ];
            break;
        }
      } else {
        switch (this.fundamento) {
          case 8:
          case 9:
          case 12:
          case 19:
          case 25:
          case 26:
            this.displayedColumns = [
              'nomeJogador',
              'jogos',
              'passeCerto',
              'passeCertoMedia',
            ];
            break;
          case 32:
            this.displayedColumns = [
              'nomeJogador',
              'jogos',
              'passeCertoMedia',
              'passeCerto',
            ];
            break;
          case 6:
          case 11:
          case 15:
          case 27:
            this.displayedColumns = [
              'nomeJogador',
              'jogos',
              'passeCerto',
              'passeCertoMedia',
              'passeErrado',
              'passeErradoMedia',
              'total',
              'totalMedia'
            ];
            break;
          default:
            this.displayedColumns = [
              'nomeJogador',
              'jogos',
              'passeCerto',
              'passeCertoMedia',
              'passeCertoPorc',
              'passeErrado',
              'passeErradoMedia',
              'passeErradoPorc',
              'total',
              'totalMedia'
            ];
            break;
        }

      }
    }

    getFundamento(e) {
      console.log('e',e.target.value);
      this.fundamento = parseInt(e.target.value);

      this.useful.getJogadores(this.idCampeonato, e.target.value);
      this.columnShow();
    }

    getFiltro(e) {
      this.filtro = e.target.value;

      this.columnShow();
      this.orderJogadores();
    }

    orderJogadores() {
        this.ranking = this.ranking.sort(function(a,b){
          let temp1:any = a.detalhes[0].total;
          let temp2:any = b.detalhes[0].total;
          return  temp2 - temp1 ;
        });
    }

    getDetalhe(arr, inf) {
      for (const i of arr) {
        if (i.nome == inf) {
          return i;
        }
      }
    }

    sortThis(field, e, fieldSec?, indexDetalhes?) {
      let classTemp = e.target.classList;
      let dataTemp;

      if (this.ranking[0] !== undefined && typeof this.ranking[0][field] === 'string') {
        dataTemp = this.ranking.sort(function(a,b){
          if (classTemp.value.indexOf("ascending") > -1) {
            return b[field].localeCompare(a[field]);
          } else {
            return a[field].localeCompare(b[field]);
          }
        });
      } else {
        dataTemp = this.ranking.sort(function(a,b){
          let temp1 = field == 'detalhes' ? a[field][indexDetalhes][fieldSec] : a[field];
          let temp2 = field == 'detalhes' ? b[field][indexDetalhes][fieldSec] : b[field];
          if (classTemp.value.indexOf("ascending") > -1) {
            if(temp1 < temp2) { return 1; }
            if(temp1 > temp2) { return -1; }
            return 0;
          } else {
            if(temp1 < temp2) { return -1; }
            if(temp1 > temp2) { return 1; }
            return 0;
          }
        });
      }

      e.target.classList.toggle('ascending');
      this.dataSource = new MatTableDataSource<any>(this.ranking);
    }
}
