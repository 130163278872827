import { Component } from '@angular/core';
import { UsefulService } from './shared/useful/useful.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FootStats';

  constructor(
    public useful : UsefulService
  ) { }
}
