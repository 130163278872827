<section>
    <div class="header-scouts">
        <div class="placarJogo" *ngIf="partida != undefined">
            <div class="placarEsq">
                <img [src]="partida.mandante.urlLogo" [title]="partida.mandante.nome">
                <span>{{ partida.mandante.sigla }}</span>
            </div>

            <div class="placarGols">
                <strong *ngIf="partida.mandante.gols !== null">
                    {{ partida.mandante.gols }} x {{ partida.visitante.gols }}
                </strong>
                <small>{{ partida.periodoJogo }}</small>
            </div>

            <div class="placarDir">
                <img [src]="partida.visitante.urlLogo" [title]="partida.visitante.nome">
                <span>{{ partida.visitante.sigla }}</span>
            </div>
        </div>

        <span class="linha"></span>

        <nav class="navList">
          <ul>
            <li><a href="javascript:void(0);" (click)="jump('estatistica')" title="Estatística 1 x 2" class="actived">1 x 2</a></li>
            <li><a href="javascript:void(0);" (click)="jump('scouts')" title="Scouts">Scouts</a></li>
            <li><a href="javascript:void(0);" (click)="jump('escalacao')" title="Escalação">Escalação</a></li>
            <!-- <li><a href="javascript:void(0);" (click)="jump('caminho')" title="Caminho do gol">Caminho do gol</a></li> -->
            <li><a href="javascript:void(0);" (click)="jump('heatmap')" title="Heatmap">Heatmap</a></li>
            <li><a href="javascript:void(0);" (click)="jump('interacoes')" title="Interações">Interações</a></li>
            <li><a href="javascript:void(0);" (click)="jump('individual')" title="Individual">Individual</a></li>
            <li><a href="javascript:void(0);" (click)="jump('tempo')" title="Tempo">Tempo</a></li>
            <!-- <li><a href="javascript:void(0);" (click)="jump('campo')" title="Campo">Campo</a></li> -->
          </ul>
        </nav>
    </div>

    <!-- <ul id="menu-share" *ngIf="partida != undefined">
      <li>
        <a [href]="'https://www.facebook.com/sharer/sharer.php?u='+myHref" id="facebook-share-btt" rel="nofollow noreferrer" target="_blank">
          <img src="assets/images/svg/icon-facebook.svg" title="Compartilhar partida no Facebook">
        </a>
      </li>
      <li>
        <a [href]="'https://twitter.com/intent/tweet?text=Venha acompanhar a partida com o Footstats! '+partida.mandante.nome+' '+partida.mandante.gols+'x'+partida.visitante.gols+' '+partida.visitante.nome+' '+myHref" id="twitter-share-btt" rel="nofollow noreferrer" target="_blank">
          <img src="assets/images/svg/icon-twitter.svg" title="Compartilhar partida no Twitter">
        </a>
      </li>
      <li>
        <a [href]="'https://api.whatsapp.com/send?text=Venha acompanhar a partida com o Footstats! '+partida.mandante.nome+' '+partida.mandante.gols+'x'+partida.visitante.gols+' '+partida.visitante.nome+' '+myHref" id="whatsapp-share-btt" rel="nofollow noreferrer" target="_blank">
          <img src="assets/images/svg/icon-whatsapp.svg" title="Compartilhar partida no WhatsApp">
        </a>
      </li>
    </ul> -->

    <!--  over e ambos marcam -->
    <div id="over">
        <app-over [partida]="partida" [idCampeonato]="idCampeonato" [idPartida]="idPartida" [atualizacao]="tempo" *ngIf="partida != undefined"></app-over>
    </div>

    <div class="info-group">
      <!--  estatisticas 1x2 -->
      <div class="container-menu" id="estatistica">
          <app-estatistica1x2 [partida]="partida" [idCampeonato]="idCampeonato" [idPartida]="idPartida" [atualizacao]="tempo" *ngIf="partida != undefined"></app-estatistica1x2>
      </div>

      <!-- Insights -->
      <div class="container-menu" id="insights">
          <app-insights [partida]="partida" [idCampeonato]="idCampeonato" [idPartida]="idPartida" [atualizacao]="tempo" *ngIf="partida != undefined"></app-insights>
      </div>
    </div>

    <!--  scouts -->
    <div class="container-menu" id="scouts">
        <app-scouts-details [partida]="partida" [idCampeonato]="idCampeonato" [idPartida]="idPartida" [atualizacao]="tempo" *ngIf="partida != undefined"></app-scouts-details>
    </div>

    <!-- escalação -->
    <div class="container-menu" id="escalacao">
        <app-escalacao [partida]="partida" [idCampeonato]="idCampeonato" [atualizacao]="tempo" *ngIf="partida != undefined"></app-escalacao>
    </div>

    <!-- heatmap -->
    <div class="container-menu" id="heatmap">
        <app-heatmap [partida]="partida" *ngIf="partida != undefined" [atualizacao]="tempo"></app-heatmap>
    </div>

    <!-- interações -->
    <div class="container-menu" id="interacoes">
        <app-interacoes [partida]="partida" [idCampeonato]="idCampeonato" [idPartida]="idPartida" [atualizacao]="tempo" *ngIf="partida != undefined"></app-interacoes>

    </div>

    <!-- indivudual -->
    <div class="container-menu" id="individual">
        <app-individual [partida]="partida" [idPartida]="idPartida" [atualizacao]="tempo" *ngIf="partida != undefined"></app-individual>

    </div>

    <!-- tempo -->
    <div class="container-menu" id="tempo">
        <app-tempo [partida]="partida" [idPartida]="idPartida" [atualizacao]="tempo" *ngIf="partida != undefined"></app-tempo>
    </div>

    <!-- campo -->
    <!-- <div class="container-menu" id="campo">
        <app-campo [partida]="partida" [idCampeonato]="idCampeonato" [atualizacao]="tempo" *ngIf="partida != undefined"></app-campo>
    </div> -->
</section>
