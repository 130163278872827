import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsefulService } from '../../../shared/useful/useful.service';

@Component({
  selector: 'app-escalacao',
  templateUrl: './escalacao.component.html',
  styleUrls: ['./escalacao.component.scss']
})
export class EscalacaoComponent implements OnInit {
    @Input() partida: any;
    @Input() idCampeonato: Number;
    @Input() atualizacao: any;

    escalacao: any;
    interval: any;
    subscription: Subscription;

    constructor(
      public useful : UsefulService
    ) { }

    ngOnInit() {
      this.useful.getEscalacao(this.partida.id, this.idCampeonato, this.partida.mandante.id, this.partida.visitante.id);

      if (this.atualizacao != undefined) {
        this.interval = setInterval(() => {
          this.useful.getEscalacao(this.partida.id, this.idCampeonato, this.partida.mandante.id, this.partida.visitante.id);
        }, this.atualizacao);
      }

      this.subscription = this.useful.dataObservableEscalacao.subscribe((res:any) => {
        this.escalacao = this.setSubstitutedPlayers(res);
        // console.log('Escalacao', this.escalacao)
      });
    }

    ngOnDestroy() {
      clearInterval(this.interval);
      this.subscription.unsubscribe();
    }

    getMinutosGol(arr) {
      if (arr != null) {
        let temp = '';
        for (let i = 0; i < arr.length; i++) {
          if (i < 1) {
            temp += `Gol aos ${arr[i]} minutos`
          } else {
            temp += ` - Gol aos ${arr[i]} minutos`
          }
        }
        return temp;
      } else {
        return `Sem dados disponíveis`
      }
    }

    setSubstitutedPlayers(data) {
      let playersHomeReplaced = data.titular.mandante.filter(x => x.foiSubstituido !== null)
      let playersAwayReplaced = data.titular.visitante.filter(x => x.foiSubstituido !== null)

      playersHomeReplaced.forEach(player => {
        let index = data.reserva.mandante.findIndex(x => x.id === player.foiSubstituido.id)
        data.reserva.mandante[index] = player
      })
      playersAwayReplaced.forEach(player => {
        let index = data.reserva.visitante.findIndex(x => x.id === player.foiSubstituido.id)
        data.reserva.visitante[index] = player
      })

      return data
    }

}
